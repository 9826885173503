import instagram from "../Images/Icons/instagram.png";
import youtube from "../Images/Icons/youtube.png";
import main from "../Images/Contents/Lifelikesong/lifelikesong_main.gif";
import mainStatic from "../Images/Contents/Lifelikesong/lifelikesong_main.png";
import img1a from "../Images/Contents/Lifelikesong/lifelikesong_1a.png";
import img2a from "../Images/Contents/Lifelikesong/lifelikesong_2a.png";
import img3a from "../Images/Contents/Lifelikesong/lifelikesong_3a.png";
import img4a from "../Images/Contents/Lifelikesong/lifelikesong_4a.png";
import img5a from "../Images/Contents/Lifelikesong/lifelikesong_5a.png";
import img6a from "../Images/Contents/Lifelikesong/lifelikesong_6a.png";
import img7a from "../Images/Contents/Lifelikesong/lifelikesong_7a.png";
import img8a from "../Images/Contents/Lifelikesong/lifelikesong_8a.png";
import img8b from "../Images/Contents/Lifelikesong/lifelikesong_8b.png";
import img9a from "../Images/Contents/Lifelikesong/lifelikesong_9a.png";
import img10a from "../Images/Contents/Lifelikesong/lifelikesong_10a.png";
import img10b from "../Images/Contents/Lifelikesong/lifelikesong_10b.png";
import img11a from "../Images/Contents/Lifelikesong/lifelikesong_11a.png";
import { BL, BT, OL, PT, IT, QT } from "../../Components/Utility";

const LifelikesongHeader = {
  stage: "Detail",
  category: "People",
  tags: ["역마살", "낭만", "배낭여행"],
  title: "LifeLikeSong.gif",
  desc: (
    <>
      Life is Like a Song<p></p>
      노래와 같은 삶을 즐기는 여행자
      <p></p>LifeLikeSong이 여행 중 부른 11곡의 노래
    </>
  ),
  themeColor: "#B9DEFE",
  bwColor: "black",
  channelArray: [
    { url: "https://www.instagram.com/life_like_song/", platform: instagram },
    {
      url: "https://www.youtube.com/channel/UCm-C7_7OcBJID_xCNbBKTNA",
      platform: youtube,
    },
  ],
  main: main,
  mainStatic: mainStatic,
  mainRatio: "16:9",
  end: "true",
  date: "2022년 3월 28일",
  credit: [
    { role: "edit", name: "junwoo lee", url: "https://junwoolee.me/" },
    {
      role: "design",
      name: "sowon lee",
      url: "https://www.instagram.com/wish._.lee/",
    },
  ],
};

const LifelikesongContents = (bwColor, themeColor) => [
  {
    video: "https://www.youtube.com/watch?v=mUWTkKYVok8",
    title: "Intro - 일어나",
    subTitle: "스페인, 그라뇽에서의 노래",
    desc: (
      <>
        <OL
          url={"https://www.youtube.com/watch?v=FRP2dstf5o4"}
          bwColor={bwColor}
        >
          원곡 | 김광석 - 일어나
        </OL>
        <BL></BL>
        항상 낭만적인 여행에 대한 꿈을 품고 있었어요. 그래서 군대가기 전 꼭 해외
        여행을 가야겠다고 다짐을 했죠. 후보지가 여러 곳 있었는데 결국 유럽에
        가기로 했어요. 당시 생각으론 유럽은 좀 안전할 것 같다는 생각이
        들었거든요. 저한테는 첫 해외 여행이라 그런 게 중요했던 것 같아요.
        <BL></BL>
        유럽 여행을 택한 후 저는 스페인 순례자의 길을 가기로 했어요. 그 땐 학생
        시절이라 돈이 없었는데 순례자의 길은 무전 여행하기 좋은 곳이었죠.
        여행자를 순례자와 같이 여겨 온정을 베푸는 문화가 오래도록 이어져오고
        있었으니까요. 직접 가보니 저 같은 배낭 여행자에게 먹을 것과 머물 곳을
        베풀어주는 분들이 많았어요.
        <BL></BL>
        <BT>
          지금부터 얘기할 그라뇽의 한 교회도 그런 경우 중 하나였는데요.
          이곳에서부터 저의 노래와 함께하는 여행이 시작되었어요.
        </BT>
        <BL></BL>약 500년의 역사를 가진 그 교회는 오랫동안 순례자들에게 머물
        곳을 제공해왔고 저 또한 그곳에서 하루 밤을 지냈죠. (어느 정도 기부금을
        내야하긴 하지만 거의 무료에 가까웠어요.)
        <BL></BL>
        <BT>
          교회에선 저녁 또한 제공을 해주는데 여기엔 한 가지 재밌는 조건이
          붙었어요. 바로 하루 밤을 함께 지낼 여행자들이 마을의 빵집까지 다 같이
          노래를 부르며 행진을 해야한다는 거였죠.
        </BT>
        <BL></BL>이 때 함께 부를 노래를 하나 정해야하는데 곡 선택은 자유였어요.
        그래서 여행자들끼리 의논을 했죠. 한국인이 7명, 외국인이 5명이었는데요.
        신기하게 외국인 친구들이 먼저 한국 노래를 제안해줬어요.
        <BL></BL>
        <BT>"너네가 어제 부르던 한국 노래 그걸로 하는 게 어때?"</BT>
        <BL></BL>
        외국인 친구들이 말한 노래는 김광석의 일어나였어요. 어떤 한국인 친구가
        전날 밤 숙소 파티에서 기타들고 부른 걸 외국인 친구들이 기억하고
        있었나봐요. 저희도 생각해보니 외국인들이 따라부르기 쉬울 것 같아서
        일어나가 행진곡으로 낙점되었죠.
        <BL></BL>
        <BT>
          그렇게 곡 선택을 마무리하고 저희는 교회에서부터 빵집까지 일어나를
          부르며 행진했어요. 근데 동네 사람들 20명 정도가 박수를 치면서 호응을
          해주더라구요. 스페인의 골목에서 한국의 포크송을 외국인들과 부르다니.
          제가 꿈꿔왔던 낭만적인 여행이 바로 이런 게 아닐까 싶었어요. 정말 꿈과
          같은 경험이었기에 제 뇌리에 강하게 박혔죠.
        </BT>
        <BL></BL>
        <PT
          img={img1a}
          desc={"그라뇽에서의 추억"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
        <BL></BL>
        그리고 그 강렬한 경험은 짙은 여운으로 남았고 그 뒤로 한 가지 결심을 하게
        됐어요.
        <BL></BL>
        <BT>기타를 배워보자!</BT> <BL></BL>
        기타를 배워두면 언젠가 다시 유럽에 여행갔을 때 같은 경험을 할 수 있을
        거라는 확신이 있었어요. 보통 숙소마다 기타를 갖춰놓고 있었으니까요.
        그래서 군대에서 열심히 기타를 배우기 시작했어요. 그 때의 낭만을 다시
        경험할 날이 언젠가 올 것이라 기대하며.
      </>
    ),
  },
  {
    video: "https://www.youtube.com/watch?v=-WPJ_mzdFCo",
    title: "루비 반지",
    subTitle: "포르투갈, 리스본에서의 노래",
    desc: (
      <>
        <OL
          url={"https://www.youtube.com/watch?v=EHT0aVUCSTY"}
          bwColor={bwColor}
        >
          원곡 | Rui Veloso - Anel de Rubi(루비 반지)
        </OL>
        <BL></BL>
        전역을 하고나서 바로 유럽에 갈 계획을 세웠어요. 저번엔 3개월이었지만
        이번엔 6개월로 더 길게 잡았죠. 근데 이 때도 주머니 사정이 안 좋긴
        마찬가지여서 돈에 대한 고민이 많았어요.<BL></BL>
        특히 숙소비가 걱정이었는데 어느 날 군대 도서관에서 우연히 카우치서핑을
        다룬 기사를 봤어요. 읽어보니 카우치서핑은 무료로 머물 곳이 필요한
        여행객과 숙소 호스트를 연결해주는 플랫폼이더라구요.<BL></BL>
        여행객들은 무료로 잘 곳을 제공받기 위해 호스트에게 자신이 흥미로운
        사람이라는 걸 어필하는 게 중요했어요. 호스트에게 나는 흥미로운 사람이니
        재워주면 재밌는 일이 있을 거야라는 설득의 이메일을 보내야하는 거죠.
        <BL></BL>
        <BT>
          쉽지는 않아보였지만 그래도 이거라면 숙소비를 아껴볼 수 있겠다는 생각이
          들었어요. 그래서 그 날부터 한달 동안 호스트들에게 사지방에서 메일을
          계속 보냈죠. 포르투갈 리스본이 첫번째 행선지였기 때문에 그쪽 호스트들
          위주로요.
        </BT>
        <BL></BL>
        근데 거의 50통을 보내도 호스트들에게선 기대했던 답을 들을 수 없었어요.
        대부분 회신을 아예 안했고 7명 정도만 거절해서 미안하다는 답장을 줬어요.
        <BL></BL>
        <BT>
          그래서 거의 반 포기 상태였는데 어떤 분에게 오케이라는 답장이 왔어요.
          메일을 보는 순간 정말 감격 그 자체였고 감동을 먹었어요. 그 어떠한
          호스트 후기도 없는 절 받아준 거니까요. 고마운 마음이 너무 커서
          호스트에게 뭔가 선물을 해줄 수 없을까 생각을 하게 됐어요. 그리고 하나
          생각해낸 게 포르투갈 노래를 배워가는 거였죠.
        </BT>
        <BL></BL>
        마침 호스트의 집에 기타가 있다고 해서 기타 연주도 준비해야겠다 마음먹고
        같이 연습을 했어요. 고마운 마음만큼 열심히 준비해서 포르투갈에 갔죠.
        <BL></BL>
        <BT>
          그리고 호스트의 집에 도착한 대망의 날. 저는 준비한 루비 반지라는
          포르투갈 노래를 호스트 앞에서 기타 반주와 함께 불렀어요. 근데 기대했던
          것보다 더 좋아해주더라구요. 혼자서 보기 아까웠는지 친척들에게까지 제가
          노래부르는 모습을 보여줬어요.
        </BT>
        <BL></BL>
        <PT
          img={img2a}
          desc={"첫번째 호스트"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
        <BL></BL>
        사람들이 좋아하는 모습을 보니 저도 즐거웠어요. 그리고 음악을 통해
        사람들과 훨씬 더 쉽고 빠르게 가까워질 수 있겠구나라는 생각도 하게
        됐어요. 구구절절 나에 대해 소개하는 것보다 훨씬 더 효과적인 방법을 찾은
        거였죠. 그래서 기왕 배워간 포르투갈 노래를 다양한 상황에서 활용해보기로
        했어요.
        <BL></BL>
        <BT>
          특히 기억에 남는 건 스페인에서 카풀을 했을 때였는데요. 마침 기사분이
          포르투갈 분이셔서 차 안에서 즉석으로 노래를 불러드렸어요. 그랬더니
          엄청 좋아하시면서 직접 만든 수제 구두를 선물해주시더라구요. 알고보니
          이 분이 바르셀로나 축제에 가고 있던 구두 상인이셨던 거예요. 얼마나
          좋았으면 이런 비싼 구두를 선물해줬을까. 감격스러운 순간이었죠.
        </BT>
        <BL></BL>그 뒤로 저는 각 나라별로 노래를 배워봐야겠다는 생각을 하게
        됐어요. 사람들에게 더 친근하고 재밌게 다가가는 것은 물론 카우치서핑을
        잡는 거에도 도움이 될 거라 생각했거든요. 실제로 이후 카우치서핑 성공률이
        거의 40%까지 올라갔어요. 저만의 마케팅 비법을 발견한 것 같아
        뿌듯하더라구요.
        <BL></BL>
        <BT>
          카우치서핑 첫 호스트를 위해 준비한 포르투갈 노래가 이렇게 다양한
          가능성과 연결되다니. 어디로 튈지 모르는 게 여행의 매력이란 걸 다시금
          느꼈어요.
        </BT>
      </>
    ),
  },
  {
    video: "https://www.youtube.com/watch?v=jKU5BA1ajMs",
    title: "푸른색",
    subTitle: "이탈리아, 베네치아에서의 노래",
    desc: (
      <>
        <OL
          url={"https://www.youtube.com/watch?v=HCyzGuipTd4"}
          bwColor={bwColor}
        >
          원곡 | Adriano Celentano - Azzurro(푸른색)
        </OL>
        <BL></BL>
        스페인, 프랑스, 스위스 등 여러나라를 돌아다니다 베니스에 도착했어요.
        입대 전 순례자의 길에서 만난 친구가 베니스에 살고 있어서 놀러간 거였죠.
        <BL></BL>그 때 저는 노래와 함께하는 여행에 푹 빠진 터라 베니스에서도
        노래를 부르기로 했어요. 베니스에 살고 있는 친구와 함께 거리에서 이탈리아
        노래를 부르기로 했죠.<BL></BL>
        근데 막상 나가려고 하니 길거리에서 노래를 부르는 건 제 친구에게 부담이
        되는 일이었나봐요. 그래서 함께 노래부를 사람을 더 불러보자고 말하고
        페이스북에 모집 글을 올렸어요.
        <BL></BL>
        <BT>
          사실 전 누가 관심을 가질까 의아했어요. 근데 한 커플이 저희와 함께
          노래를 부르고 싶다고 연락을 줬어요. 노래를 통해 서로 모르는 사이에서
          이렇게 즉석 만남이 성사되다니. 한국에서는 이런 경우를 본 적이 없어서
          신기했어요.
        </BT>
        <BL></BL>
        그렇게 저희는 베니스의 길바닥에서 만나 함께 노래를 불렀어요. 생전
        처음보는 사이인데도 푸른색(Azzurro)이라는 이탈리아에서 유명한 응원곡을
        함께 부르니 친밀감이 쌓이더라구요.
        <BL></BL>
        <BT>
          그리고 친밀감이 쌓이니 유튜브 영상을 찍어도 되냐는 말에 흔쾌히
          오케이를 해줬어요. 제가 알기로 유럽 사람들은 미디어 노출을 조금 꺼리는
          부분이 있었는데 의외였죠. 이렇게 친밀감이 쌓이면 얘기가 다르구나
          생각이 들었어요.
        </BT>
        <BL></BL>
        <PT
          img={img3a}
          desc={"베네치아에서의 인연"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
        <BL></BL>
        저는 이 베니스에서의 일을 계기로 노래 부르는 걸 영상으로 더 열심히
        기록해보자 결심하게 됐어요. 기왕하는 여행 최대한 많이 남기면 좋으니까요.
      </>
    ),
  },
  {
    video: "https://www.youtube.com/watch?v=S5BVz-as9V4",
    title: "그녀가 변했어요",
    subTitle: "독일, 프랑크푸르트에서의 노래",
    desc: (
      <>
        <OL
          url={"https://www.youtube.com/watch?v=6tVcbbMcVuw"}
          bwColor={bwColor}
        >
          원곡 | Saad Lamjarred - Mal Hbibi Malou(그녀가 변했어요)
        </OL>
        <BL></BL>
        노래하며 여행하는 거에 재미가 들려서 독일로 넘어가기까지 8개의 곡을 그
        나라의 언어로 불렀어요. 근데 이렇게 다양한 나라에서 다양한 언어로 노래를
        부르다보니까 욕심이 생겼어요. 어디 또 배워볼 새로운 언어의 노래가
        없을까하고요.
        <BL></BL>
        <BT>
          그러다 독일에서 한 아랍 친구를 만났어요. 카우치서핑 사이트를 통해서
          만난 친구인데 제게 재워주긴 어렵지만 아랍 노래를 함께 부를 수 있냐고
          물어보더라구요. 이런 경우는 또 처음이었는데 아랍 노래라 하니
          흥미로웠어요. 아랍 노래도 하나 부를 줄 알면 좋겠다는 생각이 들었죠.
        </BT>
        <BL></BL>
        그래서 곧바로 오케이를 했고 저흰 프랑크프루트 공원에서 만났어요. 저희는
        그녀가 변했어요라는 아랍의 사랑 노래를 부르기로 했는데 신기한 포인트가
        있었어요. 사랑 노래임에도 가사에 신(God)이 자주 등장하더라구요. '신만이
        내 마음을 알겠지'와 같은 식으로요. 문화 자체가 종교와 밀접해서 그런 것
        같았어요.
        <BL></BL>
        <BT>
          이렇게 다른 나라의 언어와 노래를 함께 배우니 그 나라의 문화까지도 알게
          되어 참 좋더라구요. 몸은 독일에 있지만 아랍 친구 그리고 아랍 노래와
          함께 이슬람 지역까지 여행을 다녀온 느낌이 들었어요.
        </BT>
        <BL></BL>
        <PT
          img={img4a}
          desc={"프랑크푸르트와 아랍"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
      </>
    ),
  },
  {
    video: "https://www.youtube.com/watch?v=MXo2Bp1SUQo",
    title: "세상 저편에서",
    subTitle: "핀란드, 어떤 외딴 호수에서의 노래",
    desc: (
      <>
        <OL
          url={"https://www.youtube.com/watch?v=sS6Ta6SrTHs"}
          bwColor={bwColor}
        >
          원곡 | Haloo Helsinki - Maailman Toisella Puolen(세상 저편에서)
        </OL>
        <BL></BL>
        카우치서핑을 하다보면 호스트들에게 어디가 제일 좋았냐는 질문을 종종 듣게
        돼요. 근데 저 같은 경우 어느 나라 노래가 제일 좋았냐는 질문을 가장 많이
        받았어요. 카우치서핑 프로필에 제가 부른 노래들을 기록해놨기 때문이겠죠.
        <BL></BL>
        <BT>
          그런 질문을 받았을 때 가장 먼저 떠오르는 음악이 있어요. 바로
          핀란드에서 부른 세상 저편에서(Maailman Toisella Puolen)라는 노래예요.
        </BT>
        <BL></BL>
        핀란드엔 이전에 순례자의 길에서 만난 친구가 살고 있어서 방문했어요. 마침
        핀란드에선 세인트 요하네스라는 하지 축제를 하고 있어 잘됐다 싶었죠. 하지
        축제 때 핀란드 사람들은 별장에 가서 하루 종일 사우나하고 술 먹으며
        휴식을 취한다고 하더라구요. 저도 그래서 핀란드 친구와 함께 별장에
        놀러가게 됐어요.
        <BL></BL>
        <PT
          img={img5a}
          desc={"핀란드의 별장에서"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
        <BL></BL>
        그곳에서 제 친구에게 세상 저편에서(Maailman Toisella Puolen)라는 노래를
        배웠어요. 여행에 관한 노래인데 시작부터 여행의 설렘이 느껴지는
        곡이었어요.<BL></BL>{" "}
        <QT bwColor={bwColor}>
          <BT>
            <IT>
              Nyt hihnalle laukku Ja viimeinen kuppi naamaan Kohta pilvien
              päältä Voin muistaa tämän maan.
            </IT>
            <p></p>
            케리어를 끌고 공항에 가서 커피 한잔을 마시지. 그리고 얼마 지나지
            않아 엄마 저는 세상 반대편에 있어요.
            <p></p>
            <IT>Ei mulla oo tarkkaa suuntaa Mä menen minne sattuma johtaa.</IT>
            <p></p>
            이제 곧 구름위에 올라 이 나라를 기억하게 되겠지.
          </BT>
        </QT>
        <BL></BL>
        여행지에서 불러서 그랬을까요. 저에겐 외국어 노래이지만 부르면서 감정
        이입이 많이 됐어요. 아 내가 지금 여행을 하고 있구나. 오랜 기간의 여정에
        무뎌질 수 있는 여행의 감각이 다시 파릇파릇해지는 느낌이 들었어요.
        <BL></BL>
        지금도 그래요. 이 노래를 흥얼거리다보면 여행 세포가 깨어난다고
        해야할까요.
      </>
    ),
  },
  {
    video: "https://www.youtube.com/watch?v=8nMJnYSvpho",
    title: "나의 여제",
    subTitle: "크로아티아, 스플리트에서의 노래",
    desc: (
      <>
        <OL
          url={"https://www.youtube.com/watch?v=4lWgvFQX2MM"}
          bwColor={bwColor}
        >
          원곡 | Oliver Dragojevic - Cesarica(나의 여제)
        </OL>
        <BL></BL>
        상상만 하던 일이 실제로 벌어진 적이 있어요. 크로아티아에서 있었던 일인데
        그 때만 생각하면 지금도 신기해요.
        <BL></BL>
        크로아티아의 스플리트라는 도시에서도 저는 카우치서핑을 했는데요.
        호스트가 구시가지의 베스티블이라는 빈 원통형 돌 건물에 대해 말해줬어요.
        건물 구조 덕분에 그 안에서 노래를 부르면 에코 효과가 나서 노래가 더
        있어보인다고 하더라구요. 그래서 저희는 그곳에서 함께 크로아티아 노래를
        부르기로 했어요.
        <BL></BL>
        실제로 가보니 노래하기 참 좋은 곳이었어요. 근데 아쉽게도 저희보다 먼저
        노래를 하고 있는 아카펠라 그룹이 있었어요. 호스트와 저는 옆에서 구경을
        하며 노래를 부를 기회를 노렸는데 자리가 안 났죠. 그래서 용기를 내서
        아카펠라 그룹에게 노래 한번만 부를 수 있냐고 물어봤는데 차갑게 거절을
        하더라구요.<BL></BL>
        <BT>
          그래서 쭈구리처럼 있었는데 그냥 가기는 아쉬워서 호스트랑 구석에서 곡
          하나만 부르고 가기로 했어요. 연습한 크로아티아 노래를 불렀는데
          아카펠라 그룹의 한 사람이 들었나봐요. 그리고 한국인이 크로아티아
          노래를 부르니 흥미가 생겼는지 저희에게 관심을 갖더라구요. 저희에게
          같이 노래를 불러보는 게 어떻겠냐고 물어봤어요.
        </BT>
        <BL></BL>
        처음엔 냉정하게 거절을 했던 사람들이 흥미를 가질 정도라니. 저의 노래가
        차가운 사람의 마음을 돌려놓았다는 게 정말 좋았어요. 저와 호스트는
        아카펠라 그룹의 합창 제안을 받아드렸고 함께 노래하게 됐어요.
        <BL></BL>
        <BT>
          그 다음에 일어난 일이 정말 꿈 같은 일이었는데 아카펠라 그룹이 저에게
          화음을 맞춰 합창을 해줬어요. 제대로 옷을 차려입고 아카펠라를 하는
          사람들 사이에서 메인으로 노래를 부른 셈이죠. 중세 유적지 같은 곳에서
          불러서인지 더 낭만적이게 느껴졌어요.
        </BT>
        <BL></BL>
        저는 가사를 다 외워서 완곡을 했는데 그걸 아카펠라 그룹이 엄청 인상적으로
        받아들이더라구요. 그래서 노래를 다 부르고 같이 맥주도 마시러 갔어요.
        <BL></BL>
        <PT
          img={img6a}
          desc={"아카펠라 그룹과 함께"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
        <BL></BL>
        상상도 하지 못했던 인연을 만나 만든 멋진 장면이었기에 기억에 더 남는 것
        같아요. 윌터의 상상이 현실이 된다라는 영화처럼 저에게도 꿈꾸기만 했던
        일이 실현된 날이었어요.
        <BL></BL>
        그리고 여담이지만 이 날 찍은 유튜브 영상이 크로아티아의 네이버 같은 곳에
        소개되어 높은 조회 수를 찍었어요. 아마 제 영상 중 조회 수가 제일 높을
        거예요. 조회 수를 기대하고 찍은 영상은 아니지만 그래도 참 뿌듯했어요.
      </>
    ),
  },
  {
    video: "https://www.youtube.com/watch?v=PfZ9yfZd_qg",
    title: "자유",
    subTitle: "크로아티아, 자그레브에서의 노래",
    desc: (
      <>
        <OL
          url={"https://www.youtube.com/watch?v=tfpqKPsAjAM"}
          bwColor={bwColor}
        >
          원곡 | Nino Bravo - Libre(자유)
        </OL>
        <BL></BL>
        유럽을 돌아다니며 많은 노래를 불렀는데 가장 미련이 남는 건 스페인
        노래였어요. 스페인 노래도 많이 부르긴 했지만 영상으로 기록을 따로 못
        남겼거든요. 그래서 크로아티아 자그레브에서 만난 스페인 친구에게 즉석에서
        함께 노래를 부르자고 했어요. (그러고보니 이 친구도 카우치서핑을 통해
        만난 친구네요.)<BL></BL>
        <BT>
          고맙게도 스페인 친구는 승낙을 해줬고 저희는 길바닥에서 함께 노래를
          불렀어요. 자유(Libre)라는 음악이었는데 함께 노래를 부른 스페인 친구와
          참 어울리는 곡이었어요. 정말 자유로운 친구였거든요.
        </BT>
        <BL></BL>이 친구도 저처럼 노래와 함께 여행을 다녔어요. 우쿨렐레를 들고
        도로에서 노래를 하며 함께 히치하이킹을 했던 기억은 아직도 선해요. 제가
        즉석에서 노래 부르자고 했을 때 흔쾌히 승낙해준 이유가 있었던 거죠.
        <BL></BL>
        <PT
          img={img7a}
          desc={"자유로운 스페인 친구"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
        <BL></BL>더 얘기를 나눠보니 인생과 여행을 병행하는 사람이라는 생각이
        들었어요. 각 지역을 여행하듯이 돌아다니며 공부도 하고 살아가는 친구였죠.
        언제는 영국 런던에 있다가, 또 언제는 미국에 있다가. 참 바람 같은
        사람이었어요. <BL></BL>
        <BT>
          당시 저 자신을 돌아보면 사실 여행이라는 걸 일회성이라 생각했던 것
          같아요. 언젠가 다시 일상으로 돌아가야 하기에 언젠가는 끝나는 여행.
          근데 스페인 친구와 함께 노래를 하고 다니면서 생각이 바뀌었어요. 삶
          자체가 곧 여행이 될 수 있겠구나. 이런 생각을 하기 시작한 거죠.
        </BT>
        <BL></BL>
        물론 현실적인 문제들이 있지만 그럴 때마다 스페인 친구와 함께 불렀던
        자유(Libre)라는 음악을 떠올려요.
        <BL></BL>
        <QT bwColor={bwColor}>
          <BT>
            <IT>
              Libre, como el sol cuando amanece, yo soy libre como el mar...
            </IT>
            <p></p>저 떠오르는 태양처럼, 또 바다처럼 나는 자유로워.
          </BT>
        </QT>
      </>
    ),
  },
  {
    video: "https://youtu.be/_KfOTynckuQ",
    title: "일어나(외국인 버전)",
    subTitle: "보스니아, 브르치코에서의 노래",
    desc: (
      <>
        <OL
          url={"https://www.youtube.com/watch?v=FRP2dstf5o4"}
          bwColor={bwColor}
        >
          원곡 | 김광석 - 일어나
        </OL>
        <BL></BL>
        <BT>
          보스니아에서 저를 재워준 호스트는 두 명의 룸메이트였는데 이전의
          호스트들과는 뭔가 달랐어요. 저에게 보스니아가 아닌 한국어 노래를 함께
          불러보고 싶다고 말해줬거든요. 그런 제안을 한 호스트는 처음이었어서
          신기했어요.
        </BT>
        <BL></BL>
        근데 얘기를 해보니 둘 다 언어학 석사를 공부하는 대학원생이고 함께 밴드를
        하고 있는 친구들이더라구요. 그래서 나한테 그런 제안을 할 만한
        사람들이구나라는 생각이 들었어요.<BL></BL>
        <PT
          img={img8a}
          desc={"밴드하는 친구들"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
        <BL></BL>
        진지한 친구들이라 함께 지내며 진지한 이야기를 많이 했어요. 보통
        카우치서핑을 할 땐 일상 얘기를 많이 하는 편인데 두 친구들과는 각 나라의
        정치, 문화, 철학 등에 대한 깊은 얘기를 나눴죠.
        <BL></BL>
        특히 보스니아에 대한 얘기를 많이 들을 수 있었는데 흥미로웠어요. 종교를
        이유로 한 나라가 세 개의 문화권으로 나뉘어 있다는데 사용하는 언어는
        같다고 하더라구요. 그렇게 된 역사에 대해 듣는데 알쓸신잡처럼 재밌었어요.
        내가 여행하는 곳에 대해 더 자세히 알아가는 그 느낌이 참 좋기도 했구요.
        <BL></BL>
        <BT>
          이런 즐거운 경험을 남겨준 친구들이었기에 저도 한국 노래를 최대한 잘
          가르쳐줘야겠다는 생각이 들었어요. 김광석의 일어나를 가르쳐줬죠. 제가
          좋아하기도 하고 저한테 의미가 있는 곡이기도 했으니까요.
        </BT>
        <BL></BL>
        호스트 친구가 배우는 것에 애를 먹긴 했지만 노력하는 모습만으로도 보기
        좋았어요. 원래 K-POP에 관심이 있던 친구도 아니었거든요. 지적인
        욕구만으로 쉽지 않은 도전을 해내는 게 멋있었어요.
        <BL></BL>
        <PT
          img={img8b}
          desc={"일어나를 연습하는 친구"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
        <BL></BL>
        함께 노래를 부르고 깊이 있는 대화를 나누는 즐거움. 그 즐거움 덕분에
        보스니아에 발만 담그는 게 아니고 푹 빠져있다가 나온 것 같은 느낌이
        들었어요.
      </>
    ),
  },
  {
    video: "https://youtu.be/RR99pli2y6w",
    title: "나의 동지들",
    subTitle: "세르비아, 베오그라드에서의 노래",
    desc: (
      <>
        <OL
          url={"https://www.youtube.com/watch?v=B9y7UX3wUow"}
          bwColor={bwColor}
        >
          원곡 | Bajaga - Moji su drugovi(나의 동지들)
        </OL>
        <BL></BL>
        보스니아에서 세르비아로 넘어왔는데 카우치서핑을 잡는 것에 실패했어요.
        그래서 어쩔 수 없이 호스텔을 예약할 수밖에 없었죠. 근데 히치하이킹엔
        성공해서 차를 얻어타는 중 기적 같은 일이 일어났어요.
        <BL></BL>
        <BT>
          태워주신 아저씨에게 감사의 의미로 세르비아와 크로아티아 노래를
          불러드렸는데 참 좋아하시더니 제게 머물 곳을 제공해준다고 하셨어요. 그
          분 딸이 베오그라드에 사는데 마침 비어있다면서요.
        </BT>
        <BL></BL>
        차를 태워주신 건 물론 이렇게 머물 곳까지 마련해주시다니. 보스니아에 이어
        세르비아까지 경험하며 발칸 반도의 사람들이 참 친절하다는 생각을 하게
        됐어요. 실제로 발칸 반도에선 히치하이킹 성공률이 높고 기다리는 시간도
        짧기도 했구요.
        <BL></BL>
        어쨌든 전 그렇게 좋은 느낌으로 세르비아에 들어왔고 그 다음 날 워킹
        투어를 신청했어요. 제가 어딘가에 좋은 워킹 투어가 있다면 놓치지 않고
        하는 편이거든요.
        <BL></BL>
        워킹 투어를 하고 가이드에게 같이 노래를 같이 불러줄 수 있냐고
        물어봤어요. 히치하이킹을 해준 세르비아 아저씨에게 불러준 세르비아
        노래였는데 유명해서 다들 알더라구요.
        <BL></BL>
        그랬더니 가이드 친구가 좋다고 하고 기타를 칠 줄 아는 친구까지
        불러줬어요. 그렇게 세 명이 모여서 나의 동지들(Moji su drugovi)라는
        노래를 부르게 됐죠.
        <BL></BL>
        나의 동지들(Moji su drugovi)은 공산 시절의 음악이라 약간 그 때의 느낌이
        나는 올드한 곡이었는데요. 그래도 가사가 마음에 들었어요.
        <BL></BL>
        <QT bwColor={bwColor}>
          <BT>
            <IT>
              Moji su drugovi biseri rasuti po celom svetu I ja sam selica pa ih
              po nekad sretnem u letu Da l` je to sudbina, il ko zna šta li je
              kad god se sretnemo, uvek se zalije uvek se završi, s nekom od
              naših pesama.
            </IT>
            <p></p>
            나의 동지는 세상 곳곳에 흩어진 진주들. 나는 한 마리의 새가 되어 가끔
            그들과 재회하네. 운명인지는 몰라도 한 가지는 확실하지. 언제 만나든,
            한 잔 술로 목을 축이고 우리의 노래로 끝을 맺는다는 것.
          </BT>
        </QT>
        <BL></BL>
        노래를 부르며 언젠가 가사의 여행자처럼 발칸 반도의 친절한 동지들을 다시
        보고 싶다라는 생각을 했어요. 그만큼 저에겐 발칸 반도의 사람들이
        인상적이고 따뜻하게 느껴진 거죠. 언젠가 그들의 온정을 느끼러 다시 발칸
        반도에 가고 싶어요.
        <BL></BL>
        <PT
          img={img9a}
          desc={"가이드와 함께 한 선상 파티"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
      </>
    ),
  },
  {
    video: "https://www.youtube.com/watch?v=Tyb4jzhHNPI",
    title: "지중해의 밤",
    subTitle: "터키, 이스탄불에서의 노래",
    desc: (
      <>
        <OL
          url={"https://www.youtube.com/watch?v=c_MhDZ3do08"}
          bwColor={bwColor}
        >
          원곡 | Haluk Levent - Akdenız akşamları(지중해의 밤)
        </OL>
        <BL></BL>
        유럽에서의 마지막 여행지, 이스탄불에 도착했어요. 마지막 여행지에서도
        카우치서핑을 하고 싶다는 생각에 아예 호텔이나 호스텔 예약을 안했죠. 근데
        히치하이킹을 해서 태워주신 아저씨가 저를 희안한 곳에 내려줬어요. 서울로
        치면 강남이나 홍대 같은 곳이 아닌 노원 같은 곳에요.
        <BL></BL>
        내린 곳이 큰 상업 지구나 관광지가 아니라 잘 곳을 찾는 게 너무
        어려웠어요. 그래서 결국엔 공항에 가서 하룻밤을 지낼 수밖에 없었죠.
        공항에서 자며 어쩔 수 없이 호스텔을 잡아야하나 생각을 했는데 한 터키
        부부가 카우치서핑을 해도 된다고 연락주셨어요.
        <BL></BL>
        마지막 여행지에서도 카우치서핑을 할 수 있어서 참 다행이었어요.
        호스트들과 또 노래부를 수 있었으니까요. 터키 부부는 저에게 지중해와
        관련된 지중해의 밤(Akdenız akşamları)이라는 노래를 가르쳐줬어요.
        <BL></BL>
        <PT
          img={img10a}
          desc={"유럽 여행 마지막 호스트"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
        <BL></BL>
        저희는 노래 제목에 맞춰 밤에 지중해가 보이는 곳에서 노래를 불렀어요. 참
        낭만적이더라구요.
        <BL></BL>
        <BT>
          근데 노래를 부르며 애틋한 마음이 들었어요. 아 이제 진짜 마지막이구나
          하며 6개월 동안의 여정이 떠올랐죠. 사실 그 전까지는 무전 여행이기도
          해서 조금 지치기도 했었거든요. 땡볕에서 히치하이킹하고 바깥에서
          노숙하고 카우치서핑 메일쓰는 여행이 녹록치는 않았어요. 하지만 노래를
          부르니 여태 느낀 피로감은 가고 지나온 여행들에 대한 아쉬움이
          커지더라구요.
        </BT>
        <BL></BL>
        유럽 여행을 좋은 감정으로 마무리할 수 있게 해준 노래라 지중해의
        밤(Akdenız akşamları)을 지금도 종종 혼자 부르곤 해요. 유럽 여행에 대한
        향수를 불러일으키는 노래죠. 언젠가 또 다시 이스탄불에서 지중해의 밤을
        부르는 날이 오면 좋겠네요.
        <BL></BL>
        <PT
          img={img10b}
          desc={"유럽 여행 지도"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
      </>
    ),
  },
  {
    video: "https://www.youtube.com/watch?v=Lqe7pAaw3EE",
    title: "Outro - 브라반트",
    subTitle: "한국, 서울에서의 노래",
    desc: (
      <>
        <OL
          url={"https://www.youtube.com/watch?v=YaIzl1Tz-so"}
          bwColor={bwColor}
        >
          원곡 | Guus Meeuwis - Brabant(브라반트)
        </OL>
        <BL></BL>
        유럽 여행을 통해서 내가 상상했던 게 실현이 될 수 있구나라는 걸 알게
        됐어요. 그래서 귀국한 후 좋아하는 걸 더 적극적으로 시도해보기로 마음을
        먹었죠.
        <BL></BL>
        내가 뭘 해볼 수 있을까 생각을 하다가 유럽에서 절 도와준 사람들이
        생각났어요. 저도 그들처럼 한국에 온 여행객들을 도와주면 어떨까
        싶더라구요.
        <BL></BL>
        그래서 처음엔 외국인 여행객들이 주로 묵는 호스텔에서 리셉션 일을 했어요.
        그곳에서 여행객들이 한국에서 더 좋은 경험을 하고 갈 수 있도록 도와줬죠.
        같이 친하게 지내며 노래를 부르기도 했구요. 근데 어디까지나 호스텔 안에
        묶여있어야 한다는 한계가 있어 아쉬웠어요.
        <BL></BL>
        <BT>
          더 적극적으로 도울 방법은 없을까 하다가 워킹 투어를 시작해봤어요. 외국
          친구들에게 한국의 숨겨진 매력을 보여주고 싶은 마음이 컸거든요. 제가
          유럽에서 했던 워킹 투어에서 각 여행지의 숨은 매력을 알게된 것처럼요.
        </BT>
        <BL></BL>
        본격적으로 가이드를 시작하기 전 마음이 맞는 동료도 구하고 답사도 많이
        다녔어요. 가이드라는 게 생각보다 발품을 많이 팔고 공부도 많이 해야되는
        일이더라구요. 그래도 공을 들이니 워킹 투어를 시작했을 때 외국인 친구들의
        반응이 참 좋았어요. 또 다 같이 친해져서 투어가 끝나고도 함께 놀았죠.
        <BL></BL>
        <BT>
          근데 그렇게 가이드를 해줬던 여행객들 중에 기억에 남는 친구들이 있어요.
          네덜란드 친구들인데 투어가 끝나고 저에게 유료 가이드를 제안해줬어요.
          곧 자신들의 가족이 한국에 오는데 이틀 동안 패키지를 짜서 가이드해줄 수
          있냐고 얘기하더라구요.
        </BT>
        <BL></BL>
        흔치 않은 경험일 것 같아 저는 승낙했고 저흰 더 친해져서 함께 노래를
        부르러 한강에 갔어요. 브라반트(Brabant)라는 네덜란드 도시에 대한
        노래였는데 같이 부르다보니 유럽 여행 때가 생각나서 참 좋았어요. 유료
        가이드도 제안받고 같이 노래도 부르고 참 뿌듯했는지 그 네덜란드 친구들을
        위해 뮤직 비디오 형태로 여행기도 만들어줬던 기억이 나네요.
        <BL></BL>
        <PT
          img={img11a}
          desc={"워킹 투어 가이드"}
          bwColor={bwColor}
          themeColor={themeColor}
        ></PT>
        <BL></BL>
        <BT>
          이런 경험을 하니까 유럽 여행에 다녀오고 나서도 여행은 계속되고
          있구나라는 생각이 들었어요. 여행의 불꽃이 지지 않은 느낌. 삶이 곧
          여행이라는 그 감각이 제게 생긴 것 같았어요.
        </BT>
        <BL></BL>
        물론 시간이 흘러 지금의 전 조금 현실적인 사람이 되었어요. 근데 요새 취미
        삼아 다시 기타를 배우기 시작했는데 예전 생각이 많이 나요. 기타를 들고
        사람들과 노래를 불렀던 여행의 낭만적인 장면들. 무작정 기다리지 않고
        이제부터 그런 장면들을 직접 만들어갈 생각이에요.
        <BL></BL>
        <BT>노래가 어디에나 있듯 여행도 그럴 테니까요.</BT>
      </>
    ),
  },
];

export { LifelikesongHeader, LifelikesongContents };
