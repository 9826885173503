import React from "react";
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import View from "../Routes/View";
import RootChangeTracker from "./RootChangeTracker";

export default () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={View} />
        <Route path="/about" exact component={View} />
        <Route path="/junwoolee" exact component={View} />
        <Route path="/mojitto" exact component={View} />
        <Route path="/gandanman" exact component={View} />
        <Route path="/guridang" exact component={View} />
        <Route path="/lifelikesong" exact component={View} />
        <Redirect from="*" to="/" />
      </Switch>
      <RootChangeTracker></RootChangeTracker>
    </Router>
  );
};
