import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { useInView } from "react-intersection-observer";
import { BD } from "./Utility";

const Box = styled.div`
  backdrop-filter: blur(8px);
  background-color: ${(props) => (props.cdColor ? props.cdColor : "none")};
  color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"};
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  border: 1px solid;
  border-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.1)"};
  margin-bottom: 20px;
  overflow: hidden;
  cursor: ${(props) => (props.stage === "Home" ? "pointer" : "default")};
  &:hover {
    border-color: ${(props) =>
      props.stage === "Home"
        ? props.bwColor === "white"
          ? "rgba(255,255,255,1)"
          : "rgba(0,0,0,1)"
        : "default"};
  }
  &:hover .tagChips {
    transition: 0.5s ease-in-out;
    opacity: 1;
  }
`;

const TBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  background-color: ${(props) =>
    props.subColor ? props.subColor : "rgba(0,0,0,1)"};
  color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(255,255,255,1)"};
`;

const IssueNumber = styled.div`
  opacity: 1;
`;

const Category = styled.div`
  opacity: 0.5;
`;

const BBox = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
`;

const BLBox = styled.div`
  width: 100%;
  padding-top: ${(props) =>
    props.ratio ? (props.ratio === "16:9" ? "56.25%" : "100%") : "0px"};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${(props) =>
    `rgba(0,0,0,1) url(${
      props.hover ? props.gif : props.img
    }) no-repeat center center`};
  background-size: cover;
`;

const BRBox = styled.div`
  border-top: 1px solid;
  border-top-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.1)"};
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 10px;
`;

const BRTBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const TitleLine = styled.div`
  height: 16px;
  margin-right: 8px;
  width: 5px;
  background-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"};
  display: ${(props) => (props.stage === "Home" ? "none" : "default")};
`;

const Title = styled.div`
  font-size: 20px;
  font-family: ${(props) =>
    props.stage === "Home" ? `"Outfit", sans-serif` : "default"};
  font-weight: ${(props) => (props.stage === "Home" ? "800" : "800")};
`;

const SubTitle = styled.div`
  margin-bottom: 12px;
  line-height: 1.8;
`;

const Border = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255, 0.05)" : "rgba(0,0,0,0.1)"};
  height: 1px;
  margin-bottom: 12px;
`;

const Desc = styled.div`
  font-weight: 200;
  line-height: 1.8;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255, 0.6)" : "rgba(0,0,0,0.6)"};
`;

const BRBBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagChip = styled.div`
  margin-bottom: 10px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: black;
  color: white;
  opacity: 0.5;
`;

const TagText = styled.span`
  opacity: 1;
  font-weight: 200;
  font-size: 10px;
`;

const Card = ({
  id,
  stage,
  themeColor,
  subColor,
  bwColor,
  cdColor,
  category,
  leftText,
  title,
  subTitle,
  desc,
  img,
  gif,
  goTo,
  tags,
  ratio,
  video,
  path,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
  });
  const [hover, setHover] = useState(false);
  const hoverEvent = (hv) => {
    if (stage === "Home") {
      setHover(hv);
    } else {
      return;
    }
  };
  const goToFunc = () => {
    if (goTo) {
      setHover(false);
      goTo(title);
    } else {
      return;
    }
  };
  useEffect(() => {
    if (stage === "Detail") {
      if (inView) {
        document.getElementById(`${id}icon`).style.color =
          bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)";
        document.getElementById(`${id}title`).style.color =
          bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)";
        document.getElementById(`${id}icon`).style.fontWeight = "400";
        document.getElementById(`${id}title`).style.fontWeight = "400";
      } else {
        document.getElementById(`${id}icon`).style.color =
          bwColor === "white" ? "rgba(255,255,255,0.5)" : "rgba(0,0,0,0.5)";
        document.getElementById(`${id}title`).style.color =
          bwColor === "white" ? "rgba(255,255,255,0.5)" : "rgba(0,0,0,0.5)";
        document.getElementById(`${id}icon`).style.fontWeight = "200";
        document.getElementById(`${id}title`).style.fontWeight = "200";
      }
    }
  }, [inView, path]);
  return (
    <Box
      ref={ref}
      className="dD"
      id={id}
      bwColor={bwColor}
      cdColor={cdColor}
      stage={stage}
      onClick={goToFunc}
      onMouseEnter={() => hoverEvent(true)}
      onMouseLeave={() => hoverEvent(false)}
      onTouchStart={() => hoverEvent(true)}
      onTouchEnd={() => hoverEvent(false)}
    >
      <TBox subColor={subColor} bwColor={bwColor}>
        <IssueNumber>{leftText}</IssueNumber>
        <Category>{category}</Category>
      </TBox>
      <BBox>
        {img || gif || video ? (
          <BLBox
            hover={hover}
            img={img ? img : null}
            gif={gif ? gif : null}
            ratio={ratio ? ratio : "16:9"}
          >
            {video ? (
              <ReactPlayer
                className="react-player"
                url={video}
                width="100%"
                height="100%"
              ></ReactPlayer>
            ) : null}
          </BLBox>
        ) : null}

        <BRBox bwColor={bwColor}>
          <BRTBox>
            <TitleBox>
              <TitleLine stage={stage} bwColor={bwColor}></TitleLine>
              <Title stage={stage}>{title}</Title>
            </TitleBox>
            <SubTitle>{subTitle}</SubTitle>
            {stage === "Home" ? null : <BD bwColor={bwColor}></BD>}
            <Desc bwColor={bwColor}>{desc}</Desc>
          </BRTBox>
          <BRBBox>
            {tags
              ? tags.map((tag, index) => (
                  <TagChip
                    themeColor={themeColor}
                    bwColor={bwColor}
                    className="tagChips"
                    key={index}
                  >
                    <TagText>{tag}</TagText>
                  </TagChip>
                ))
              : null}
          </BRBBox>
        </BRBox>
      </BBox>
    </Box>
  );
};

export default Card;
