import React from "react";
import styled from "styled-components";

const Box = styled.div`
  background: ${(props) =>
    ` rgba(255,255,255,0) url(${props.montageimg}) no-repeat center center`};
  background-size: 100%;
  min-width: 100%;
  padding-top: 56.25%;
  margin-bottom: 30px;
  border: 1px solid;
  border-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255, 0.05)" : "rgba(0,0,0,0.1)"};
  /* box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.5); */
`;

const Montage = ({ main, bwColor }) => {
  return <Box montageimg={main} bwColor={bwColor}></Box>;
};

export default Montage;
