import React from "react";
import styled from "styled-components";

const BG = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: -1;
  position: fixed;
  background: ${(props) =>
    `rgba(0,0,0,1) url(${props.img}) no-repeat center center`};
  background-size: cover;
`;

const BackgroundImg = ({ bgImg }) => {
  return <BG img={bgImg}></BG>;
};

export default BackgroundImg;
