import { HomeHeader, HomeContents } from "./Home_Array";
import { AboutHeader, AboutContents } from "./About_Array";
import { JunwooleeHeader, JunwooleeContents } from "./Junwoolee_Array";
import { MojittoHeader, MojittoContents } from "./Mojitto_Array";
import { GandanmanHeader, GandanmanContents } from "./Gandanman_Array";
import { GuridangHeader, GuridangContents } from "./Guridang_Array";
import { LifelikesongHeader, LifelikesongContents } from "./Lifelikesong_Array";

const All_Array = {
  home: [HomeHeader, HomeContents],
  about: [AboutHeader, AboutContents],
  junwoolee: [JunwooleeHeader, JunwooleeContents],
  mojitto: [MojittoHeader, MojittoContents],
  gandanman: [GandanmanHeader, GandanmanContents],
  guridang: [GuridangHeader, GuridangContents],
  lifelikesong: [LifelikesongHeader, LifelikesongContents],
};

export default All_Array;
