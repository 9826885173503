import React from "react";
import styled from "styled-components";
import upIconB from "../Assets/Images/Icons/up_black.png";
import upIconW from "../Assets/Images/Icons/up_white.png";

const Box = styled.div`
  backdrop-filter: blur(6px);
  background-color: ${(props) => (props.cdColor ? props.cdColor : "none")};
  z-index: 10000;
  position: fixed;
  transition: 0.5s ease-in-out;
  right: 40px;
  bottom: ${(props) => (props.visible ? "50px" : "-32px;")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid;
  border-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255, 0.1)" : "rgba(0,0,0,0.1)"};
  &:hover {
    transition: 0.5s ease-in-out;
    border-color: ${(props) =>
      props.bwColor === "white" ? "rgba(255,255,255, 1)" : "rgba(0,0,0,1)"};
  }
  &:hover img {
    transition: 0.5s ease-in-out;
    opacity: 1;
  }
  @media screen and (max-width: 1350px) {
    right: 30px;
    bottom: ${(props) => (props.visible ? "30x" : "-32px;")};
  }
  @media screen and (max-width: 1000px) {
    right: 50px;
    bottom: ${(props) => (props.visible ? "40px" : "-32px;")};
  }
  @media screen and (max-width: 479px) {
    right: 30px;
    bottom: ${(props) => (props.visible ? "40px" : "-32px;")};
  }
`;

const Icon = styled.img`
  height: 16px;
  opacity: 0.3;
`;

const GoUp = ({ scrollHeight, bwColor = "black", cdColor }) => {
  return (
    <Box
      id="goUp"
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      visible={scrollHeight > 840 ? true : false}
      className="dD"
      cdColor={cdColor}
      bwColor={bwColor}
    >
      <Icon src={bwColor === "white" ? upIconW : upIconB}></Icon>
    </Box>
  );
};

export default GoUp;
