import React from "react";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(6px);
  background-color: ${(props) => (props.cdColor ? props.cdColor : "none")};
  height: auto;
  border: 1px solid;
  border-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255, 0.05)" : "rgba(0,0,0,0.1)"};
  margin-bottom: 20px;
  @media screen and (min-width: 1430px) {
    display: none;
  }
`;

const TBox = styled.div`
  display: flex;
  padding: 10px 20px;
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  background-color: ${(props) =>
    props.subColor ? props.subColor : "rgba(0,0,0,1)"};
  color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(255,255,255,1)"};
`;

const BBox = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
  padding: 20px 20px 0px;
`;

const ItemBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,0.5)" : "rgba(0,0,0,0.5)"};
  &:hover div {
    color: ${(props) =>
      props.bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"};
    font-weight: 400;
  }
`;

const ItemIcon = styled.div`
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-weight: 200;
`;

const ItemTitle = styled.div`
  font-weight: 200;
`;

const MBox = styled.div`
  backdrop-filter: blur(6px);
  background-color: ${(props) => (props.cdColor ? props.cdColor : "none")};
  max-width: 250px;
  position: fixed;
  border: 1px solid;
  color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"};
  border-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.1)"};
  display: flex;
  flex-direction: column;
  right: 40px;
  padding: 20px 20px 5px;
  @media screen and (max-width: 1430px) {
    display: none;
  }
`;

const MTBox = styled.div`
  font-family: "Outfit", sans-serif;
  margin-bottom: 15px;
`;

const MTBoder = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"};
  margin-bottom: 20px;
`;

const MontageIndex = ({
  contentsArray,
  subColor,
  cdColor,
  bwColor = "black",
  headerTitle,
}) => {
  return (
    <>
      <Box className="dD" bwColor={bwColor} cdColor={cdColor}>
        <TBox subColor={subColor}>Index</TBox>
        <BBox>
          <>
            {contentsArray.map((content, index) => (
              <AnchorLink
                offset="50"
                href={`#${headerTitle}card${index}`}
                key={index}
              >
                <ItemBox bwColor={bwColor}>
                  <ItemIcon>#{index + 1}.</ItemIcon>
                  <ItemTitle>{content.title}</ItemTitle>
                </ItemBox>
              </AnchorLink>
            ))}
          </>
        </BBox>
      </Box>
      <MBox bwColor={bwColor} cdColor={cdColor}>
        <MTBox>Index</MTBox>
        <MTBoder bwColor={bwColor}></MTBoder>
        {contentsArray.map((content, index) => (
          <AnchorLink
            offset="50"
            href={`#${headerTitle}card${index}`}
            key={index}
          >
            <ItemBox bwColor={bwColor}>
              <ItemIcon id={`${headerTitle}card${index}icon`}>
                #{index + 1}.
              </ItemIcon>
              <ItemTitle id={`${headerTitle}card${index}title`}>
                {content.title}
              </ItemTitle>
            </ItemBox>
          </AnchorLink>
        ))}
      </MBox>
    </>
  );
};

export default MontageIndex;
