import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

const Itag = styled.span`
  font-style: italic;
`;

const IT = ({ children }) => {
  return <Itag>{children}</Itag>;
};

const Atag = styled.a`
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    transition: 0.5s ease-in-out;
    font-weight: 400;
    color: ${(props) =>
      props.bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"};
  }
`;

const OL = ({ url, children, bwColor, target = "_blank" }) => {
  return (
    <Atag target={target} href={url} bwColor={bwColor}>
      {children}
    </Atag>
  );
};

const Btag = styled.span`
  font-weight: 400;
  /* margin-top: 10px;
  margin-bottom: 10px; */
`;

const BT = ({ children }) => {
  return <Btag>{children}</Btag>;
};

const Block = styled.div`
  display: flex;
  width: 100%;
  height: 10px;
`;

const BL = () => {
  return <Block></Block>;
};

const Minus = styled.div`
  font-size: 16px;
  color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.1)"};
  margin-top: 8px;
  margin-bottom: 14px;
`;

const HB = ({ bwColor }) => {
  return (
    <Minus className="dD" bwColor={bwColor}>
      -
    </Minus>
  );
};

const Picture = styled.div`
  /* margin-top: 15px;
  margin-bottom: 15px; */
  width: 100%;
  padding-top: ${(props) =>
    props.ratio ? (props.ratio === "16:9" ? "56.25%" : "100%") : "0px"};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${(props) =>
    `rgba(0,0,0,0) url(${props.img}) no-repeat center center`};
  background-size: cover;
`;

const PictureDesc = styled.div`
  position: absolute;
  display: ${(props) => (props.desc ? "flex" : "none")};
  background-color: ${(props) => props.themeColor};
  color: ${(props) => props.bwColor};
  font-size: 10px;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  font-weight: 500;
  opacity: 0.8;
`;

const PT = ({ ratio = "16:9", video, img, desc, bwColor, themeColor }) => {
  return (
    <Picture ratio={ratio} img={img}>
      {video ? (
        <ReactPlayer
          className="react-player"
          url={video}
          width="100%"
          height="100%"
        ></ReactPlayer>
      ) : (
        <PictureDesc desc={desc} bwColor={bwColor} themeColor={themeColor}>
          {desc}
        </PictureDesc>
      )}
    </Picture>
  );
};

const QABox = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

const QAMark = styled.div`
  background-color: ${(props) => props.subColor};
  color: ${(props) => props.bwColor};
  width: 30px;
  height: 30px;
  padding-bottom: 1px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  font-weight: 500;
  border-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.1)"};
`;

const QAContents = styled.div`
  width: calc(100% - 40px);
  margin-top: 2px;
`;

const QA = ({ question = true, children, bwColor, subColor }) => {
  return (
    <QABox>
      <QAMark bwColor={bwColor} subColor={subColor}>
        {question ? "Q" : "A"}
      </QAMark>
      <QAContents> {children}</QAContents>
    </QABox>
  );
};

const Border = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255, 0.05)" : "rgba(0,0,0,0.1)"};
  height: 1px;

  margin: ${(props) => (props.margin ? "20px 0px 20px" : "0px 0px 12px")};
`;

const BD = ({ bwColor, margin = false }) => {
  return <Border bwColor={bwColor} margin={margin}></Border>;
};

const Credit = styled.div`
  font-size: 12px;
  font-family: "Outfit", sans-serif;
  margin-bottom: ${(props) => (props.outro ? "10px" : "0px")};
  font-weight: 500;
  opacity: 0.5;
  :last-child {
    margin-bottom: 0px;
  }
`;

const CD = ({ bwColor, children, outro = false }) => {
  return (
    <Credit outro={outro} bwColor={bwColor}>
      {children}
    </Credit>
  );
};

const Quote = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  padding-left: 15px;
`;

const QuoteContents = styled.div`
  width: calc(100% - 15px);
  height: auto;
  position: relative;
  ::before {
    position: absolute;
    width: 1px;
    top: 0px;
    left: -15px;
    height: 100%;
    opacity: 0.3;
    background-color: ${(props) => props.bwColor};
    content: "";
  }
`;

const QT = ({ children, bwColor }) => {
  return (
    <Quote>
      <QuoteContents bwColor={bwColor}>{children}</QuoteContents>
    </Quote>
  );
};

export { IT, OL, BT, BL, HB, PT, QA, BD, CD, QT };
