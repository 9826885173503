import React from "react";
import styled from "styled-components";

const Box = styled.div`
  display: flex;
`;

const ChannelBox = styled.a`
  backdrop-filter: blur(6px);
  background-color: ${(props) => (props.cdColor ? props.cdColor : "none")};
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.1)"};
  margin-right: 15px;
  &:hover {
    transition: 0.5s ease-in-out;
    border-color: ${(props) =>
      props.bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"};
  }
  &:hover img {
    transition: 0.5s ease-in-out;
    opacity: 1;
  }
  :last-child {
    margin-right: 0px;
  }
`;

const ChannelIcon = styled.img`
  height: 14px;
  opacity: 0.3;
`;

const Channel = ({ channelArray, bwColor, cdColor }) => {
  return (
    <Box className="dD">
      {channelArray.map((item, index) => (
        <ChannelBox
          target="_blank"
          key={index}
          href={item.url}
          cdColor={cdColor}
          bwColor={bwColor}
        >
          <ChannelIcon src={item.platform}></ChannelIcon>
        </ChannelBox>
      ))}
    </Box>
  );
};

export default Channel;
