import instagram from "../Images/Icons/instagram.png";
import { JunwooleeHeader } from "./Junwoolee_Array";
import { MojittoHeader } from "./Mojitto_Array";
import { GandanmanHeader } from "./Gandanman_Array";
import { LifelikesongHeader } from "./Lifelikesong_Array";
import { GuridangHeader } from "./Guridang_Array";

const HomeHeader = {
  stage: "Home",
  title: "Montage.gif",
  desc: (
    <>
      전체는 부분의 합보다 크다.<p></p>몽타주를 그리는 매거진
    </>
  ),
  themeColor: "rgba(255,255,255,1)",
  channelArray: [
    { url: "https://www.instagram.com/montage.gif/", platform: instagram },
  ],
};

const HomeContents = () => [
  {
    img: GuridangHeader.mainStatic,
    gif: GuridangHeader.main,
    ratio: GuridangHeader.mainRatio,
    title: GuridangHeader.title,
    subTitle: GuridangHeader.desc,
    tags: GuridangHeader.tags,
    themeColor: GuridangHeader.themeColor,
    textColor: GuridangHeader.textColor,
    category: GuridangHeader.category,
  },
  {
    img: LifelikesongHeader.mainStatic,
    gif: LifelikesongHeader.main,
    ratio: LifelikesongHeader.mainRatio,
    title: LifelikesongHeader.title,
    subTitle: LifelikesongHeader.desc,
    tags: LifelikesongHeader.tags,
    themeColor: LifelikesongHeader.themeColor,
    textColor: LifelikesongHeader.textColor,
    category: LifelikesongHeader.category,
  },
  {
    img: GandanmanHeader.mainStatic,
    gif: GandanmanHeader.main,
    ratio: GandanmanHeader.mainRatio,
    title: GandanmanHeader.title,
    subTitle: GandanmanHeader.desc,
    tags: GandanmanHeader.tags,
    themeColor: GandanmanHeader.themeColor,
    textColor: GandanmanHeader.textColor,
    category: GandanmanHeader.category,
  },
  {
    img: MojittoHeader.mainStatic,
    gif: MojittoHeader.main,
    ratio: MojittoHeader.mainRatio,
    title: MojittoHeader.title,
    subTitle: MojittoHeader.desc,
    tags: MojittoHeader.tags,
    themeColor: MojittoHeader.themeColor,
    textColor: MojittoHeader.textColor,
    category: MojittoHeader.category,
  },
  {
    img: JunwooleeHeader.mainStatic,
    gif: JunwooleeHeader.main,
    ratio: JunwooleeHeader.mainRatio,
    title: JunwooleeHeader.title,
    subTitle: JunwooleeHeader.desc,
    tags: JunwooleeHeader.tags,
    themeColor: JunwooleeHeader.themeColor,
    textColor: JunwooleeHeader.textColor,
    category: JunwooleeHeader.category,
  },
];

export { HomeHeader, HomeContents };
