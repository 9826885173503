import instagram from "../Images/Icons/instagram_white.png";
import url from "../Images/Icons/url_white.png";
import main from "../Images/Contents/Mojitto/mojitto_main.gif";
import mainStatic from "../Images/Contents/Mojitto/mojitto_main.png";
import img1 from "../Images/Contents/Mojitto/mojitto_1.png";
import img2 from "../Images/Contents/Mojitto/mojitto_2.png";
import img3 from "../Images/Contents/Mojitto/mojitto_3.png";
import img4 from "../Images/Contents/Mojitto/mojitto_4.png";
import img5 from "../Images/Contents/Mojitto/mojitto_5.png";
import img6 from "../Images/Contents/Mojitto/mojitto_6.png";
import img7 from "../Images/Contents/Mojitto/mojitto_7.png";
import img9 from "../Images/Contents/Mojitto/mojitto_9.png";
import img10 from "../Images/Contents/Mojitto/mojitto_10.png";
import { BT, OL, BL } from "../../Components/Utility";

const MojittoHeader = {
  stage: "Detail",
  category: "Brand",
  tags: ["다이어리앱", "칵테일", "팀블렌더"],
  title: "Mojitto.gif",
  desc: (
    <>
      데일리 이모지 다이어리, 모지또<p></p>
      칵테일로 표현하는 오늘의 기분
    </>
  ),
  themeColor: "rgba(18,18,18,1)",
  bwColor: "white",
  channelArray: [
    { url: "https://www.instagram.com/mojito._.b/", platform: instagram },
    {
      url: "https://teamblender.github.io/mojito_policy_terms/#/",
      platform: url,
    },
  ],
  main: main,
  mainStatic: mainStatic,
  mainRatio: "16:9",
  end: "end",
  date: "2021년 12월 13일",
  credit: [{ role: "edit", name: "junwoo lee", url: "https://junwoolee.me/" }],
};

const MojittoContents = (bwColor) => [
  {
    img: img1,
    ratio: "1:1",
    title: "광저우에서 시작된 모지또",
    subTitle: "모지또 아이디어의 유래",
    desc: (
      <>
        모지또 아이디어는 중국 광저우의 한 비밀스러운 칵테일 바에서
        시작되었어요.<BL></BL>
        외지인이라면 찾아가기 힘든 허름한 아파트 속 칵테일 바. 예술 분야에서
        일하는 중국인 친구의 소개로 찾아가게 되었죠. 힙해보이는 바텐더 사장님이
        친절히 반겨주셨습니다. 그때는 손님이 우리뿐이라 프라이빗한 바에 온 것
        같은 느낌이 들었어요. 근데 칵테일 바엔 많이 안 가봐서 어떤 걸 주문할지
        고민하고 있었는데 그때 사장님이 한가지 제안을 주셨어요.
        <BL></BL>{" "}
        <BT>"오늘의 기분을 말해주면 그거에 맞춰서 칵테일을 타줄게."</BT>{" "}
        <BL></BL>
        사장님의 말을 듣고 저는 그날 느꼈던 기분을 서툰 중국어로 말씀드렸어요.
        제대로 알아들으셨을지는 모르지만 제가 말을 끝내자 바로 칵테일 제조에
        들어가셨어요. 그리고 얼마 안가 완성된 칵테일이 제 앞에 놓여졌는데 하루가
        그 칵테일 안에 담겨있다는 생각이 들더라구요. 칵테일을 마시며 친구와
        얘기를 하니 하루를 특별하게 마무리한다는 느낌이 들었어요. 광저우 여행의
        마지막 밤이었는데 여운이 참 많이 남겼습니다.<BL></BL>
        여행이 끝나고 한국에 돌아와서 사이드 프로젝트 팀을 만났는데 마침 어떤
        앱을 만들지 고민하던 시기였습니다. 어디 좋은 아이디어가 없을까 팀원
        모두가 고심했는데 문득 광저우에서의 특별한 기억이 떠올랐어요. 유레카!
        이걸 앱으로 만들어보면 어떨까? 생각이 들어 제안서를 써보았습니다.
        <BL></BL>
        제안서를 다 쓰고 팀원들에게 공유했는데 다행히도 다들 좋아해줬어요. 한번
        만들어보면 재밌겠다는 반응이었죠. 그리고 그렇게 함께 모지또를 만들기
        시작했습니다.
        <BL></BL>{" "}
        <BT>
          지금 생각해보면 참 운명적이에요. 막 아이디어를 논의할 시점에 광저우에
          다녀와 영감을 받았다는 게 참 신기하죠. 드라마틱하게 태어난 만큼
          앞으로도 그런 일이 많을 거라 기대하고 있어요.
        </BT>
      </>
    ),
  },
  {
    img: img2,
    ratio: "16:9",
    title: "우리는 팀 블렌더",
    subTitle: "하나로 섞인 우리",
    desc: (
      <>
        사이드 프로젝트 팀으로 만난 우리. 원래부터 서로 알던 사이도 있었지만
        그렇지 않은 팀원들도 있었죠. 사이드 프로젝트 특성상 금전적인 보상이
        주어지지 않기 때문에 팀의 결속력을 높이기 위해선 그 외적인 요소가
        필요하겠다라는 생각이 들었어요.
        <BL></BL>{" "}
        <BT>
          어떤 방법이 있을까 고민을 했는데 답은 생각보다 금방 나왔어요. 바로
          우리 팀 브랜딩하기.
        </BT>{" "}
        <BL></BL>
        개개인의 팀원을 하나로 묶어줄 수 있는 무형의 무언가가 있어야겠다고
        느꼈고 그러기 위해선 팀 브랜딩이 필요했어요. 그래서 우선 팀 이름부터
        지어보았죠. 건대입구의 엔제리너스에서 이름을 고민했하며 이런저런 얘기를
        하다가 블렌더 얘기가 나왔어요.
        <BL></BL>{" "}
        <BT>
          "블렌더가 여러가지 재료를 갈아 주스를 만드는 것처럼 우리도 그러면
          좋겠다. 각자의 개성, 아이디어를 멋진 결과물로 만드는 느낌이랄까?"
        </BT>{" "}
        <BL></BL>
        단순한 생각인데 그만큼 받아들이기도 쉬웠던 것 같아요. 그래서 팀원들 모두
        흔쾌히 그걸로 하자고 답을 해줬죠. 그렇게 팀 이름을 정한 뒤로 본격적으로
        어떻게 브랜딩을 할지 고민을 했어요.
        <BL></BL>{" "}
        <BT>
          크게 두 가지 방법이 있었는데 첫번째가 '커버' 그 다음이 '콘텐츠'였어요.
        </BT>{" "}
        <BL></BL>
        우선 커버는 말 그대로 포장지였어요. 우리 팀을 포장할 수 있는 걸
        만들자하여 팀 소개 웹사이트(
        <OL url={"https://teamblender.co/"} bwColor={bwColor}>
          teamblender.co
        </OL>
        )를 만들었죠. 실용적인 느낌은 없는 단순한 웹사이트였지만 선물의
        포장지처럼 내용물을 더 근사하게 보여줄 수 있을 것 같았어요. 실제로
        그렇게 봐주시는 분들도 계셨구요.
        <BL></BL>그 다음은 스텝은 우리의 콘텐츠, 즉 기록을 남기는 거였어요.
        우리가 모지또를 만들면서 경험하고 배운 것들을 최대한 글로 남겨놓고자
        했어요. 그때는 그냥 해보면 좋겠다는 생각이 들어서 했는데 요새 브랜딩
        책을 보니까 꾸준한 기록을 강조하더라구요. 어떻게 얻어걸린 느낌이지만
        기록을 남기며 얻은 게 참 많았어요. 팀 내부적으로도 더 많은 보람을 느낄
        수 있고 기록물(
        <OL url={"https://brunch.co.kr/@vicotorlee/126"} bwColor={bwColor}>
          모지또앱제작기
        </OL>
        )이 바이럴이 되면서 앱 홍보 효과도 발생했거든요.<BL></BL>
        이렇게 서툴지만 팀 결속을 위해 브랜딩을 진행하며 배운 점이 하나
        있는데요.
        <BL></BL>{" "}
        <BT>
          바로 브랜딩이라는 게 작고 사소한 행동 하나하나가 모여 이루어진다는 점.
          원래는 브랜딩이라는 게 엄청 거창한 일이라 생각되어 멀게 느껴졌는데
          그런 게 아니었어요. 어떤 사람의 습관이 그 사람을 말해준다는 얘기처럼,
          평소 하나하나 쌓아가는 게 중요한 거였죠.
        </BT>{" "}
        <BL></BL>
        그렇기에 누군가가 보지 않더라도 꾸준히 우리 팀의 기록을 쌓아갈
        예정이에요. 그만큼 우리 팀과 브랜딩이 단단해질 거라 생각해요. 지금까지
        그래왔던 것처럼요.
      </>
    ),
  },
  {
    img: img3,
    ratio: "16:9",
    title: "모지또, 세상에 나오다",
    subTitle: "모지또 iOS 버전 출시",
    desc: (
      <>
        2020년 6월 4일. 기나긴 개발 기간 끝에 모지또가 세상에 나왔어요. 초기
        개발 시엔 안드로이드 개발자가 없었기에 iOS 버전만 나왔죠.
        <BL></BL>
        출시 전날엔 정말 걱정이 많이 되었어요. 다들 많은 고생을 해서 만들었는데
        반응이 안 좋으면 어떡하지라는 생각 때문에 잠을 못 이룰 정도였죠. 처음엔
        신나서 팀원들에게 얘기한 아이디어였지만 자꾸만 보니 이게 좋은
        아이디어인지 아닌지 분간이 안되더라구요. 그래서 출시 직전엔 자신감이
        많이 떨어져있는 상황이었어요. 사이드 프로젝트라 다들 본업과 병행하다보니
        조금씩 지치는 게 눈에 보이기도 했구요.
        <BL></BL>
        대망의 출시날. 검토 승인이 나서 애플 앱스토어 올라갔을 때 우리는 가능한
        선에서 최대한 홍보를 했어요. 그래봤자 지인 홍보였지만 팀원들 모두가
        자신의 인맥을 총동원하니 이곳저곳에서 피드백이 들려왔어요. 정말 다행히도
        긍정적인 평이 많았는데 처음엔 주로 지인 평가다보니 크게 와닿지는
        않았어요. 보통 지인이 만든 앱에 험한 소리는 안하니까요.
        <BL></BL>
        근데 이게 어찌저찌 IT업계 일부에 바이럴이 되면서 아예 모르는 분들에게도
        평가를 들을 수 있었어요. 그리고 그때가 되어서야 조금 안심을 했던 것
        같아요. 평이 나름 괜찮았거든요. 아이디어는 물론 앱의 완성도 부분에서도
        좋은 평가를 받았어요. 그런 좋은 평가는 팀 단톡방에서 활발하게 공유가
        되었죠.
        <BL></BL>{" "}
        <BT>
          참 기분이 좋았어요. 단지 앱이 좋은 평가를 받아서라기보다는 팀원들이
          좋아하는 모습을 보며 많은 보람을 느꼈어요. 함께 산전수전을 겪으며
          전우애가 끈끈해졌거든요.
        </BT>{" "}
        <BL></BL>
        좋은 평가를 받으니 앱 출시 전 지쳤던 마음도 조금씩 회복되었어요. 그래서
        그 다음 스텝을 준비할 동력을 다시 얻을 수 있었던 것 같아요. 만약 앱 출시
        후 별 반응이 없었다면 거기서 끝났을 수도 있었을 거예요. 그런 상상하기도
        싫은 일이 벌어지지 않아서 참 다행이에요.
      </>
    ),
  },
  {
    img: img4,
    ratio: "16:9",
    title: "Featured 도장깨기",
    subTitle: "앱스토어 추천에 오르다",
    desc: (
      <>
        앱을 출시하고 좋은 평가를 받아서 굉장히 보람이 있었는데 좋은 소식이 하나
        더 있었어요. 앱스토어에서 연락이 온 거였죠. 앱스토어에 Featured를 할 수
        있도록 필요한 이미지 파일 등을 전해달라는 메일이었어요.
        <BL></BL>그 메일을 받고 정말 기대감에 부풀었던 것 같아요. 우리 앱은 어떤
        타이틀로 Featured 될까? 그때부터 매일매일 앱스토어에 들어가보며 우리가
        Featured 되었는지 확인을 했어요. 돌아보면 처음 메일을 받고 앱스토어에서
        확인하기까지 시간이 좀 걸렸던 것 같아요. 그렇게 매일 앱스토어를
        확인하면서 앱스토어 피드가 자정에 업데이트 된다는 걸 알게 되기도 했죠.
        <BL></BL>
        마침내 모지또가 앱스토어 피드에 Featured 된 것을 확인한 날엔 정말 제
        눈을 믿기가 어려웠어요. 무려 7월 최고의 앱으로 선정이 되었거든요. 카드
        하단에 '고르고 골랐어요'라는 말도 있어서 더욱 기분이 좋았어요. 캡처해서
        스토리에 올리고 난리도 아니었답니다.
        <BL></BL>
        그리고 그 뒤에 이어서 다른 타이틀로 Featured 된 적도 꽤 있었어요. 아마
        메이저한 Featured 타이틀은 대부분 다 받은 것 같은데 정말 신기해요.
        당시엔 앱스토어 에디터가 우리를 좋아하는 게 아닐까 착각하기도 했어요.
        <BL></BL>
        이렇게 Featured가 여러번 되니까 따로 홍보를 하지 않아도 사용자 수가
        늘었고 리뷰도 조금씩 더 달리기 시작했던 것 같아요. 그래서 저희 모두 다음
        업데이트를 더 열심히 준비하게 되었죠.
        <BL></BL>
        <BT>
          이때의 좋은 기억들이 지금도 저희의 마음속에 자리잡아 좋은 영향을
          끼치고 있다고 생각해요. 함께 이뤄낸 성과에 대한 좋은 기억이 우리
          모두를 끈끈하게 이어주고 있다는 느낌이 들어요.
        </BT>
      </>
    ),
  },
  {
    img: img5,
    ratio: "1:1",
    title: "다이어리 앱을 만든다는 것의 무게",
    subTitle: "작지만 결코 가볍지 않은",
    desc: (
      <>
        여러가지 앱 카테고리 중 다이어리를 선택한 건 가장 만만해보였기
        때문이었어요. 타겟층이 넓은 것에 비해 개발 난이도가 높지 않아 사이드
        프로젝트 아이디어로 잘 맞는다고 생각했죠. 그런데 사용자들의 리뷰를 보며
        다이어리 앱이 결코 만만한 서비스가 아니라는 걸 깨달았어요.
        <BL></BL>
        우선 사용자들은 하루하루의 일상을 다이어리 앱에 남기는데 그게 참 소중한
        기록이에요. 딱 그때만 남길 수 있는 기록이니까요. 다 지우고 다른 날 다시
        쓰라고 하면 이미 그때의 감성과는 달라서 똑같이 쓰는 건 불가능해요. 이런
        특성 때문에 사용자들에게 다이어리 기록이 더욱더 소중하게 느껴질 수밖에
        없는 것 같아요.
        <BL></BL>
        그런데 모지또는 여기서 한발 더 나아가는 부분이 있어요. 모지또가 갖는
        강점에서 비롯되는 부분인데요.
        <BL></BL>{" "}
        <BT>
          기존 다이어리 앱과 달리 모지또는 하루에 여러 감정을 기록할 수 있는데
          이 부분을 양극성 장애를 가지신 분들이 좋아해주셨어요. 다른 앱과 달리
          자신의 감정을 더 상세하게 남길 수 있는 게 치료에 도움이 된다고
          하시더라구요.
        </BT>{" "}
        <BL></BL>
        이런 리뷰를 보고나서 더욱더 큰 책임감을 갖게 되었어요. 그 자체만으로도
        소중한 기록인데 자신을 돌보는 일에도 쓰이는 거니까요. 지금은 사용자들의
        기록을 더 잘 관리하고, 앞으로도 그들이 기록을 더 잘 남길 수 있도록
        모지또를 개선해야겠다는 생각이 커요. 처음 모지또를 만들 때와는 완전
        달라진 거죠.
        <BL></BL>{" "}
        <BT>
          예상치 못한 무게감에 스트레스를 받을 때도 있지만 참 감사해요. 모지또를
          통해 이전에는 못보던 것들이 시야에 들어오게 되었거든요. 사람들이
          모지또를 통해 자신의 감정과 마음을 이해하고 더 나은 방향으로 나아갈 수
          있었으면 좋겠어요.
        </BT>
      </>
    ),
  },
  {
    img: img6,
    ratio: "16:9",
    title: "모든 것의 시작은 하나의 점",
    subTitle: "모지또 바이럴 되다",
    desc: (
      <>
        어느 날 모지또의 앱 랭킹이 갑자기 올라간 적이 있었어요. 신기해서 무슨
        일인지 찾아봤는데 정답은 네이트판이었죠. 한 게시물의 댓글에서 모지또가
        추천 되었더라구요. 힐링할 때 도움이 되는 앱으로 어떤 분이 모지또를
        언급해주셨어요. 감사하게도 해당 댓글엔 추천이 많이 달렸는데 그걸 보고
        많은 분들이 모지또를 다운받았나봐요.
        <BL></BL>
        댓글에 달린 대댓글도 확인해봤는데 마찬가지로 모지또에 대해 칭찬해주시는
        분들이 많았어요. 우리가 만든 게 어딘가에서 이렇게 칭찬을 받고 있다니
        감격스러웠죠. 부모의 마음이 이런 걸까라는 생각이 들었어요.
        <BL></BL>
        바이럴이 되고 정말 많은 사용자들이 모지또에 유입이 되었는데 하마터면
        서버가 터질 뻔했죠. 댓글 하나의 파급력이 이렇게 강할 줄 몰랐어요. 그리고
        이런 경험을 하면서 인터넷 세상에선 나비효과 현상이 더 잘 일어난다고
        느끼게 되었어요. 아주 작은 행위가 막대한 효과를 불러올 수 있는 세상.
        모지또에겐 그런 세상이 좋게 작용한 거였죠.
        <BL></BL>{" "}
        <BT>
          네이트판 바이럴 이후 사소한 행위들을 보는 관점이 바뀌었어요. 예전엔
          '그럴 거면 뭐하러 해'라는 식의 태도였다면 이제는 '뭐라도 해봐야지'라는
          식이에요. 지금 당장은 반응이 없더라도 언제 어떤 지점에서 바이럴이
          시작될지 모르는 일이니까요.
        </BT>{" "}
        <BL></BL>
        거슬러 올라가면 애초에 모지또를 출시했던 것도 그런 사소한 행위 중 하나라
        볼 수 있을 거예요. 앱 출시라는 첫번째 점을 찍지 않았다면 그 뒤로
        뻗어나간 지금의 가능성들을 보지 못했겠죠? 그러니 앞으로도 부지런히
        사소한 점들을 하나씩 찍어가려고 해요.
      </>
    ),
  },
  {
    img: img7,
    ratio: "16:9",
    title: "안드로이드 버전 출시와 겹경사",
    subTitle: "출시와 동시에 틱톡 바이럴",
    desc: (
      <>
        iOS 앱을 출시한 뒤 안드로이드 앱에 대한 요청이 참 많았는데 개발자 구하는
        게 쉽지 않았어요. 그런데 어느 날 모지또에 흥미를 갖고 개발해보고 싶다고
        먼저 말해준 친구가 있었어요. 실력뿐만 아니라 모지또에 대한 관심도 갖고
        있는 개발자라 너무 감사했죠. 그래서 바로 저희 팀에 합류하게 됐어요.
        <BL></BL>
        무척이나 빠른 개발 속도로 금새 모지또 안드로이드 버전을 개발하고 2020년
        12월에 배포했는데요. 이상한 게 배포를 하자마자 앱에 리뷰가 달리는
        거예요. 홍보도 제대로 하지 않았는데 어떻게 안드로이드 버전을 찾은 거지
        생각이 들었죠.
        <BL></BL>{" "}
        <BT>
          그래서 알아보니 안드로이드 버전이 플레이스토어에 올라갈 즈음 틱톡에서
          바이럴이 터진 거였더라구요. 어떤 인플루언서 분이 틱톡에 올린 모지또
          사용기가 바이럴의 시작점이었어요. 그 덕분에 모지또 안드로이드 앱은
          출시하자마자 급상승 랭킹에 들어가게 되었죠. 지금 생각해봐도 정말 말도
          안되는 우연의 일치에요.
        </BT>{" "}
        <BL></BL>
        안드로이드 버전에서 사용자를 만날 수 있는 것만으로도 큰 행복이었는데 또
        다른 경사가 찾아와서 우리 모두 엄청 기뻐했어요. 그때가 크리스마스
        시기였는데 뭔가 크리스마스 선물을 받은 거 같더라구요.
        <BL></BL>
        <BT>
          평소 틱톡을 안써서 생각도 안하고 있는 채널이었는데 아무래도 모지또를
          10대가 많이 써서 연결점이 있었던 거 같아요. 돌아볼수록 모지또를 통해
          새로운 세상을 알게 된 때가 참 많았네요. 이게 사이드 프로젝트의
          매력이겠죠?
        </BT>
      </>
    ),
  },
  {
    video: "https://youtu.be/ALgOhjLHyI4",
    title: "틱톡에서 온 선물",
    subTitle: "사용자가 모지또로 만든 틱톡 콘텐츠",
    desc: (
      <>
        틱톡에서 발생한 바이럴 중 특히 기억에 남는 게 하나 있어요. 단순 앱 후기
        콘텐츠가 아닌 모지또를 활용한 새로운 유형의 콘텐츠에서 바이럴이
        발생했죠.
        <BL></BL>{" "}
        <BT>
          스텔라장의 Colors라는 노래와 모지또를 합쳐서 만든 영상이었는데
          감동이었어요. 우리가 만들지도 않고, 만들 생각도 못했던 창의적인 영상을
          사용자가 만들어줬으니까요.
        </BT>{" "}
        <BL></BL>
        Colors라는 음악을 원래도 알고 있었지만 모지또와 이렇게 찰떡일지는
        몰랐어요. 그래서인지 그 영상을 보고 많은 분들이 저희 앱을 궁금해주셨고
        바이럴이 발생한 것 같았어요.
        <BL></BL>그 사용자분은 이후에도 모지또를 활용한 콘텐츠를 계속
        만들어주셨는데 주로 댓글을 통해 신청을 받고 칵테일을 만들어주는
        식이었어요. 근데 웃긴 게 그 영상들의 조회 수가 저희가 운영하는
        인스타그램 채널보다 많이 나왔어요. 어떻게 보면 그 사용자분이 저희
        서비스의 마케터가 되어준 셈이었죠.
        <BL></BL>
        예전에 인터넷에서 Z세대는 자신이 좋아하는 것들을 주변에 적극적으로
        공유한다는 글을 봤는데 그런 사례를 직접 경험하니 참 신기했어요. 감사한
        마음에 귀인분께 어떻게 사례를 할 수 있을까 고민했는데 연락할 방법이 따로
        없더라구요. 여러모로 아쉬웠어요.
        <BL></BL>{" "}
        <BT>
          이런 일을 겪고나니 정말 사용자 한분 한분이 참 소중하구나라는 생각이
          들어요. 이번 일처럼 어떤 사용자분을 통해 무슨 일이 일어날지
          모르니까요.
        </BT>
      </>
    ),
  },
  {
    img: img9,
    ratio: "16:9",
    title: "더할 나위 없는 2020년",
    subTitle: "올해 추천한 앱 20선 선정",
    desc: (
      <>
        사실 출시 후 겹경사가 너무 많아서 이보다 더 좋을 수 있을까 생각을
        했어요. 그런데 그럴 수 있더라구요. 2020년이 끝나갈 때 즈음 앱스토어에
        들어갔다가 깜짝 놀랐어요. 모지또가 2020년 올해의 앱으로 선정된 걸 보고
        믿을 수가 없었죠.
        <BL></BL>{" "}
        <BT>
          오늘의 앱, 이번 주 추천 앱 그리고 7월 최고의 앱. 마지막으로는 2020년
          올해의 앱까지. 앱스토어에서 받을 수 있는 주요 Featured는 다 받았다는
          게 너무 의미가 있었어요.
        </BT>{" "}
        <BL></BL>막 데뷔한 신인 배우가 연말 시상식에서 신인상을 받은 느낌.
        가슴이 웅장해진다는 표현이 딱 맞겠네요. 모지또에겐 정말 과분할 정도로
        최고의 한 해가 아니었을까 싶어요.
        <BL></BL>
        그래서 한 해를 돌아보는 마음으로 그동안 모지또에서 발생한 기록들을
        살펴봤는데요.
        <BL></BL>{" "}
        <BT>
          회원 45,000분이 찾아주셨고, 그 회원분들이 22만번의 쉐-킷을 해주셨어요.
          리뷰는 436분이 달아주셨구요.
        </BT>{" "}
        <BL></BL>
        우리의 앱에서 이런 의미있는 숫자들이 발생했다는 게 너무 뜻깊었어요.
        팀원들 모두 감동하고 내년엔 더 열심히 해보자라는 다짐도 하게 되었죠.
        정말 더할 나위 없는 한 해였어요.
      </>
    ),
  },
  {
    img: img10,
    ratio: "16:9",
    title: "모지또 해외 지점내기",
    subTitle: "해외 다이어리 앱 시장 도전",
    desc: (
      <>
        최고의 해를 마무리하고 맞이한 2021년. 기대가 굉장히 컸죠. 올해엔
        모지또가 얼마나 성장할지 궁금했어요. 그런데 결과적으로 기대가 컸던 만큼
        아쉬운 점도 많았어요.
        <BL></BL>{" "}
        <BT>
          왜냐면 2021년엔 작년에 기대받은 것만큼 폭발적으로 성장하지 못했거든요.
          모지또를 계속해서 사용해주시는 분들도 계셨지만 전체 사용자 수의 성장은
          정체되었어요. 사용자분들이 모지또를 꾸준히 쓸 수 있게 만드는 방법을
          여러모로 시도해봤지만 한계가 있더라구요. 그리고 그러던 중 저희도
          조금씩 지치기 시작했구요.
        </BT>{" "}
        <BL></BL>또 웃픈 포인트가 하나 있는데요. 작년에 온갖 Featured와 좋은
        평가를 받았던 터라 올해엔 그런 소식이 들려와도 조금 무감각해졌어요.
        전처럼 으쌰으쌰하게 되지는 않더라구요. 그래서 점점 앱 업데이트 주기가
        길어졌어요.
        <BL></BL>
        이대로 업데이트가 뜸해지다 결국 Fade-Out 되는 건가 생각이 들기도 했는데
        저희가 다 같이 얘기해보니 팀원 모두 그걸 원치는 않았어요. 대신 다시
        불타오르기 위해 새롭게 챌린지할 게 필요하다는 의견이 있었어요. 그래서 다
        함께 어떤 방향으로 나아가면 좋을지 의논을 했죠.
        <BL></BL>
        의논을 하며 중요한 포인트로 잡았던 건 금전적 보상이었어요. 앞서 말한
        것처럼 서비스에 대한 긍정적인 피드백만으로는 아쉬움을 느꼈기 때문이었죠.
        그래서 이번엔 돈을 벌 수 있는 방향으로 모지또를 만들어보자 얘기를 했고
        자연스레 시장 얘기도 나왔어요.
        <BL></BL>{" "}
        <BT>
          기존에 모지또는 한국에만 출시했었는데 그게 평소에도 참 아쉬웠거든요.
          특히 앱스토어 어드민에 들어갔을 때 보이는 세계 지도에서 대한민국만
          파랗게 칠해져 있는 걸 보면 더 그랬어요. 볼 때마다 세계 지도의 모든
          부분을 파랗게 칠하고 싶은 욕심이 생겼죠.
        </BT>{" "}
        <BL></BL>
        지금은 한국에만 출시해서 앱이 자급자족하고 팀원끼리 회식할 수 있을
        정도의 수익만 나는 상황인데 해외 버전을 내면 달라지지 않을까 생각이
        들었어요. 이 부분에 팀원들 모두 공감했고 해외 버전을 내는 방향으로
        의견을 모았죠.
        <BL></BL>{" "}
        <BT>
          또 거기서 멈추지 않고 우리는 한발 더 나아가기로 했어요. 기왕 해외 버전
          낼 거 우리가 모지또를 운영하면서 배운 점들을 토대로 모지또를 아예 다시
          만들어보기로 한 거였죠. 사용자들의 피드백을 반영하여 앱을 아예 새로운
          구조로 다시 짜게 되었어요. 기존 앱을 그대로 해외 버전으로 출시했다면
          상상도 못했을 일이었을 거예요.
        </BT>{" "}
        <BL></BL>
        시간과 노력이 참 많이 들어가는 일이지만 팀원들 모두 다시 배우는 마음으로
        새로 시작하고 있어요. 설렌다고 말해주는 팀원도 있었죠. 해외 버전은
        2022년 초에 출시하는 것을 목표로 하고 있는데 반응이 어떨지 참 궁금해요.
        특히 해외 시장을 공략하는 거라서 더욱더 그런 것 같아요.
        <BL></BL>
        해외 시장을 향해 찍은 마침표 하나가 어떤 무한한 가능성으로 이어질지
        기대가 되는 만큼, 열심히 준비해보려고 해요.
        <BL></BL>
        <BT>Mojitto. To be Continued!</BT>
      </>
    ),
  },
];

export { MojittoHeader, MojittoContents };
