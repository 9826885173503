import React from "react";
import ReactDOM from "react-dom";
import App from "./Components/App";
import ReactGA from "react-ga";

ReactGA.initialize("UA-219982079-2");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
