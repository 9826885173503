import React, { useEffect } from "react";
import styled from "styled-components";

const BBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
`;

const SBox = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1300px;
  height: 100%;
  position: relative;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
  }
`;

const Container = ({ children, bgColor = "white", path }) => {
  useEffect(() => {
    document.querySelector("html").style.backgroundColor = bgColor;
  }, [path]);
  return (
    <BBox className="hide-scrollbar">
      <SBox>{children}</SBox>
    </BBox>
  );
};

export default Container;
