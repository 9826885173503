import React from "react";
import styled from "styled-components";
import { OL, CD } from "./Utility";

const Box = styled.div`
  width: 100%;
  border: 1px solid;
  border-color: ${(props) =>
    props.bwColor === "white"
      ? "rgba(255, 255, 255, 0.05)"
      : "rgba(0, 0, 0, 0.1)"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
  color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(0, 0, 0, 1)"};
  backdrop-filter: blur(6px);
  background-color: ${(props) => (props.cdColor ? props.cdColor : "none")};
`;

const Title = styled.div`
  width: 100%;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  margin-bottom: 13px;
  opacity: 0.5;
`;

const Desc = styled.div`
  margin-bottom: 7px;
  width: 100%;
  font-size: 12px;
  opacity: 0.5;
  :last-child {
    margin-bottom: 0px;
  }
`;

const Border = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255, 0.05)" : "rgba(0,0,0,0.1)"};
  height: 1px;
  margin: 15px 0px 20px;
`;

const Outro = ({ headerArray }) => {
  const { end, date, bwColor, cdColor, credit } = headerArray;
  return (
    <Box end={end} className="dD" bwColor={bwColor} cdColor={cdColor}>
      <Title end={end}>{end === "true" ? "The End" : "To Be Continued"}</Title>
      <Desc end={end}>
        {end === "true" ? `${date}에 찍힌 마침표` : `${date}에 찍힌 쉼표`}
      </Desc>
      {end === "true" ? (
        <Desc end={end}>
          다른{" "}
          <OL url={"https://montagegif.com"} target={"_self"} bwColor={bwColor}>
            montage.gif
          </OL>
          도 구경해볼까요?
        </Desc>
      ) : (
        <Desc end={end}>
          업데이트 소식은{" "}
          <OL url={"https://www.instagram.com/montage.gif/"} bwColor={bwColor}>
            @montage.gif
          </OL>
          에서 만나보세요!
        </Desc>
      )}
      <Border bwColor={bwColor}></Border>
      {credit.map((content, index) => (
        <CD bwColor={bwColor} key={index} outro={true}>
          {content.role}ed by{" "}
          {content.url ? (
            <OL bwColor={bwColor} url={content.url}>
              {content.name}
            </OL>
          ) : (
            content.name
          )}
        </CD>
      ))}
    </Box>
  );
};

export default Outro;
