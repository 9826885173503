import React, { useEffect, useState } from "react";
import Container from "../Components/Container";
import Header from "../Components/Header";
import Contents from "../Components/Contents";
import Card from "../Components/Card";
import All_Array from "../Assets/Data/All_Array";
import { Helmet } from "react-helmet";
import ListHeader from "../Components/ListHeader";
import GoUp from "../Components/GoUp";
import ScrollBox from "../Components/ScrollBox";
import Outro from "../Components/Outro";
import MontageIndex from "../Components/MontageIndex";
import BackgroundImg from "../Components/BackgroundImg";

const View = ({ history }) => {
  const [scrollValue, setScrollValue] = useState({
    scrollHeight: 0,
    scrollRatio: 0,
  });
  const scrollFunc = () => {
    const scrollHeight =
      document.body.scrollTop || document.documentElement.scrollTop;
    const entireHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollRatio = (scrollHeight / entireHeight) * 100;
    setScrollValue({
      scrollHeight: scrollHeight,
      scrollRatio: scrollRatio,
    });
  };
  useEffect(() => {
    window.onscroll = () => {
      scrollFunc();
    };
  }, []);
  const pathOriginal = history.location.pathname;
  const pathEdited =
    pathOriginal.substr(1, pathOriginal.length).toLowerCase().length === 0
      ? "home"
      : pathOriginal.substr(1, pathOriginal.length).toLowerCase();
  const [headerArray, dataArray] = All_Array[pathEdited];
  const contentsArray = dataArray(
    headerArray.bwColor,
    headerArray.themeColor,
    headerArray.subColor
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathOriginal]);
  const goToDetail = (detail) => {
    const pureTitle = detail.substring(0, detail.length - 4).toLowerCase();
    history.push(`/${pureTitle}`);
  };
  return (
    <>
      {headerArray.bgImg ? (
        <BackgroundImg bgImg={headerArray.bgImg}></BackgroundImg>
      ) : null}
      <Container bgColor={headerArray.themeColor} path={pathOriginal}>
        <Helmet>
          <title>{headerArray.title}</title>
          <link
            id="favicon"
            rel="icon"
            type="image/png"
            href={`https://victorlee917.github.io/montage/${
              pathEdited === "home" || pathEdited === "about"
                ? "montage"
                : pathEdited
            }_favicon.png`}
          />
        </Helmet>
        <Header
          history={history}
          cdColor={headerArray.cdColor}
          bwColor={headerArray.bwColor}
          stage={headerArray.stage}
          title={headerArray.title}
          desc={headerArray.desc}
          channelArray={headerArray.channelArray}
          author={headerArray.stage === "Home" ? "junwoolee" : "Montage.gif"}
          authorUrl={
            headerArray.stage === "Home"
              ? "https://junwoolee.me"
              : "https://montagegif.com"
          }
        ></Header>
        <Contents
          stage={headerArray.stage}
          history={history}
          main={headerArray.main}
          end={headerArray.end}
          end={headerArray.date}
          bwColor={headerArray.bwColor}
        >
          <ListHeader
            bwColor={headerArray.bwColor}
            listTitle={
              headerArray.stage === "Home"
                ? "Montages"
                : headerArray.stage === "Detail"
                ? "About Montage"
                : "Steps"
            }
          ></ListHeader>
          {headerArray.stage === "Home" ||
          headerArray.stage === "About" ? null : (
            <MontageIndex
              subColor={headerArray.subColor}
              cdColor={headerArray.cdColor}
              bwColor={headerArray.bwColor}
              scrollHeight={scrollValue.scrollHeight}
              headerTitle={headerArray.title}
              contentsArray={contentsArray}
            ></MontageIndex>
          )}
          {contentsArray.map((data, index) => (
            <Card
              key={index}
              id={headerArray.title + "card" + index}
              stage={headerArray.stage}
              bwColor={headerArray.bwColor}
              cdColor={headerArray.cdColor}
              subColor={headerArray.subColor}
              themeColor={headerArray.themeColor}
              leftText={
                headerArray.stage === "Home"
                  ? `#${contentsArray.length - index}`
                  : `#${index + 1}`
              }
              title={data.title}
              subTitle={data.subTitle}
              desc={data.desc}
              img={data.img}
              gif={data.gif}
              video={data.video}
              ratio={data.ratio ? data.ratio : null}
              category={
                headerArray.stage === "Home"
                  ? data.category
                  : `${index + 1}/${contentsArray.length}`
              }
              tags={headerArray.stage === "Home" ? data.tags : null}
              goTo={headerArray.stage === "Home" ? goToDetail : null}
              path={pathOriginal}
            ></Card>
          ))}
          {headerArray.stage === "Home" ||
          headerArray.stage === "About" ? null : (
            <Outro headerArray={headerArray}></Outro>
          )}
        </Contents>
      </Container>
      <GoUp
        scrollHeight={scrollValue.scrollHeight}
        bwColor={headerArray.bwColor}
        cdColor={headerArray.cdColor}
      ></GoUp>
      <ScrollBox
        title={headerArray.title}
        pathname={pathOriginal.substr(1, pathOriginal.length)}
        scrollHeight={scrollValue.scrollHeight}
        scrollRatio={scrollValue.scrollRatio + "%"}
        stage={headerArray.stage}
        bwColor={headerArray.bwColor}
        themeColor={headerArray.themeColor}
      ></ScrollBox>
    </>
  );
};

export default View;
