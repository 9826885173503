import instagram from "../Images/Icons/instagram_white.png";
import url from "../Images/Icons/url_white.png";
import main from "../Images/Contents/Gandanman/gandanman_main.gif";
import mainStatic from "../Images/Contents/Gandanman/gandanman_main.png";
import img1 from "../Images/Contents/Gandanman/gandanman_1.png";
import img2 from "../Images/Contents/Gandanman/gandanman_2.png";
import img3 from "../Images/Contents/Gandanman/gandanman_3.png";
import img4 from "../Images/Contents/Gandanman/gandanman_4.png";
import img5 from "../Images/Contents/Gandanman/gandanman_5.png";
import img6 from "../Images/Contents/Gandanman/gandanman_6.png";
import { BL, BT, OL } from "../../Components/Utility";

const GandanmanHeader = {
  stage: "Detail",
  category: "People",
  tags: ["아티스트", "정신적자유", "선한영향력"],
  title: "Gandanman.gif",
  desc: (
    <>
      직관에 따라 주체적이고<p></p>자유롭게 삶을 유랑하는 여행자<p></p>
      간단남에게 영향을 준 6권의 책
    </>
  ),
  themeColor: "#064635",
  subColor: "#02392B",
  bwColor: "white",
  channelArray: [
    { url: "https://www.instagram.com/gandanmaan/", platform: instagram },
    {
      url: "https://litt.ly/gandannam",
      platform: url,
    },
  ],
  main: main,
  mainStatic: mainStatic,
  mainRatio: "16:9",
  end: "true",
  date: "2022년 2월 13일",
  credit: [
    { role: "edit", name: "junwoo lee", url: "https://junwoolee.me/" },
    {
      role: "design",
      name: "sowon lee",
      url: "https://www.instagram.com/wish._.lee/",
    },
  ],
};

const GandanmanContents = (bwColor, themeColor, subColor) => [
  {
    img: img1,
    title: "아티스트 웨이",
    subTitle: "예술가로 거듭나는 길",
    desc: (
      <>
        <OL
          url={"http://www.yes24.com/Product/Goods/6960553"}
          bwColor={bwColor}
        >
          아티스트 웨이, 줄리아 카메론 저
        </OL>
        <BL></BL>
        제게 가장 큰 영향을 준 단 한 권의 책을 꼽으라면 저는 고민하지 않고 이
        책을 꼽을 겁니다. 12주 간의 창조성 워크숍이라는 부제를 가진 아티스트
        웨이는 저뿐만 아니라 전세계의 수많은 상처받은 영혼을 치유하고 그들을
        앞으로 나아가게 해주었거든요.
        <BL></BL>
        상처라는 표현을 쓰니까 제가 무슨 사연 많은 사람이라고 생각할 수 있는데
        그렇지 않아요. 우리는 모두 내면에 상처받은 아이 한 명씩은 가지고
        있거든요. 다른 말로 표현하면 그것은 '결핍'이에요. 누구나 상처받은 기억,
        숨기고 싶은 치부, 나의 약한 부분들이 있잖아요? 그런 건 모두 과거의 어느
        시점에 심지어는 우리가 태어나기도 전 엄마 뱃속에 있을 때부터 무의식에
        억눌려 쌓여가기 시작한다고 해요.
        <BL></BL>{" "}
        <BT>
          아티스트 웨이는 '모닝 페이지'와 '아티스트 데이트'라는 단순한 2가지
          툴(Tool)을 통해서 그런 내면을 들여다보고 어루만지며 치유하는 12주 간의
          프로그램을 제시합니다.
        </BT>{" "}
        <BL></BL>
        혼자 해도 되고, 마음 맞는 사람들과 함께 해도 좋아요. 차근차근 과정을
        밟아가다 보면 나도 몰랐던 나를 발견하게 될 거예요. 가슴속 곪고 썩어
        문드러진 상처를 발견하게 되고 어린 시절 품었던 순수한 열망을 다시금
        마주하게 됩니다. 이를 통해 새로운 나로 다시 태어날 수 있어요.
        <BL></BL>{" "}
        <BT>
          무의식 깊은 곳까지 억눌려서 나의 가능성과 창조성이 발현되지 못하게
          가로막던 그 모든 것들로부터 해방되었을 때의 기분은 느껴본 사람만이 알
          거라 생각해요.
        </BT>{" "}
        <BL></BL>
        저의 경우 아티스트 웨이를 읽은 후 매일 아침 일어나서 의식의 흐름에 따라
        모닝 페이지를 쓰기 시작했어요. A4 용지 한장 반 정도의 분량을 쓰는데
        의식의 흐름을 받아적는 게 생각보다 쉽지 않아요. 처음엔 주로 의식의
        표면에 있는 생각들을 쓰게 되죠. 그런데 계속 쓰다보면 점차 무의식 중의
        생각들이 나와요. 내가 은연 중에 갖고 있던 생각들 그리고 욕망을 발견하게
        되고 그것들이 점차 짙어져요. 그리고 이런 과정을 통해 내가 어떤 사람인지
        무엇을 원하는지 분명해지죠.
        <BL></BL>{" "}
        <BT>
          지금은 모닝 페이지를 쓴 지가 3년이 되었는데 예전과 달라진 점이 많아요.
          특히 예전엔 심리적 장벽 때문에 새로운 시도를 두려워했는데 제 욕망이
          뚜렷해진 덕분인지 그런 게 해소되었어요. 지금은 크든 작든 해보고 싶은
          게 있다면 시도해보는 편이에요. 시작이 반이라는데 시작하는 힘이 생긴
          거죠. 그리고 이 덕분에 전보다 많은 발자취를 남기고 있어요. 한 예로
          예전에 배울 생각만 하던 피아노와 수영을 느닷없이 배우기 시작했는데
          아주 즐겁답니다.
        </BT>{" "}
        <BL></BL>
        이런 경험을 하고 나니 왜 책 이름이 아티스트 웨이인지도 깨닫게 되었어요.
        예술가를 위한 책이라 아티스트 웨이가 아니었죠. 자신의 표현하고자 하는
        욕구를 자연스럽게 드러내는, 창조 본능을 이끌어내주는 과정을 다룬
        책이기에 이름이 아티스트 웨이였던 거예요.
        <BL></BL>{" "}
        <BT>
          결국 아티스트 웨이는 자신을 예술가로 생각하지 않던 사람들의 잠재적인
          표현 능력을 끌어내 예술가가 되게끔 도와주는 책이에요. 그래서 잠재력이
          풍부한 사람이 읽을수록 더 좋은 결과를 낳을 수 있을 거라 생각해요.
        </BT>{" "}
        <BL></BL>
        인터뷰를 하다보니 아티스트 웨이가 역시 제 인생 책이라는 걸 다시금
        느껴요. 이 책이 더 많은 사람에게 도움을 줄 수 있다면 좋겠네요. 그래서 이
        세상에 더 많은 예술가가 생길 수 있길 바라요.
        <BL></BL>아 혹시나 모닝 페이지에 관심이 생기신 분들이 있을까 해서 제가
        쓴 걸 조금 공유해봐요!
        <BL></BL>
        <OL
          url={"https://blog.naver.com/gandanmaan/222624084830"}
          bwColor={bwColor}
        >
          간단남 모닝페이지 발췌문 보러가기
        </OL>
      </>
    ),
  },
  {
    img: img2,
    title: "리얼리티 트랜서핑",
    subTitle: "영혼을 성장시키는 일",
    desc: (
      <>
        <OL
          url={"http://www.yes24.com/Product/Goods/3268498"}
          bwColor={bwColor}
        >
          리얼리티 트랜서핑, 바딤 젤란드 저
        </OL>
        <BL></BL>내 마음이 곧 현실을 창조한다라는 말 들어보셨나요?
        <BL></BL>
        불교에서 말하는 일체유심조(一切唯心造)의 사상과도 일맥상통한 이 말은
        단순히 긍정적으로 살자는 식의 나이브한 소리를 하는 것이 아닙니다.
        그보다는 훨씬 더 깊고 근원적인 삶의 진리를 내포하고 있어요.<BL></BL>
        리얼리티 트랜서핑, 일명 트랜서핑 시리즈는 러시아의 양자 물리학자 출신인
        작가가 어느 날 꿈을 꾸다가 계시(?) 비스무리한 것을 받고 적어내려간 삶을
        살아가는 의식적인 태도에 대한 이야기입니다.
        <BL></BL>
        트랜서핑 시리즈에서 한결같이 삶이란 곧 '거울'과도 같다는 말을 해요.
        우리가 거울 앞에 서면 거울은 우리 자신을 비추고 우리는 거울 속에 비친
        우리의 '반영'을 보죠. 우리가 웃으면 거울 속 반영도 따라서 웃고 내가 화를
        내면 거울 속 반영도 똑같이 화를 냅니다. 거울 속에 비친 자신의 모습이
        평온하고 행복하게 보이려면 어떻게 해야할까요? 당연히 우리가 행복하고
        평온해지면 될 것입니다.
        <BL></BL>{" "}
        <BT>
          그러나 이것은 단편적인 비유이기 때문에 쉬운 문제이지 현실로 적용하면
          상당히 어려운 문제가 됩니다. 정작 현실 속의 우리는 거울 속의 반영을
          바꾸기 위해 반영의 실체인 우리 자신을 바꿔야한다는 걸 깨닫지 못합니다.
          오히려 그 반영을 바꾸기 위해서 아등바등 애를 쓸 뿐이죠.
        </BT>{" "}
        <BL></BL>
        예를 들어 '무능력은 나쁜 것이다'라는 관념을 가진 사람 A가 있다고
        생각해봐요. A는 무능력한 사람을 혐오하고 그와 똑같이 되지 않기 위해
        부단히 노력하죠. 또 자신보다 능력이 부족한 사람을 보며 위안을 느낍니다.
        근데 무능력이란 것은 상대적인 개념이기에 A는 위안과 만족을 위해 멈출
        수가 없어요. 계속해서 누군가에게 쫓기듯 살아야하는 거예요. 이런 굴레에서
        벗어나기 위해선 '무능력은 나쁜 것이다'라는 관념을 내려놓아야 하는데 그게
        쉬운 일은 아니죠. 거울 속에 반영된 세상과 자기 자신의 모습이 실체를
        뒤엎어버렸으니까요.
        <BL></BL>{" "}
        <BT>
          이런 문제를 해결하기 위해 트랜서핑 시리즈에선 반영이 아니라 반영의
          실체인 자신을 바꾸기 위해 꾸준히 노력해야 한다고 말하고 있어요. 자신
          안에 있던 부정적인 관념의 존재를 인지하고 그것을 내려놓거나 다른
          관념으로 대체하는 식으로요.
        </BT>{" "}
        <BL></BL>
        근데 사실 이렇게 주절주절 대고 있는 저 자신도 이러한 이치를 충분히
        깨우치지 못했을 만큼 저자가 말하는 내용은 상당히 방대합니다. 그래도
        트랜서핑 시리즈 덕분에 '영성'이라는 분야에 관심을 갖게 되었어요.
        <BL></BL>{" "}
        <BT>
          갑자기 영성이라는 말이 나와 놀라셨을 수도 있을 것 같아요. 근데 사실
          영성은 곧 '영혼을 성장시키는 일'을 말해서 결코 낯선 게 아니라
          생각해요. 하지만 우리는 보통 영성이라는 것을 비과학적인 것 그렇기에
          배척해야 하는 것으로 여기곤 하죠. 실제로 영성이라는 것이 초월적인 존재
          혹은 영적인 부분에 대해 고민하는 과정이긴 해요. 하지만 그렇다고 영성을
          배척해야 되는 걸까요?
        </BT>{" "}
        <BL></BL>
        트랜서핑 시리즈에선 이에 대해 아니라고 답해요. 또한 무조건적으로 영성을
        비과학적인 것으로 치부하는 태도가 오히려 왜곡된 과학관이라 말하고
        있어요. 생각해보면 과학이라는 것은 본디 미지의 영역을 호기심을 갖고
        연구해나가는 학문이니까요. 우리의 호기심을 제한하기 위한 수단이
        아니라요.
        <BL></BL>저 또한 아직 세상에 과학적으로 밝혀내지 못한 부분이 많은데 이미
        검증된 것으로만 세상을 보는 게 아닐까라는 생각이 들어요. 실제로
        양자역학이라는 개념이 나온 후 여러가지 철학적인 논의가 다시 활발하게
        이뤄지고 있잖아요. 물리학 질서에 큰 변동이 오며 기존에 불가능할 것으로
        보였던 가능성들도 대두되구요. 결국 과학이라는 것도 언제든 바뀔 수 있는
        것인데 그것을 절대적인 기준으로 삼아 자신을 속박할 이유가 있는지
        의문이에요.
        <BL></BL>{" "}
        <BT>
          그래서 다시 돌아와 얘기하자면 영성이라는 것은 모든 관념의 제한을
          거두고 이 세상과 그 안에 속한 스스로에 대해 무한한 가능성으로 돌아보는
          과정이라 생각해요. 이 세상은 어떤 질서로 구성되었는지, 자신의 존재
          의의와 정체성은 무엇인지 등의 고민을 자유로이 하며 영혼을 성장시키는
          과정인 거죠.
        </BT>{" "}
        <BL></BL>
        저는 이게 불교에서 말하는 부처가 된다는 '성불'이라는 개념과 이어지지
        않나 싶어요. 성불을 한 사람은 내적 수양을 통한 깨달음으로 앞서 말한 거울
        속에 반영된 모습에 휘둘리지 않는다고 하잖아요. 트랜서핑 시리즈를 읽고
        저는 그런 부분이 요즘 시대에 필요하다 생각하게 되었어요. 정신없이 바쁜
        일상 중에 점점 자기 자신을 돌아볼 여유가 없어지니까요.
        <BL></BL>운동을 꾸준히 해야 건강이 좋아지는 것처럼 영혼도 그렇기에 저도
        트랜서핑 시리즈를 읽은 후 꾸준히 관심을 갖고 스스로에 대해 알아가려
        노력하고 있어요. 시간을 내서 내적 수양 그러니까 명상을 하는데 모닝
        페이지도 그 중 하나라 할 수 있을 거예요. 글로 하는 명상이거든요. 글로써
        제가 지니고 있는 부정적인 관념들을 드러내고 털어내려고 해요. 또 그
        과정을 통해 거울 속 반영이 아닌 제 자신의 실체 대해 재정립도 하구요.
        <BL></BL>
        돌아보면 트랜서핑 시리즈를 읽기 전에는 영성이라는 것에 대해 잘 모르고
        부정적이었는데요. 책을 읽은 후론 제 편협한 사고의 지평이 넓어지고 영혼을
        성장시키는 일에 더 많은 관심을 갖게 되었어요. 또 그 덕분에 균형잡히고
        건강한 시각으로 세상을 바라보게 된 것 같구요. 제게 시작점이 되어준 책이
        다른 누군가에게도 도움이 되면 좋겠어요.
      </>
    ),
  },
  {
    img: img3,
    title: "삶으로 다시 떠오르기 외 1권",
    subTitle: "경제적 자유 이전의 정신적 자유",
    desc: (
      <>
        <OL
          url={"http://www.yes24.com/Product/Goods/9437871"}
          bwColor={bwColor}
        >
          삶으로 다시 떠오르기, 에크하르트 톨레 저
        </OL>
        <BL></BL>
        <OL
          url={"http://www.yes24.com/Product/Goods/15320271"}
          bwColor={bwColor}
        >
          상처 받지 않는 영혼, 마이클 싱어 저
        </OL>
        <BL></BL>요즘 경제적 자유가 화두죠. 하지만 저는 그보다 더 우선시 되어야
        할 것으로 '정신적 자유'를 말하고 싶어요. 정신적 자유란 바로 외부 자극에
        대한 무의식적인 반응을 하는 패턴에서 자유로워지는 것을 뜻해요. 경제적
        자유에 대해 얘기할 때 돈을 벌어들이는 파이프라인 그 자체보다 어떤
        상황에서도 돈을 벌 수 있는 능력을 갖추는 게 중요하다고 하죠. 정신의
        영역에서도 마찬가지입니다.
        <BL></BL>
        주변에서 좋은 일만 일어나고 불행한 일이 없을 때에만 평온한 것은 거짓
        평온이에요. 내가 어떠한 상황에 처하든 중심을 잡고 평정심을 유지할 수
        있는 능력, 마음의 무의식적인 반응에서 탈피하는 것이 정신적 자유예요.
        어쩌면 진정한 의미에서의 경제적 자유는 정신적 자유를 포함할지도
        모르겠네요. 돈에 대한 강박이나 집착을 내려놓는 것도 정신적 자유의
        일부이니까요.
        <BL></BL>{" "}
        <BT>
          삶으로 다시 떠오르기와 상처 받지 않은 영혼은 정신적 자유에 대한
          이야기를 다루고 있는 책이에요. 그리고 두 책에선 정신적 자유를 위해
          스스로를 자신의 가치관, 지위 등과 동일시해서는 안된다는 걸 강조하고
          있어요.
        </BT>{" "}
        <BL></BL>
        예를 들어 누군가가 나의 주장 혹은 가치관을 반박한다면 자신이 부정당한 것
        같아 기분이 상해요. 심할 때는 자존감이 없어져 우울감에 빠지기도 하죠.
        금전적 지위도 마찬가지예요. 돈을 얼마나 갖고 있느냐로 자신을 정의하고
        남들과 비교하며 스트레스를 받죠.
        <BL></BL>
        책에선 결국 상처를 받음에도 자신을 무엇인가와 끊임없이 동일시하는 건
        공허에 대한 두려움 때문이라고 해요. 아무것도 아닌 존재가 되는 것에 대한
        두려움에서 벗어나기 위해 그럴듯한 것에 자신을 동일시하게 되는 거죠.
        일종의 마약인 셈이에요. 지금 당장의 두려움과 외로움을 해소하기 위해
        복용하는 마약.
        <BL></BL>{" "}
        <BT>
          동일시라는 마약 없이도 마음의 평온을 지키기 위해선 어떻게 해야할까요?
          두 책에선 '나는 저것이다'가 아닌 '나는 있다'로 스스로를 정의하는 게
          필요하다고 말해요. 가치관, 지위, 재물 등 자신이 소유한 것과 스스로를
          분리하여 세상을 경험하는 태도가 있어야 정신적 자유로 나아갈 수 있다고
          보는 거죠.
        </BT>{" "}
        <BL></BL>
        물론 이것을 머리로 아는 것과 실천하는 것은 지극히 별개의 일이에요. 저의
        경우도 책을 몇번 읽는다고 곧바로 삶이 180도 달라지진 않더라구요. 하지만
        자신이 기존에 보던 방식과는 전혀 다른 방식으로 세상을 바라볼 수 있는
        프레임을 제공해준다고 생각해요. 전 여러 차례 필사를 했을 정도로 올바른
        마음을 유지하고 지향하는데 많은 도움을 받았거든요. 에크하르트 톨레와
        마이클 싱어의 책 모두 정신적 자유로 향하는 여정에 든든한 나침반이 되어줄
        것이라 생각합니다.
      </>
    ),
  },
  {
    img: img4,
    title: "탈출하라",
    subTitle: "새로운 삶으로 이어지는 새로운 선택지",
    desc: (
      <>
        <OL
          url={"http://www.yes24.com/Product/Goods/61939574"}
          bwColor={bwColor}
        >
          탈출하라, 로버트 링엄 저
        </OL>
        <BL></BL>
        스물일곱 살 때 다니던 첫 번째 회사를 1년 다니고 번아웃이 왔어요.
        <BL></BL>
        일주일에 6일을 일하면서 툭하면 야근을 했던 터라 몸과 마음이 많이 지친
        데다가 적은 인원임에도 임원진과의 소통이 정말 원활하지 않은 곳이었어요.
        남들이 들으면 고작 회사 한 번 다녀보고 성급한 판단 아니냐고 할 수
        있겠지만 저는 문득 이러한 형태의 삶 자체에 회의감을 느꼈어요. 그 시절 제
        눈길을 끌었던 제목의 책이 있었으니 바로 탈출하라입니다. 제목에서부터
        뭔가 제가 나아갈 방향의 실마리를 제공해줄 것만 같았어요.
        <BL></BL>
        책에선 우리를 구속하는 거대한 시스템에 대해 이야기합니다. 크게는 노동과
        소비라는 두 개의 톱니바퀴가 맞물려 돌아가는 시스템이 어떻게 우리를
        쳇바퀴 같은 삶에서 빠져나오지 못하게 만드는지에 대해 다양한 각도에서
        의견을 내놓고 있어요. 책을 읽고 있노라면 영화 매트릭스에서 기계들이 만든
        시스템, 매트릭스의 '자양분'으로 전락해버린 인간들의 모습이 떠올라요.
        <BL></BL>
        물론 그렇다고 이 책이 기존에 인간이 만든 시스템에 아예 등을 지고
        자연인처럼 살라는 말을 하는 건 아니에요. 시스템에 종속되지 않고 그걸
        활용하는 자세가 필요하다 말하는 거죠.<BL></BL>어떤 게 종속된 상태인지
        책에선 열심히 설명해주는데요. 뜨끔하게 되는 몇 가지 구절이 있어요.
        <BL></BL>{" "}
        <BT>
          "우리가 직장에 나가는 이유는 그 일이 즐거워서도 아니고 숭고해서도
          아니다. 다르게 사는 법을 모르기 때문이고, 그리고 무엇보다 먹고살기
          위해서다."
        </BT>{" "}
        <BL></BL>
        <BT>
          "현대인들은 목적지가 어디인지도 모르고 정신없이 일만 하기로 했다.
          그러면 무거운 질문과 마주할 일도 없고, 일을 게을리했다고 비난받을 일도
          없을 테니까. 지독한 일벌레로 살아야 힘들게 얻은 사회적 지위를 잃을
          일도 없을 테니까."
        </BT>{" "}
        <BL></BL>
        책을 읽으며 스스로를 돌아보게 됐어요. 나를 옭아매고 있는 것은 무엇일까,
        내가 외면해온 질문들은 무엇일까 하구요. 그리고 다른 선택지는 없는 걸까에
        대한 고민도 했어요. 다른 선택지는 곧 다른 삶의 가능성을 의미하는
        거니까요.
        <BL></BL>
        물론 그 이후로 저는 다시 두 번째 회사에 들어가게 되었지만 가슴 한편에는
        언제나 그 책이 제게 전했던 메시지가 남아있었어요. 결국 두 번째 회사도
        2년이 채 되지 못해서 그만두고서는 이제는 정말 시스템으로부터의 '탈출'을
        도모해야겠다고 마음먹게 되었답니다.
        <BL></BL>
        근데 사실은 아직 탈출에 성공하지는 못했어요. 그리고 생각보다 많이
        고달픕니다. 탈출을 마음먹고 행동에 옮기는 순간부터 고생길이 훤히
        열리거든요. 하지만 그 어느 때보다도 저는 지금의 제 삶을 사랑합니다. 우리
        모두는 결국 자신의 삶을 사랑하기 위해 태어난 존재들이잖아요?
        <BL></BL>{" "}
        <BT>
          우리 모두는 자기 자신을, 그리고 자신의 삶을 사랑하는 만큼만 자유로워질
          수 있습니다.
        </BT>
      </>
    ),
  },
  {
    img: img5,
    title: "두 번째 산",
    subTitle: "삶은 혼자가 아닌 함께의 이야기다",
    desc: (
      <>
        <OL
          url={"http://www.yes24.com/Product/Goods/91916588"}
          bwColor={bwColor}
        >
          두 번째 산, 데이비드 브룩스 저
        </OL>
        <BL></BL>
        이건 비교적 최근에 읽은 책이라 지금의 저를 만들었다기보다는 앞으로의
        저를 만들어갈 책인데요.
        <BL></BL>
        현대 사회의 개인주의는 과거 가부장적이고 집단주의적 성향이 강했던 시대에
        대한 반작용으로써 등장했죠. 그리고 현대의 능력주의와 결합하며 누구나
        무엇이든 가능하다는 자유도의 증가를 가져왔어요. 하지만 지금은 그 정도가
        너무 지나쳐서 여러 가지 문제점을 낳고 있어요.
        <BL></BL>
        우선 자유도가 늘어난 만큼 삶에서 나아가야 할 방향을 상실하기
        쉬워졌는데요. 이로 인해 경쟁에서 밀려난 낙오자들도 많아졌어요. 그런데
        초개인주의로 단절된 사회엔 그들을 보듬어줄 안전망이 제대로 갖춰져있지
        않죠.
        <BL></BL>
        또한 1인 가구의 증가로 그 누구도 뭐라고 하는 사람이 없어지면서 개인은
        쾌락을 조절할 능력을 잃어가며 우울증을 비롯한 각종 정신적 질환은 늘어만
        가고 있어요. 점차 기댈 사람이 없어져가는 상황에서 누군가에게 기대야하는
        상황은 늘어나는 거죠.
        <BL></BL>
        지금의 우리는 당장 내 옆에 누가 사는지도 알지 못해요. 알아도 관심도
        없구요. 노인 고독사뿐만 아니라 혼자 사는 젊은 남녀 역시 고독히 죽어가도
        한참이 지난 뒤에야 백골이 되어 발견되곤 합니다. 이런 추세라면 결국
        공동체라는 단어는 아마 역사 책에서나 볼 법한 단어가 될지도 모릅니다.
        누군가의 돈과 명예를 위해 타인의 희생이 당연해지는 세상이 올 수도 있지
        않을까요.
        <BL></BL>{" "}
        <BT>
          역사적으로 우리는 정반합의 과정을 거치며 발전해왔어요. 지금 이 혼돈의
          시기도 더 나은 모습으로 나아가기 위한 과도기에 해당하겠죠. 두 번째
          산은 그 과도기를 현명하게 극복하고 더 나은 세상을 위해서 가져야할
          마음가짐에 대해 이야기합니다.
        </BT>{" "}
        <BL></BL>
        개인주의도 아닌 그렇다고 다시 과거로 퇴보하여 집단주의로 되돌아가는 것도
        아닌 그 사이 어딘가에 존재하는 '관계주의'. 그 두 번째 산에 대해서 저자가
        잘 정리해둔 흐름을 따라가다 보면 가슴속에 그런 세상을 만드는 데 일조하고
        싶다는 생각이 들 겁니다. 저 또한 아직은 구체적인 방법을 생각한 건
        아니지만 앞으로 제가 가진 것을 나누며 공동체적인 가치를 실현해가고
        싶어졌거든요.
      </>
    ),
  },
  {
    img: img6,
    title: "만원 버스에서 내려 걷는 중입니다",
    subTitle: "조금은 다르게 살아가고 싶은 7년 차 커플의 이야기",
    desc: (
      <>
        <OL
          url={"https://gandanman.notion.site/76c556e3a2d04d36b73dc137e462b038"}
          bwColor={bwColor}
        >
          만원 버스에서 내려 걷는 중입니다, 김민경 | 송성호 저
        </OL>
        <BL></BL>
        태어나 처음으로 '출판'이라는 경험을 하게 해준 책이에요. 위에서 언급한
        책들이 이 책의 탄생에 지대한 공헌을 했다고 해도 틀린 말은 아닐 거예요.
        <BL></BL>{" "}
        <BT>
          만원 버스에서 내려 걷는 중입니다. 제목이 길어 정작 책을 쓴 당사자인
          저희도 종종 제목이 헷갈리는데요. 조금은 ‘다르게’ 살아가고 싶은 7년 차
          커플의 이야기를 담은 책이에요. 사회가 제시한 기준을 그대로 따르는 것이
          아니라 저희 두 사람이 옳다고 생각하는 기준에 맞춰 살면서 겪는 일들과
          그로 인한 생각들을 솔직하게 기록한 커플 에세이랍니다.
        </BT>{" "}
        <BL></BL>
        비록 대형 출판사와 계약해서 낸 책은 아니지만 사랑하는 애인과 함께 머리를
        싸매며 원고를 쓰고 퇴고를 거듭하여 한 권의 책으로 엮어낸 경험은 무척이나
        소중했으며 그 과정에서 많은 걸 배우기도 했어요.
        <BL></BL>{" "}
        <BT>
          서로 다른 두 사람이 만나 7년이라는 시간을 함께 해오면서 알게 모르게
          형성된 교집합. 그리고 그런 공통 분모를 제외한 각자만의 고유한 개성을
          한 권의 책에 담아낸다는 것은 생각보다 쉬운 일은 아니었어요. 서로 다른
          문체와 생각들을 조율해가며 하나의 결과물로 만들어내는 과정에서 우리는
          제법 많이 다투기도 했어요. 하지만 우리 둘의 합작품이 세상에 나온 순간
          그간의 고생은 아무래도 좋다는 생각뿐이더라구요.
        </BT>{" "}
        <BL></BL>
        자식이 어디 가서 칭찬을 듣고오면 행복해하고 어디 가서 기가 죽어오면
        덩달아 슬픈 것이 부모의 마음이라지요. 책 한 권을 진짜 자식에 감히 비할
        수는 없겠지만 그만큼 애틋하고 소중하며 각별한 의미를 지닌 존재의 탄생을
        '함께' 경험하고 지켜본 사람이 있다는 게 참 감사했어요.
        <BL></BL>
        책에서도 이야기했듯 저희는 세상이 우리에게 권하는 시나리오가 아니라
        우리가 직접 설계한 인생 시나리오대로 살고자 해요. 지금도 어설픈 발걸음을
        옮기느라 힘에 부치지만 그 어느 때보다도 자유롭고 행복합니다.
        <BL></BL>
        책을 쓰기 전 이런 고민을 한 적이 있어요.
        <BL></BL>'지금 적어 내려가는 나의 가치관에 대한 생각들이 5년 혹은 10년이
        지나도 변치 않을 만큼 굳건한가?’
        <BL></BL>‘내가 이런 이야기를 할 자격이 있는 건가?’
        <BL></BL>하지만 오히려 시간이 흐른 뒤에도 변하지 않고 고정되어 있다면
        그것이야말로 ‘죽은 상태’가 아닐까요? 세상 모든 현자들이 입을 모아 말하듯
        만물은 매 순간 변합니다. 우리 역시도 변화의 큰 물결을 빗겨나갈 재간은
        없답니다. 시간이 지나 달라진 자신의 모습이 성장으로 인한 것이었다면
        과거의 치기 어렸던 자신의 모습 덕에 지금의 자신이 있음을 알면 되는
        것이고, 슬프게도 제자리 걸음이거나 되려 퇴보한 것 같다면 과거의 기록에
        담겨있는 초심의 에너지를 원동력 삼아 다시 한걸음 앞으로 나아갈 용기를
        얻을 수도 있겠죠.
        <BL></BL>{" "}
        <BT>
          어느 쪽이든 자신이 옳다고 생각하는 것, 가치 있다고 믿는 것을 당당히
          드러내길 주저하지 않아야겠다는 결론을 내리게 되었어요. 그리고 마침내
          탈고를 하며 머릿속에만 존재하던 ‘영적인 가죽’을 손으로 만질 수 있는
          실체로 남겨 뿌듯했답니다. 탈고를 한 시점부터 이미 그 전과 달라지기
          시작했다고 해도 과언이 아니라고 생각해요.
        </BT>{" "}
        <BL></BL>
        밤을 지새워 고민하며 자신의 이야기를 용기있게 적어나간 아무개들의
        이야기를 읽고 들으며 여러모로 큰 위로와 힘을 받았는데요. 저희 커플의
        존재와 책 또한 누군가에겐 귀감이, 하다못해 작은 위안과 용기라도 줄 수
        있다면 그걸로 족합니다.
        <BL></BL>{" "}
        <BT>
          시간이 지나 저희의 기록을 다시 돌아봤을 때 미소를 지을 수 있도록 저희
          앞에 놓인 비포장도로를 열심히 걸어 나가야겠습니다. 만원 버스에서 내려
          자신만의 길을 걷기로 한 저희 두 사람의 행보를 종종 생각날 때마다
          찾아봐주시면 감사하겠습니다.
        </BT>
      </>
    ),
  },
];

export { GandanmanHeader, GandanmanContents };
