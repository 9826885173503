import instagram from "../Images/Icons/instagram.png";
import main from "../Images/Contents/Guridang/guridang_main.gif";
import mainStatic from "../Images/Contents/Guridang/guridang_main.png";
import img1 from "../Images/Contents/Guridang/guridang_1.png";
import img2 from "../Images/Contents/Guridang/guridang_2.png";
import img2a from "../Images/Contents/Guridang/guridang_2a.png";
import img3 from "../Images/Contents/Guridang/guridang_3.png";
import img3a from "../Images/Contents/Guridang/guridang_3a.png";
import img4 from "../Images/Contents/Guridang/guridang_4.png";
import img5 from "../Images/Contents/Guridang/guridang_5.png";
import img5a from "../Images/Contents/Guridang/guridang_5a.png";
import img5b from "../Images/Contents/Guridang/guridang_5b.png";
import img6 from "../Images/Contents/Guridang/guridang_6.png";
import img6a from "../Images/Contents/Guridang/guridang_6a.png";
import img6b from "../Images/Contents/Guridang/guridang_6b.png";
import img8 from "../Images/Contents/Guridang/guridang_8.png";
import img8a from "../Images/Contents/Guridang/guridang_8a.png";
import img8b from "../Images/Contents/Guridang/guridang_8b.png";
import img9 from "../Images/Contents/Guridang/guridang_9.png";
import img10 from "../Images/Contents/Guridang/guridang_10.png";
import img10a from "../Images/Contents/Guridang/guridang_10a.png";
import img10b from "../Images/Contents/Guridang/guridang_10b.png";
import img11 from "../Images/Contents/Guridang/guridang_11.png";
import img11a from "../Images/Contents/Guridang/guridang_11a.png";
import img12 from "../Images/Contents/Guridang/guridang_12.png";
import img12a from "../Images/Contents/Guridang/guridang_12a.png";
import img13 from "../Images/Contents/Guridang/guridang_13.png";
import img13a from "../Images/Contents/Guridang/guridang_13a.png";

import { BL, BT, OL, PT, QT } from "../../Components/Utility";

const GuridangHeader = {
  stage: "Detail",
  category: "People",
  tags: ["비밀기지", "유머탐험가"],
  title: "Guridang.gif",
  desc: (
    <>
      불쏘시개 같은 사람<p></p>다들 그에게 잠은 언제 자냐고 묻지만,<p></p>
      사실은 자연을 닮은 비밀기지에서<p></p>잘 숨어지내는 편이다.
    </>
  ),
  themeColor: "rgba(222,246,125,1.0)",
  bwColor: "black",
  channelArray: [
    { url: "https://www.instagram.com/guridang/", platform: instagram },
  ],
  main: main,
  mainStatic: mainStatic,
  mainRatio: "16:9",
  end: "true",
  date: "2022년 6월 11일",
  credit: [
    { role: "edit", name: "junwoo lee", url: "https://junwoolee.me/" },
    {
      role: "design",
      name: "sowon lee",
      url: "https://www.instagram.com/wish._.lee/",
    },
  ],
};

const GuridangContents = (bwColor, themeColor) => [
  {
    img: img1,
    title: "나만의 비밀기지 만들기",
    subTitle: "비밀기지에 들어가기 전에",
    desc: (
      <>
        <BT>인간은 누구나 자신만의 비밀기지를 필요로 한다.</BT>
        <BL></BL>
        <QT bwColor={bwColor}>
          비밀기지는 놀이터이자 도피처. 세상에는 어른이 되어서도 비밀기지를
          만드는 사람이 있다.<p></p>
          그들은 가슴 설레며 친구들과 함께 비밀스러운 일을 도모하고, 학교에서는
          가르쳐주지 않는 지식과 자연에서 배웠던 소중한 추억을 여전히 잊지 않은
          사람들이다.<p></p>
          중요한 것은 자신 내면의 충동에 솔직하고, 무엇이 좋고 나쁜지 스스로
          결정하는 것이며, 어린 시절 느꼈던 모험심과 도전을 잊지 않고 간직하는
          것이다.
          <BL></BL>
          <OL
            url={"http://www.yes24.com/Product/Goods/12011321"}
            bwColor={bwColor}
          >
            비밀기지 만들기, 오가타 다카히로
          </OL>
        </QT>
        <BL></BL>
        <BL></BL>
        <BT>저는 원래 서울의 부모님 집에서 살고 있었어요.</BT>
        <BL></BL>
        회사로 가는 교통편이 편리해서 경제적으로나 현실적으로나 나갈 이유가
        없었죠. 나만의 공간을 가지고 싶다는 생각은 오랫동안 해왔지만, 곰곰이
        생각해보니 저를 붙잡는 건 돈도 현실도 아닌 1년이나 2년 계약으로 한 곳에
        묶여있고 싶지 않았던 마음이더라고요. 저는 언제든 서울이나 한국을 떠날
        마음의 준비를 하며 살고 있달까요. 자유로운 영혼이라기보다는 구속되고
        싶지 않은 사람에 가까운 것 같아요. 근데 어느 날 친구가 세계 여행을
        떠나는데 그동안 집을 돌봐줄 사람을 구한다는 거예요.
        <BL></BL>
        <BT>그렇게 이직과 함께 이사를 했어요.</BT>
        <BL></BL>
        이직한 회사와도 가깝고 무엇보다 “몇 달만" 살아도 된다는 게 꽤나 마음에
        들었어요. 마치 독립 예행 연습처럼요. 늘 친구나 가족과 함께 살았지 온전히
        혼자는 처음이었는데요. 살아보니 알겠더라고요. 나에게 정말 중요한 것은
        무엇인지. 그리고 밖에서의 내 모습 말고, 혼자 있을 때 나는 나와 어떻게
        관계 맺는지. 돌아보니 네 달 간 가구도 여러 번 옮기고 매번 새롭게 꾸미고
        2주가 남았을 때도 새롭게 배치를 했어요. 고작 네 달 살 집을 열심히 가꾸는
        마음, 그건 파도에 휩쓸려갈 모래성일지라도 열심히 짓는 마음과 같지
        않을까요?
        <BL></BL>
        <BT>4개월이 지난 지금 이제 다시 그 집을 떠나요.</BT>
        <BL></BL>책 비밀기지 만들기를 읽으면서 내가 이미 비밀기지에 있다는 걸
        깨달았어요. 아직 어린이처럼 나만의 놀이터를 만들어나가는 여정. 그냥
        안식처 말고 놀이터이기도 하고 공연장이기도 한 나의 집. 집과 이별하며
        봄이 찾아왔고, 한 겨울밤의 꿈 같은 시절이 지나갔네요. 잠시 살러온 집에
        굳이 가져온 물건들을 바라보면서, 그 물건들로 나를 다시 정의할 수
        있더라구요.
        <BL></BL>
        <BT>저희 집 물건으로 돌아본 비밀기지 만드는 법을 소개할게요.</BT>
        <BL></BL>- main photo by{" "}
        <OL url={"https://www.instagram.com/seasunfilm/"} bwColor={bwColor}>
          @seasunfilm
        </OL>
      </>
    ),
  },
  {
    img: img2,
    ratio: "1:1",
    title: "초록 조개와 립살리스",
    subTitle: "빛이 내려앉은 내 초록 물건들",
    desc: (
      <>
        저는 아침 10시가 되면 재택으로 출근을 하는데요. 놀랍게도 딱 그 시간에 저
        초록 조개에 빛이 비춰요. 그럼 저는 창문을 열고, 반짝이는 조개와 식물을
        보면서 잠시 멍을 때려요. 그 아래 주렁주렁 매달린 식물은 립살리스에요.
        <BL></BL>
        제게 이 초록 물건이 더욱 의미있는 이유는, 큰 나무와 온갖 식물로 가득한
        이 집에서 유일하게 저의 식물이기 때문이에요. 언제든 이동해도 함께할 수
        있는 아이라는 점에서, 그리고 언제나 내가 숨고르고 햇살을 바라볼 여유를
        건네준다는 점에서, 그리고 내가 가장 좋아하는 초록색이라는 점에서...!
        완벽한 물건이죠.
        <BL></BL>
        <PT img={img2a} bwColor={bwColor} themeColor={themeColor}></PT>
      </>
    ),
  },
  {
    img: img3,
    title: "입 벌린 초록 그릇",
    subTitle: "가장 좋아하는 그릇 하나를 가진다는 것의 의미",
    desc: (
      <>
        손님이 오면 늘 가장 먼저 자랑하는 물건이에요. 내가 직접 만든 초록 그릇!
        <BL></BL>
        아무리 바빠도 음식을 그릇에 옮겨담는 1초의 여유라도 낼 수 있다면, 잠깐의
        일상이라도 나를 위한 시간을 지켜낼 수 있어요. 출근 전 허겁지겁 챙겨먹는
        아침은 물론 대충 시킨 배달 음식도, 열심히 차려낸 식사와 잠깐 먹는
        간식까지도, 모두 굳이 이 그릇에 옮겨담아서 먹어요.<BL></BL>만들 때
        처음에는 색깔 조합만 하다가, 아니 - 이렇게 만들면 내가 만들었는지
        디자이너가 만들었는지 알게 뭐야? 하는 마음으로 표정을 그려넣었어요. 그
        후로는 입이 그려진 부분에 계속 자그마한 음식들을 올려 먹게 되는데요.
        음식을 먹어갈수록 등장하는 표정이 정말 귀엽지 않나요?
        <BL></BL>- main photo by{" "}
        <OL url={"https://www.instagram.com/seasunfilm/"} bwColor={bwColor}>
          @seasunfilm
        </OL>
        <BL></BL>
        <PT img={img3a} bwColor={bwColor} themeColor={themeColor}></PT>
      </>
    ),
  },
  {
    img: img4,
    ratio: "1:1",
    title: "인센스 스틱",
    subTitle: "마음이 불안할 때는 창문 앞으로",
    desc: (
      <>
        저는 조금 답답하거나 불안할 때면, 인센스를 피워요.<BL></BL>가끔 너무
        집중하고 있을 때는 내가 숨을 쉬지 않고 있다는 걸 깨달았어요. 그런데 향을
        피우려면 창문을 열어야 하잖아요. 창문을 열려면 일어나야 하고. 라이터를
        키고 창문을 열죠. 그리고 큰 숨으로 바깥 공기를 들이쉬고 내뱉기. 그렇게
        나에게 잠깐의 휴식 시간을 주는 거예요.<BL></BL>제주도에서 요가를 시작한
        후로 인센스의 매력에 빠졌어요. 요가원에 가면 늘 향이 피워져 있었거든요.
        향은 참 신기하게 마치 음악처럼 나를 그 때의 마음가짐으로 데려다줘요.
        향을 피우면 집도 요가원이 돼요. 그렇게 언제든 요가원으로도, 제주도로도
        마음을 여행시킬 수 있어요. 오늘 하루의 숨이라도 잘 쉬어내며 살아가는
        사람이고 싶어요.<BL></BL>발리에서 날아온 우드 홀더는 생일 선물로 받은
        건데요. 목포에 살던 시절 놀러온 친구가 페트병으로 홀더를 만든 창의력에
        스스로 뿌듯해하던 저를 보고 오히려 마음 아프다며 선물해줬어요. 덕분에 제
        비밀기지에서 잘 ‘숨'어서 ‘숨’ 쉴 수 있게 되었네요.
        <BL></BL>- main photo by{" "}
        <OL url={"https://www.instagram.com/seasunfilm/"} bwColor={bwColor}>
          @seasunfilm
        </OL>
      </>
    ),
  },
  {
    img: img5,
    ratio: "1:1",
    title: "패브릭 포스터",
    subTitle: "숲으로 갈 수 없다면 집을 숲으로 만들자!",
    desc: (
      <>
        친구는 하와이에 갈 수 없다면 내가 있는 곳을 하와이로 만들라는 이야기를
        했어요.<BL></BL>그렇게 친구의 집은 이미 바다 사진과 바다 포스터, 서핑
        책과, 크나큰 보드, 바다 엽서로 온 벽이 가득했죠. 친구가 이 방을 남기고
        하와이를 누비는 와중에, 나는 하와이 같이 꾸며진 방에서 재택 근무를 하고
        있다는 사실이 종종 절망스럽다고 느껴지기도 했지만, 근사한 집에 사는
        기분은 나쁘지 않았어요.<BL></BL>그렇게 몇달이 지났어요. 분명 아름답지만
        왠지 어색했죠. 온전히 내 공간이 아닌 것 같은 느낌에서 벗어날 수
        없었어요. 잠시 살다가는 집이라 마음을 덜 써서 그런 것 같기도 해요.
        곰곰이 생각해보니 저는 바다보다는 숲에 가까운 사람인데 맞지 않는 옷을
        입은 것 같았어요. 도시에 사는 악어인거죠.<BL></BL>그런 쉬운 절망 대신
        어려운 희망을 택하자고, 이 집을 하와이 대신 한 여름의 알래스카로
        만들어보기로 했어요. 그렇게 팔려고 만들어두었던 패브릭 포스터 재고들을
        가져와 집을 채웠어요. 그렇게 바다를 닮은 집이 숲을 닮은 집으로 점점
        변해갔죠.
        <BL></BL>
        <BT>
          맞아 숲에서 살 수 없다면 방을 숲으로 만들어보는 거다. 그렇게 내 초록
          비밀기지 완성!
        </BT>
        <BL></BL>- main photo by{" "}
        <OL url={"https://www.instagram.com/seasunfilm/"} bwColor={bwColor}>
          @seasunfilm
        </OL>
        <BL></BL>
        <PT img={img5a} bwColor={bwColor} themeColor={themeColor}></PT>
        <BL></BL>
        <PT img={img5b} bwColor={bwColor} themeColor={themeColor}></PT>
      </>
    ),
  },
  {
    img: img6,
    title: "아이슬란드 폭포 일기장",
    subTitle: "하루에 모래알만한 세 줄이라도",
    desc: (
      <>
        10년 일기장.
        <BL></BL>매일을 10년 동안 한 페이지에 채우는 일기장이에요. 하루의 칸이
        아주 좁기 때문에 길어야 하루에 세 줄 정도 쓸 수가 있어요. 초록색을 사고
        싶었는데 초록색이 품절이었어요. 남색을 사서, 제가 직접 찍은 좋아하는
        아이슬란드 사진을 덧대 붙였더니 마음에 쏙 드는 공책이 되었어요. 이제
        1년을 거의 채워가는데, 과연 10년을 채울 수 있을까요?<BL></BL>작년에
        부모님께도 각각 이 10년 일기장을 선물했어요. 처음에는 매일 일상이 똑같은
        데 뭐 이런 숙제를 주냐고 투덜대셨는데, 지금은 저보다 더 성실히 꼬박꼬박
        채워가고 계세요. 부모님의 요즘이 궁금할 때면 식탁 위의 일기장을 훔쳐
        보곤 했어요. 출퇴근하는 무난한 날도, 골프가 잘 안 되어 속상한 날도,
        여행을 가서 기쁜 날도 다 거기 적혀있더라고요. 결국 선물을 가장해 내가
        직접할 수 없는 (하지 않고 있는) 부모님에 대한 기록을 스스로 하게 만드는
        장치를 마련한 것일지도 몰라요.<BL></BL>이 무거운 일기장은 유일하게 절대
        침대 위를 벗어나지 않는 물건이에요. 자기 직전에 보고, 침대 위에서 작은
        조명 하나를 켜고 써야 그 하루를 잘 마무리하는 기분이 들어요. 결국 이것도
        미래의 나를 위한 기록이 될까요?
        <BL></BL>
        <QT bwColor={bwColor}>
          도망치듯 시작된, 심지어 차로 10분이면 본가에 닿는 이곳에서, 나는 2주
          후면 떠날 이 집에서 오늘도 가구를 옮긴다. 이제 이 집에 살 날이 20일
          정도 밖에 남지 않았지만 그럼에도 나는 사람을 열심히 초대하고, 집을
          열심히 더 꾸미고, 집 구조도 바꿔보고, 이렇게 지금의 집을 기록해줄
          분들도 찾아다니며 열심히 남은 3월을 보내보려고 한다.
          <BL></BL>- 마지막 달 일기 中
        </QT>
        <BL></BL>- photo by{" "}
        <OL url={"https://www.instagram.com/seasunfilm/"} bwColor={bwColor}>
          @seasunfilm
        </OL>
        <BL></BL>
        <PT img={img6a} bwColor={bwColor} themeColor={themeColor}></PT>
        <BL></BL>
        <PT img={img6b} bwColor={bwColor} themeColor={themeColor}></PT>
      </>
    ),
  },
  {
    video: "https://youtu.be/HBXyM4XO7Bg",
    title: "비밀기지로 숨어든 후에",
    subTitle: "그 방을 열고 나올 사람은 오로지 나밖에 없네",
    desc: (
      <>
        <QT bwColor={bwColor}>
          In the woods, there is no wifi but you will find better connection.{" "}
        </QT>
        <BL></BL>
        <BT>단절과 연결의 양끝은 결국 맞닿아있어요.</BT>
        <BL></BL>
        비밀기지로 숨어들어가 나와의 시간을 지켜서 얻어낸 에너지만큼 더 많은
        사랑을 나눌 수 있었어요. 한 사람을 초대해도 온 마음을 다해 요리하고,
        그림을 그리고, 이야기했어요. 그렇게 모아낸 이야기를 세상에 꺼내어놓기도
        하고, 소중한 사람들을 더 많이 비밀기지로 초대하기 시작했죠.
        <BL></BL>
        <BT>
          결국 비밀기지 덕에 저는 숨기만 하지 않고 밖으로 더 잘 나올 수 있는
          사람이 되었어요.
        </BT>
        <BL></BL>
        결국 숨는 것도, 그 방문을 열고 밖으로 나가는 것도 나 자신뿐이더라구요.
        제발 제가 정말 좋아하는 뮤지션 윤숭의 음악 “방"을 들어주세요. 제가
        하려던 말은 모두 거기에 있답니다.
      </>
    ),
  },
  {
    img: img8,
    ratio: "1:1",
    title: "방구석 음악인의 기타",
    subTitle: "먼지가 쌓여도 케이스에 넣지는 않는다",
    desc: (
      <>
        <BT>“넌 정말 제일 하고 싶은 게 뭐냐?”</BT>
        <BL></BL>(웃음) 아시겠지만 저는 책도 쓰고 사진도 찍고 춤도 추고 미술도
        하고 많은 것을 하고 있어요. 그래서 넌 뭐가 되고 싶은 거냐는 질문을 종종
        받는데, 곰곰이 생각해보니 가장 잘하고 싶고 그래서 가장 두려운 것은 바로
        음악이었어요. 늘 숨어지내기 바빴죠.<BL></BL>잠시 살러온 집에 피아노를
        가져올 수는 없어서 기타를 가져왔어요. 케이스가 있는데도 굳이 넣어두지
        않는 이유는 볼 때마다 음악을 하라고 나 스스로를 다그치기 위함이에요.
        사실 제 가장 친한 친구인 것 같아요.<BL></BL>이 집에 오고서는 처음으로
        제가 만든 자작곡을 세상에 꺼내어놓는 경험을 했어요. 바로 자작곡 발송
        뉴스레터 ‘월간구리당'을 시작한 것인데요!!!!!! (셀프 박수!!!!)<BL></BL>한
        번 음악을 내보내려면, 대충 녹음하더라도 최소 30번은 take를 해야해요.
        그리고 이 30개도 넘는 녹음 파일 중에 한 번의 실수도 없는 음원은 없어요.
        <BL></BL>
        <PT img={img8b} bwColor={bwColor} themeColor={themeColor}></PT>{" "}
        <BL></BL>늘 무대 위 화려한 뮤지션을 보며 자괴감 느끼기 바빴는데요.
        그런데 그들이 방구석에서 연습하며 견뎠을 시간을 상상해본 적도 없으면서,
        그 수많은 밤을 지내본 적도 없으면서, 나도 저런 음악을 하고 싶은데 하고
        부러워했다는 게 우습더라고요.<BL></BL>그러니 완벽주의와 욕심을 내려놓고,
        그나마 차선과 최선의 것을 골라 세상에 내어놓았어요. 무언가 부족한 채로
        내어놓을 수 있는 마음가짐에도 연습이 필요하더라고요. 내 마음에 꼭 들지
        않아도 매듭짓고 더 나은 다음으로 나아가는 것이 방구석 음악인인 내가
        가져야할 태도다-! 하고 다짐하는 요즘이에요.<BL></BL>사람들은 결국 내
        실수보다 내 이야기와 목소리에 집중해요. 더 이상 방구석이 아닌 세상에서도
        이 기타로 노래할 수 있을 것 같은 예감이 들어요.<BL></BL>어떠세요? 제
        음악 한번 들어보실래요?<p></p>한 달에 한 번 제가 만든 음악을 보내드려요!
        <p></p>
        <OL
          url={"https://page.stibee.com/subscriptions/163704"}
          bwColor={bwColor}
        >
          월간구리당 신청하기!
        </OL>
        <BL></BL>- main photo by{" "}
        <OL url={"https://www.instagram.com/seasunfilm/"} bwColor={bwColor}>
          @seasunfilm
        </OL>
        <BL></BL>
        <PT img={img8a} bwColor={bwColor} themeColor={themeColor}></PT>
      </>
    ),
  },
  {
    img: img9,
    title: "노이즈 캔슬링 헤드폰",
    subTitle: "비밀기지 밖의 세상과 연결되는 법",
    desc: (
      <>
        중고로 구매한 아이맥에 문제가 있어서, 집에서 온라인 회의를 할 때도
        헤드폰을 써요. 사람들이 게이머 같다고 놀리고, 상사도 한 마디씩 했어요.
        그런데 저는 제 헤드폰이 정말 좋아요.<BL></BL>저는 음악에 진심인 것에
        비해, 장비에는 욕심이 없었어요. 친구들은 비행기 이어폰으로 음악을 듣는
        저를 보며 한심해했죠. 첫 회사에서 좋아하는 선배가 쓰고 다니길래 비싼
        건가보다 했는데, 좋아하는 미국 동료가 출장왔을 때 똑같은 것을 쓴 것을
        보고, 아 - 정말 사야겠다는 마음이 들었거든요.<BL></BL>그리고 귀를 다
        덮어서 오래 써도 아프지 않아요. 아무도 시키지 않은 온라인 행사에
        참여했다가 그래미 어워즈 디렉터와 1:1 이야기를 나누게 된 마법 같은 일도
        있었어요. 그 때도 저는 컴퓨터 앞에서, 이 헤드폰을 끼고 있었죠. 나를
        세상과 이곳저곳에서 차단시켜주면서도 또 소중한 사람들과 이어주기도
        한다는 생각이 들어요.<BL></BL>저는 이 투박한 헤드폰이 참 좋네요. 아니,
        그것이 내게 가져다 준 단결과 연결의 세상이 좋아요. 비행기를 타지 않아도
        온갖 이야기를 나눌 수 있는 세상이 슬프면서도 반가워요.
        <BL></BL>- main photo by{" "}
        <OL url={"https://www.instagram.com/seasunfilm/"} bwColor={bwColor}>
          @seasunfilm
        </OL>
      </>
    ),
  },
  {
    img: img10,
    ratio: "1:1",
    title: "캔버스 방명록",
    subTitle: "우리의 현재가 하나의 작품이 되는 것",
    desc: (
      <>
        친구들이 그리고 간 방명록.<BL></BL>너무 소중해요. 저는 늘 방명록에
        집착해요. 친구 집에 가면 없는 방명록도 만들어내죠. 전시장에서도 혼자
        방명록이 없는 만나씨를 위해 옆 문방구까지 뛰어가서 공책을 사고, 앞을
        특별하게 꾸며서 선물했어요. 방명록은 결국 그곳을 스쳐간 사람들의 흔적이
        남는 거라서, 내 얘기가 아니라서, 그런데 또 나에 대한 이야기라서 그래서
        좋아하는 것 같아요.<BL></BL>그런데 방명록에 내 흔적은 남지 않잖아요.
        그래서 새로운 프로그램을 기획하게 됐어요. 친구들은 무슨 일반인 집에
        프로그램까지 있냐면서 의아해했지만, 막상 하고나면 돈 받아야 하는 거
        아니냐고 정말 정말 정말 즐거워했어요.<BL></BL>프로그램은 바로 눈 감고 다
        같이 한 주제에 대해 그림을 그리는 건데요. 새로운 질문을 던지고, 주제를
        정하고, 각자 좋아하는 색깔을 고르고, 각자만의 음악을 고르고, 그 음악이
        끝날 때까지 눈을 감고 그림을 그려요. 다 그린 후에는 각자의 시선에서
        제목을 붙이는 실험을 하고 이야기를 나누죠. 이 과정에서 10년 지기 친구의
        몰랐던 이야기를 발견하기도 했어요. 너무 소중한 시간들이라 자세히
        말하지는 않을게요.<BL></BL>이 놀이로 친구들과 새롭게 연결될 수 있어 참
        좋았어요. 정말 이 그림들을 모아서 전시를 해볼까해요. 우스갯소리로
        친구들은 “아 또 구리당 전시에 이용당했네!”하고 말했어요. 근사한 전시가
        될 것 같아요.
        <BL></BL>- main photo by{" "}
        <OL url={"https://www.instagram.com/seasunfilm/"} bwColor={bwColor}>
          @seasunfilm
        </OL>
        <BL></BL>
        <PT img={img10a} bwColor={bwColor} themeColor={themeColor}></PT>
        <BL></BL>
        <PT img={img10b} bwColor={bwColor} themeColor={themeColor}></PT>
      </>
    ),
  },
  {
    img: img11,
    ratio: "1:1",
    title: "꽃다발",
    subTitle: "친구들은 떠나도 꽃은 남는다",
    desc: (
      <>
        <BT>원래는 꽃을 그렇게 좋아하지 않았어요.</BT>
        <BL></BL>한철 지나고 시들어버리는 것에 왜 그리들 의미를 부여하는 걸까요?
        그런데 몇 년 전부터 친구들 덕에 꽃시장에 가서 꽃을 직접 사는 것의 의미를
        깨달았어요.<BL></BL>군것질할 가격에 한 무더기 몸에 안고 다닐 꽃다발을 살
        수 있다는 게, 우리의 청춘만큼이나 한철인 꽃의 아름다움을 함께 누릴 수
        있다는 게, 무엇보다 아무것도 아무일도 아닌 평범한 날을 특별하게
        만들어준다는 게 참 좋았어요. 그 때부터 저는 별일없이 꽃을 선물하기
        시작했어요.<BL></BL>제 어린 시절 가장 친한 친구 중 한 명은
        플로리스트에요. 그 친구는 저의 비밀기지에 오기 전 날 “어떤 꽃
        좋아하냐"고 물었어요. 집들이 선물 대신 자기가 일하는 매장에서 직접
        고르고 골라 꽃다발을 만들어온 거예요.<BL></BL>“너가 특이한 꽃 좋다고
        해서. 근데 초록 꽃은 많이 없어. 풀이 꽃이잖아. 근데 이 꽃은 손에 붙잡고
        비비면 향이 나. 나도 일하다 스트레스 받으면 이 풀 비비러 가거든. 너도
        그래봐.”<BL></BL>친구는 떠났지만 그 대신 꽃이 남았어요. 빛이 내려앉는
        순간 늘 아름다움에 감탄하며 친구에게 연락했죠. 고맙다. 고맙다. 친구는
        오히려 준 지가 언젠데 아직도 좋아해주니 고맙다고 했어요. 화병도
        바꿔가면서 3주가 지나도록 바싹 말려가며 멱살 잡고 살려냈는데요. 이제 슬
        치울 때가 됐는데도 또 닦아서 말렸어요. 어쩌면 아름다움을 영원히 간직하기
        위해서가 아니라 친구의 마음을 간직하고 싶은 걸까요?<BL></BL>참 많은 꽃이
        4개월 동안 이 집을 스쳐갔네요. 비밀기지에 꽃을 들고 입장하는 제 친구들의
        마음 너무 예쁘지 않나요?
        <BL></BL>
        <PT img={img11a} bwColor={bwColor} themeColor={themeColor}></PT>
      </>
    ),
  },
  {
    img: img12,
    ratio: "1:1",
    title: "구리왕 요리당",
    subTitle: "맞은편에 앉아 밥을 지어먹고 싶어요",
    desc: (
      <>
        “이렇게까지 코스로 대접한다고?”<BL></BL>따듯한 밥 한 끼 지어 나누어 먹는
        것. 장을 보고 요리를 하고 설거지를 하는 수고로움의 과정을 거쳐야 하죠.
        그 정성은 정말 사랑이 아니면 할 수 없는 일이에요. 배달 음식이 주는
        편리함이 있지만, 나를 위해서는 배달 음식을 먹더라도, 소중한 사람들의
        방문은 나를 움직이게 하더라구요.<BL></BL>정말 고마운 사람이 있었어요.
        회사 선배였는데, 그 분은 모든 걸 가졌거든요? 그리고 나보다 많이 벌고,
        부자고, 부족한 것이 없고, 근데 내가 어설픈 선물을 고르자니 그냥 쓰레기가
        될 것만 같은 거예요. (불필요한 물건을 사고 예쁜 쓰레기를 만드는 것에
        민감)<BL></BL>옛날에는 정성스런 영상 편집을 한다거나 오랜 시간을 들여
        손편지를 쓰고는 했는데, 어떤 마음은 그저 건너편에 앉아 밥 먹고 눈빛과
        대화를 나누는 것만으로 충분히 전해진다는 생각이 들었어요. 그래서 밥을
        짓기로 했죠.<BL></BL>처음 먹어보는 채식 요리나, 그냥 나의 정성이 들어간
        요리. 어떤 영화에서는 정말 힘든 주인공에게, 별말없이 따뜻한 우동 한
        그릇을 내어주기도 하던데요. 그게 삶을 구원하기도 하더라고요.<BL></BL>
        제가 목포에 살았을 때 완전 채식을 하는 중이라 먹을 수 있는 식당이 많지
        않았어요. 그 때 채식 식당 친구가 종종 내어준 밥 한 끼에 왈칵 위로를
        받았는데, 저는 그 힘이 저를 목포에 4개월이나 붙잡아두었다고
        생각하거든요.<BL></BL>식구가 같이 밥을 나눠 먹은 사이라면, 잠깐
        방문했지만 우리는 여전히 식구죠. 그러니까 저는 식구가 되고 싶은 사람들만
        집에 초대하는 거예요.<BL></BL>
        <BT>"우리 집에 와. 밥 한 끼 차려줄게."</BT>
        <BL></BL>어느 순간부터 어떤 선물이나 어떤 위로보다, 열심히 차린 밥 한
        끼가 더 큰 마음을 전할 수 있다고 생각하게 되었어요. 냉장고의 빈 공간만큼
        마음은 너무나 풍요로워요.
        <BL></BL>
        <PT img={img12a} bwColor={bwColor} themeColor={themeColor}></PT>
      </>
    ),
  },
  {
    img: img13,
    title: "나의 비밀기지, 놀이터",
    subTitle: "집을 놀이터 삼아 나만의 비밀기지에서 모험하기",
    desc: (
      <>
        <BT>저는 이 집에서 다양한 모험을 했어요.</BT>
        <BL></BL>친구들과 방바닥에 누워서 각자 써온 대본을 돌아가며 읽고, 상사의
        퇴사를 축하하며 마지막 출근을 기념하고, 서로 크리스마스 선물을 나누기도
        하고, 제 신간을 홍보할 사진을 찍기도 했죠. 처음으로 혼자서 야근하며 술을
        마셔보기도 했고, 그렇게 침대 위에서 책 너머의 무수한 세상을 궁금해하다가
        잠들기도 했어요. 엄마가 울면서 방문한 날도 있어요. 그럴 때마다
        비밀기지를 얻기를 잘 했다는 생각이 들었죠. 누군가 도망칠 곳이 필요할 때
        나의 비밀기지를 떠올려줄 수 있다는 건 엄청난 특권이에요.<BL></BL>
        <BT>내가 만든 비밀기지는 내가 원하는 무엇이든 될 수 있어요.</BT>
        <BL></BL>요즘 코로나 시대에 집이 집 이상의 기능을 한다는 이야기를 많이
        하잖아요. 집이면서 오피스이기도 하고, 카페가 되기도 한다고. 그런데 이
        집은 저에게 놀이터에요. 놀다가 넘어지기도 하고, 기약없이 친구와 만나
        그네를 타기도 하죠. 좋아하는 친구들과 어린 시절로 돌아가 실로 가구를
        엮고 비밀기지를 만드는 실험을 했는데요. 그 이야기를 담아, 3월의
        월간구리당 곡을 완성했어요.
        <BL></BL>
        <BT>우리 비밀기지를 짓고 다 같이 놀아볼까요?</BT>
        <BL></BL>- main photo by{" "}
        <OL url={"https://www.instagram.com/seasunfilm/"} bwColor={bwColor}>
          @seasunfilm
        </OL>
        <BL></BL>
        <PT video={"https://www.youtube.com/watch?v=9NhUo3bmiJI"}></PT>
        <BL></BL>
        <PT
          img={img13a}
          bwColor={bwColor}
          themeColor={themeColor}
          ratio={"1:1"}
        ></PT>
      </>
    ),
  },
];

export { GuridangHeader, GuridangContents };
