import instagram from "../Images/Icons/instagram.png";
import url from "../Images/Icons/url.png";
import main from "../Images/Contents/Junwoolee/junwoolee_main.gif";
import mainStatic from "../Images/Contents/Junwoolee/junwoolee_main.png";
import img1 from "../Images/Contents/Junwoolee/junwoolee_1.png";
import img2 from "../Images/Contents/Junwoolee/junwoolee_2.png";
import img3 from "../Images/Contents/Junwoolee/junwoolee_3.png";
import img4 from "../Images/Contents/Junwoolee/junwoolee_4.png";
import img5 from "../Images/Contents/Junwoolee/junwoolee_5.png";
import img6 from "../Images/Contents/Junwoolee/junwoolee_6.png";
import img7 from "../Images/Contents/Junwoolee/junwoolee_7.png";
import img10 from "../Images/Contents/Junwoolee/junwoolee_10.png";
import { BT, OL, BL } from "../../Components/Utility";

const JunwooleeHeader = {
  stage: "Detail",
  category: "People",
  tags: ["기획자", "사이드프로젝트", "글쓰기"],
  title: "Junwoolee.gif",
  desc: <>사이드 프로젝트를 사랑하는 기획자</>,
  themeColor: "#FFE750",
  channelArray: [
    { url: "https://www.instagram.com/jwluoe/", platform: instagram },
    {
      url: "https://junwoolee.me",
      platform: url,
    },
  ],
  main: main,
  mainStatic: mainStatic,
  mainRatio: "16:9",
  end: "false",
  date: "2021년 12월 13일",
  credit: [
    { role: "edit", name: "junwoo lee", url: "https://junwoolee.me/" },
    {
      role: "design",
      name: "sowon lee",
      url: "https://www.instagram.com/wish._.lee/",
    },
  ],
};

const JunwooleeContents = (bwColor) => [
  {
    img: img1,
    ratio: "1:1",
    title: "창작의 시작",
    subTitle: "처음 쓴 소설, 처음 받은 상",
    desc: (
      <>
        만약 제 삶을 돌아본다면 가장 먼저 떠오르는 건 첫 소설과 관련된 기억일
        거예요. 방황하던 제게 방향을 잡아준 일이었으니까요.
        <BL></BL>첫 소설을 쓰기 전엔 방황을 많이 했어요. 입시에 실패한 후로
        좌절감에 빠져 살았거든요. 문돌이가 산업디자인이 하고 싶어서 재수까지
        했는데 잘 안됐죠. 그래서 어린 마음에 난 하고 싶은 건 평생 못하고 살
        운명이라고 생각했나봐요. 점수 맞춰 간 대학교 수업에 잘 적응하지 못했고
        과내 행사에도 거의 참여를 안했어요.
        <BL></BL>
        그렇게 무기력감에 찌들어 지내니 부모님이 어차피 아무것도 안할 거
        군대라도 다녀와라 하셨어요. 제가 생각해도 그게 나을 것 같아 매라도 빨리
        맞는 겸해서 입대 날짜를 받았죠. 근데 입대 날짜를 받으니 삶을 돌아보게
        되더라구요. 마치 시한부 인생처럼요.
        <BL></BL>
        삶을 돌아보며 나는 애초에 왜 산업디자인과에 가고 싶어했나 생각해보게
        됐어요. 아무래도 주요한 계기는 아이폰 발표였던 것 같아요. 고등학생 때
        아이폰 발표를 보며 나도 저런 걸 만들어보고 싶다 생각을 했었거든요.
        <BL></BL>{" "}
        <BT>
          근데 사실 생각해보면 저는 그전부터 뭔가를 만드는 걸 좋아했어요.
          이런저런 공상을 하고 노트에 끄적거리는 게 참 좋아했죠.
        </BT>{" "}
        <BL></BL>
        산업디자인과에 대한 관심이 갑자기 하늘에서 뚝 떨어진 게 아니었던 거예요.
        그래서 다시 내 근원으로 돌아가 뭐라도 끄적거려봐야겠다 싶어서 고민을
        했죠. 당시 제겐 글쓰기라는 능력밖에 없어서 그 안에서 고민을 했어요.
        <BL></BL>{" "}
        <BT>
          고민의 시간은 길지 않았고 전 금세 뭔가를 끄적거리고 있었어요. 공상을
          좋아하는 사람이라 그런지 자연스레 소설을 쓰고 있더라구요.
        </BT>{" "}
        <BL></BL>
        당시엔 나란 존재와 이 세계는 무엇인가라는 주제에 관심이 많아서 그런
        쪽으로 글을 쓰게 되었어요. 소설 제목은 [Creatures Creativity]였죠. 답이
        없는 영역이라 제 마음대로 쓸 수 있어서 설렜던 기억이 나요.
        <BL></BL>
        본격적으로 소설을 쓰기 시작한 후로 시간이 참 빨리 가서 어느덧 입대
        날짜가 가까워졌죠. 소설을 완성하지 못해 아쉬웠지만 그래도 시간을 알차게
        보내서 좋았어요. 제가 창작을 좋아한다는 걸 다시금 느끼게 된 기회였죠.
        <BL></BL>
        그렇게 입대를 했고 처음엔 완전히 새로운 환경에 적응하느라 정신이
        없었어요. 그런데 시간이 조금씩 흐르고 환경이 익숙해지니까 완성하지
        못하고 온 소설이 생각나더라구요. 그래서 일과가 끝나고 시간이 날 때
        짬짬이 다시 이어쓰게 됐어요. 오랜만에 다시 써도 재밌더라구요.
        <BL></BL>그 때 글을 쓰던 제 모습을 보고 선임과 동기들이 펜잡이라고
        놀렸던 게 기억나요. 그런데 말만 그렇게 하고 감사하게도 제가 쓴 글을
        돌아가면서 읽어줬죠. 어떤 친구는 피드백까지 줬어요. 가족이 아닌 다른
        누군가가 내 글을 읽어준다는 게 정말 특별한 경험이었죠.
        <BL></BL>
        근데 사람 욕심이 끝이 없다고 주위 사람들이 읽어주니 용기가 났고 소설
        공모전을 찾아보게 됐어요. 그러다 처음으로 열리는 전자출판대상을
        발견했는데 제가 쓴 글로도 지원이 되더라구요. 그래서 군대 사지방에서
        조심스레 지원해봤죠.
        <BL></BL>
        지원을 하니 로또를 산 거 마냥 가능성이 희박하더라도 기대하게 됐어요.
        혹시 내가 군대 안에 있어서 제때 연락을 못 받으면 어쩌지라는 걱정에
        틈틈이 사지방에 가서 메일 확인을 했던 기억도 나요.
        <BL></BL>{" "}
        <BT>
          그러던 어느 날 마침내 발표가 났는데 제 작품이 장려상에 이름을 올린
          거예요. 정말 황홀했어요. 그 때 무아지경이 되어 생활관을 뛰어다녔어요.
          지나가는 동기, 후임, 선임 모두 붙잡고 나 됐어라고 호들갑을 떨었죠.
          당시 군대 짬이 얼마 안되었다면 시끄럽다고 혼났을 거예요.
        </BT>{" "}
        <BL></BL>
        시상식에 참여하기 위해 휴가를 내고 일산 킨텍스에 갔어요. 실제로 가보니
        생각보다 규모가 큰 공모전이었더라구요. 생각해보면 대상 상금이 3000만원,
        장려상 상금이 200만원일 정도였으니까요.
        <BL></BL>
        시상식엔 어머니와 함께 갔는데 참 좋아하셨어요. 그 때 뭔가 딱 내 기분이
        어머니 기분과 같지 않을까 생각했어요. 어머니나 저나 자식이 어딘가에서
        인정을 받은 셈이니까요.
        <BL></BL>
        시상식이 시작되고 사회자분이 상을 받은 작품들은 모두 전자책으로 출판이
        될 거라 말씀해주셨어요. 상을 받은 것만으로도 특별한데 내 글이 출판(
        <OL
          bwColor={bwColor}
          url={
            "https://ridibooks.com/books/645000301?_s=instant&_q=creature&_rdt_sid=search-instant&_rdt_idx=0&_rdt_arg=creature"
          }
        >
          리디북스출판
        </OL>
        )까지 되다니 겹경사였죠. 당시엔 꿈속에서 살고 있다는 생각이 들 정도로
        행복했어요.
        <BL></BL>{" "}
        <BT>
          지금 생각해보면 운이 참 좋았던 것 같아요. 좌절하고 나서 처음 쓴 소설을
          통해 이렇게나 회복을 할 수 있었다는 게 감사해요. 첫 소설 덕분에 저는
          새출발을 할 수 있는 동력을 얻었어요. 입대까지 시한부 인생이라고
          생각하고 잡은 펜이 저를 다시 태어나게 해줄지 누가 알았을까요.
        </BT>
      </>
    ),
  },
  {
    img: img2,
    ratio: "1:1",
    title: "글로 쌓아올린 나",
    subTitle: "기록 그리고 또 기록",
    desc: (
      <>
        소설로 글쓰기의 재미를 알게 되면서 부쩍 글쓰는 빈도가 늘었어요. 일단
        기본적으로 군대에선 매일매일 일기를 썼고 책을 읽으면 독후감도 남겼어요.
        군대 안에선 컴퓨터 사용이 어려워 주로 종이에 필사를 했는데 그것도
        나름대로 감성적이고 좋았죠. 나의 소중한 기록이 남은 종이 한장 한장이 참
        소중하게 느껴져서 파일에다가 잘 보관해뒀어요. 그렇게 하나하나 모인
        파일들을 저번에 한번 심심해서 꺼내봤는데 쌓인 높이가 꽤 되더라구요. 이런
        기록들이 모여 지금의 내가 되었겠지라는 생각이 들어 뿌듯했어요.
        <BL></BL>{" "}
        <BT>
          그리고 그런 뿌듯한 마음이 그대로 동력이 되더라구요. 앞으로도 내가
          경험하고 배운 것들을 잘 남겨가야겠다는 마음이 커요. 그래서 지금도
          여러가지 시도를 하며 배우는 것들을 최대한 글로 남기려 노력하고 있죠.
        </BT>{" "}
        <BL></BL>
        지금은 주로 브런치(
        <OL url={"https://brunch.co.kr/@vicotorlee"} bwColor={bwColor}>
          링크
        </OL>
        )에 글을 많이 남기고 있는데 어느덧 시작한 지 5년이나 됐어요. 그래서 글도
        꽤 쌓였는데 돌아보면 제가 관심을 가져온 분야들을 알 수 있더라구요. 제가
        어떻게 성장해왔는지도 알 수 있고. 또 제가 쓴 글이 흐르고 흘러 누군가에게
        닿아 도움을 주기도 해요. 그럴 땐 참 많은 보람을 느껴요.
        <BL></BL>{" "}
        <BT>
          아마 죽을 때까지 계속 무언가를 기록하지 않을까 싶어요. 그러다보면
          언젠가 제가 죽더라도 저의 분신인 기록들이 제가 존재했었다는 걸 다른
          누군가에게 말해줄 수 있을 거라 생각해요.
        </BT>
      </>
    ),
  },
  {
    img: img3,
    ratio: "1:1",
    title: "노란 안경을 써요",
    subTitle: "나의 아이덴티티 아이템",
    desc: (
      <>
        어렸을 때부터 시력이 안 좋은 편이에요. 언제부터인가 눈이 급격하게 안
        좋아져 안경을 쓰기 시작했죠. 안경을 쓴 기간이 긴 만큼 안경에 관심이
        많았어요. 매일 착용하고 눈에 띄는 아이템이기도 해서 매번 신경써서
        구매했죠. 비교적 높은 가격을 줘서라두요. 좋은 일이 있을 때, 나에게
        선물을 주고 싶을 때에만 신중하게 골라서 하나씩 샀어요.
        <BL></BL>{" "}
        <BT>
          그래서 안경이 여러 개가 있는데 그 중에서도 아끼는 안경이 있어요. 바로
          백산안경점 제품인 노란 안경이에요. 저의 아이덴티티와도 같은
          아이템이죠.
        </BT>{" "}
        <BL></BL>
        처음엔 이걸 날 상징하는 아이템으로 만들어야겠다는 생각은 전혀 없었어요.
        사실 처음 봤을 땐 긴가민가 했어요. 아무래도 색이 평범하지는 않아서
        고민을 많이 했던 것 같아요. 당시엔 다들 검은색, 갈색 뿔테를 주로
        썼거든요. 그래도 패션을 잘 아는 친구의 적극 추천과 저의 팔랑귀로 사게
        됐죠.
        <BL></BL>{" "}
        <BT>
          비싼 가격을 주고 사서 긴가민가하든 말든 열심히 쓰고 다녔는데 신기한
          일이 있었어요. 새로운 곳에 갔을 때 사람들이 절 잘 기억하더라구요. 아
          그 노란 안경 끼신 분! 이러면서 절 기억해주시는 분들이 계셨어요. 또
          단순히 기억뿐만이 아니라 뭔가 개성이 있는 사람으로 여겨주시는 분들도
          있어서 좋았어요. 아무래도 노란 안경을 낀 사람이 흔치 않아서 그랬겠죠.
        </BT>{" "}
        <BL></BL>
        이런 경험을 하고 난 뒤에는 노란 안경을 내 아이덴티티처럼 써봐야겠다는
        생각이 들었어요. 그래서 지금은 저를 드러내야하는 중요한 자리에 갈 때는
        노란 안경을 픽하는 편이랍니다. 또 제 포트폴리오 사이트(
        <OL url={"https://junwoolee.me"} bwColor={bwColor}>
          링크
        </OL>
        )의 썸네일이 노란 안경이고 메인 컬러도 노란색이기도 해요.
        <BL></BL>
        스티브잡스의 뉴발란스, 존 레논의 안경과 같이 유명한 사람의 유명한
        아이템이 있는데 제 노란 안경도 그렇게 되길 바라고 있어요. 그러려면
        열심히 살아야겠죠?
      </>
    ),
  },
  {
    img: img4,
    ratio: "16:9",
    title: "내 인생에 중국 한 스푼",
    subTitle: "내가 알던 그 중국이 아니야",
    desc: (
      <>
        제 대학 전공은 중국어과였는데 처음엔 사실 좋아하지 않았어요. 오히려
        미워했죠. 원하던 산업디자인과에 가지 못하고 점수맞춰 간 과라 더
        그랬나봐요.
        <BL></BL>또 제가 대학 다닐 때는 수준별 수업이 잘 안되어서 초급반에
        원어민 수준의 애들이 들어왔어요. 난 0에서 시작하는데 쟤들은 이미 잘하니
        의욕과 애정이 생기질 않았죠.
        <BL></BL>
        그래도 어쨌든 중국어과라서 교환학생은 다녀와야 한다는 생각이 들었어요.
        전공이라는 게 끝까지 절 따라다니긴 할 테니까요. 그래서 울며 겨자먹기로
        중국 광저우행을 했어요. 가기 전 날엔 정말 군대갈 때만큼 떨렸던 것
        같아요. 집에서 한번도 나가서 산 적이 없는 제가 중국이라는 타지에서 한
        학기를 지내야 하는 게 쉽지 않아보였죠.
        <BL></BL>
        실제로 중국에 처음 갔을 땐 이런저런 고생을 많이 했어요. 타지에서
        이런저런 행정 처리를 하는 게 쉽지 않더라구요. 일단 말이 안 통하니까요.
        또 중국이 행정 처리가 빠르지도 않아 기다림의 연속이었어요.
        <BL></BL>{" "}
        <BT>
          그런데 일단 자리를 잡고 둘러보니까 새로운 것들이 보이더라구요. 가장 큰
          부분은 역동성이었어요. 후진적이라고 생각했던 중국이 실제로 가서 보니까
          여러 방면에서 엄청 빠르게 발전하고 있었어요.
        </BT>{" "}
        <BL></BL>
        일단 모바일 결제, 공유 자전거 등 실생활에서 경험하는 혁신들이 많았어요.
        또 중국인 친구들과도 얘기해봤는데 분위기가 우리나라 대학생이랑 조금 다른
        것 같더라구요. 당시 중국에서 창업 붐이 일어날 때라 더 그랬을 수도 있지만
        자기만의 사업을 하고 있는 친구들이 많았어요. 크건 작건 자기만의 사업을
        만들어나가는 모습이 멋져보였어요.
        <BL></BL>또 우연히 만나게 된 광저우 토박이 친구를 통해서도 중국의 새로운
        모습을 볼 수 있었어요. 그 친구는 예술쪽에서 일했는데 그래서인지 힙한
        곳을 많이 알았어요. 그런 곳에 저를 데려가서 예술쪽에서 일하는 친구들을
        소개해줬는데 그들을 보며 광저우와 홍콩 특유의 감성을 느낄 수 있었죠.
        <BL></BL>
        저는 중국이 통제 국가라 예술적 표현면에서 제약이 많을 거라 생각했는데
        아무래도 땅도 넓고 사람도 많다보니 인디쪽에선 여러 시도가 이루어지고
        있었어요.
        <BL></BL>{" "}
        <BT>
          중국에서 이런 경험을 하면서 더 열린 태도로 세상을 보게 됐어요. 중국이
          생각과 달리 좋았던 것처럼 다른 것들도 그렇지 않을까 생각해요. 그래서
          무엇이든 직접 맞닿아보고 결정해보려고 노력하는 중이에요. 각자의 모습이
          다른 만큼 닿았을 때 어떤 화학 작용이 일어날지는 모르는 거니까요.
        </BT>{" "}
        <BL></BL>
        이렇게 중국은 제게 여러모로 더 넓은 시야를 갖게 해준 곳이에요. 제 2의
        고향이라고 할 정도는 절대 아니지만 제 삶에서 빼놓을 수 없는 조미료
        정도는 되지 않을까 생각해요.
      </>
    ),
  },
  {
    img: img5,
    ratio: "16:9",
    title: "취미는 사이드 프로젝트",
    subTitle: "사이드가 메인이 되는 그날까지",
    desc: (
      <>
        대학교 수업으로는 무언가를 만들고 싶은 욕구가 충족되지 않았어요. 그래서
        계속해서 딴짓을 했죠. 글을 쓰는 것은 물론 다른 창작들에도 관심을
        가졌어요.
        <BL></BL>
        특히 앱 서비스에 눈이 많이 갔어요. 사람들이 매일 접하는 것이다보니
        자연스럽게 나도 재밌는 앱을 만들어보고 싶다 생각했던 것 같아요. 그렇게
        앱 서비스 기획에 발을 들였죠. 이런 게 혹은 저런 게 있으면 좋겠다 생각을
        하며 끄적거렸던 것 같아요.
        <BL></BL>
        그런데 글쓰기와는 다르게 앱 서비스는 혼자 만들기가 어렵더라구요. 그래서
        IT 창업 동아리(
        <OL url={"http://sopt.org/wp/"} bwColor={bwColor}>
          SOPT
        </OL>
        )에 들어가게 되었는데 디자이너, 개발자 등 다양한 분야의 친구들을 만날 수
        있었어요.
        <BL></BL>{" "}
        <BT>
          동아리에 들어가니까 열심히 사는 친구들이 참 많더라구요. 그리고 저처럼
          딴짓을 좋아하는 사람이 많았어요. 다들 새로운 것을 배우기 위해 혼자서
          혹은 모여서 프로젝트를 하더라구요. 그리고 거기서 사이드 프로젝트라는
          말을 처음 알게 됐어요. 딴짓의 조금 더 고급진 표현이었죠.
        </BT>{" "}
        <BL></BL>
        사이드 프로젝트를 하는 동아리 사람들을 보며 저도 자극을 많이 받았어요.
        또 딴짓을 나만 하는 게 아니었구나 하고 안심을 하게 되었어요. 그래서 그
        뒤로는 더욱더 적극적으로 사이드 프로젝트를 했던 것 같아요.
        <BL></BL>
        팀원을 구하는 게 쉽지 않아서 주로 혼자서 많이 했어요. 혼자서는 앱
        서비스를 다 만들긴 어려우니 UX/UI 기획까지만 진행했죠. 다 만들면 SNS
        같은 곳에 올려 주변에 피드백을 받기도 했어요. 그런데 그것만으로는 조금
        아쉽더라구요. 어쨌든 실제로 작동하는 앱은 아니었으니까요. 실제로 작동이
        되는 앱 서비스를 만들고 싶었어요. 그래서 코딩에 관심을 갖게 되었고
        서툴게나마 작은 것들을 만들어나가기 시작했어요. 대부분 사소해서 사람들이
        쓰지도 않을 것들이었지만 그래도 작동한다는 사실이 너무 재밌었어요.
        <BL></BL>
        <BT>
          그런데 이렇게 사이드 프로젝트를 하나씩 하고 돌아보니 쌓인 게 꽤
          많더라구요. 그리고 매번 한 것들을 주변에 공유하다보니 저에 대한 태그로
          #사이드프로젝트가 붙은 것 같기도 했어요.
        </BT>{" "}
        <BL></BL>
        알게 모르게 저에 대한 브랜딩이 된 게 아닐까 싶어 뿌듯하더라구요. 그리고
        사이드 프로젝트라는 키워드를 더 활용해보고 싶은 생각이 들어 고민을 하게
        됐죠. 그리고 결론을 내린 게 사이드 프로젝트 전도사가 되자!였어요. 내가
        해서 좋았으니까 다른 사람들에게도 권해보자라는 느낌으로요.
        <BL></BL>
        그래서 그 뒤로 제 브런치(
        <OL url={"https://brunch.co.kr/magazine/jihua"} bwColor={bwColor}>
          링크
        </OL>
        )에 사이드 프로젝트 관련된 글을 쓰기 시작했어요. 사이드 프로젝트가 왜
        좋은지, 어떤 식으로 하면 좋을지 초심자 입장에서 쓰려고 노력해봤죠.
        분량이 쌓이면 모아서 책을 만들어보고도 싶어요. 책까지 만들면 사이드
        프로젝트라는 주제로 강의를 하거나 커뮤니티를 만들 수 있지 않을까
        생각해요.
        <BL></BL>{" "}
        <BT>
          그렇게 해서 제 최종적인 꿈은 사이드 프로젝트가 메인이 되는 거예요.
          제가 좋아하는 일이 메인이 되는 거죠. 지금은 여러가지 현실적인 조건으로
          좋아하지 않음에도 해야하는 일들이 삶에서 더 많은 비중을 차지하지만.
          이렇게 주변을 하나씩 제가 좋아하는 것들로 채워가다보면 언젠간 달라질
          거라 믿어요. 그 날이 최대한 빨리 오길 바라며 프로젝트를 하나하나
          쌓아가고 있답니다.
        </BT>
      </>
    ),
  },
  {
    img: img6,
    ratio: "1:1",
    title: "첫 회사, 첫 사회 생활",
    subTitle: "IT 서비스 기획자로 입사하다",
    desc: (
      <>
        앱 서비스 기획하는 게 재미가 있어서 진로도 그쪽으로 생각하게 됐어요.
        전공은 이쪽과는 아무런 상관이 없지만 IT 서비스 기획자가 그렇게 전공을
        타는 직군은 또 아닌 것 같더라구요.
        <BL></BL>또 IT쪽이 좋았던 이유가 인적성이 없어서였어요. 일반 대기업에
        지원하면 거의 무조건 인적성을 봐야했는데 저는 그게 너무 싫었거든요. 물론
        나름의 연구를 했다고는 하지만 그냥 사람을 떨어트리기 위해 보는 시험
        같았어요.
        <BL></BL>
        근데 IT 회사에선 인적성을 안보는 대신 다른 걸 많이 봤죠. 경력이나 수상
        이력 같은 거요. 그래서 사실 많이 떨어졌어요. 당시 인턴 경력도, 그럴듯한
        수상 이력도 없어서 그랬나봐요. 인적성 보기도 싫어 그렇다고 스펙이 빠방한
        것도 아냐 이러다 취직을 할 수 있을까 싶었죠.
        <BL></BL>
        그러다 어느 날 지금 다니는 회사의 신입 공채 공고를 보게 됐어요. 신입
        공고라 그런지 지원 문서가 스펙보다는 과제 중심이더라구요. 그걸 보고
        여기는 한번 써봐도 좋겠다라는 생각이 들어 바로 지원해봤어요.
        <BL></BL>
        <BT>
          무스펙인 내겐 과제뿐이다 하고 열심히 과제를 풀었고 다행히도 서류가
          통과됐어요. 그렇게 면접을 거쳐 인턴을 하게 됐는데 정말 과제의
          연속이었죠. 힘들긴 했지만 제겐 나름 합리적인 채용 방식이라고
          느껴졌어요. 선배 기획자분들의 피드백을 들으며 성장할 수도 있었구요.
        </BT>{" "}
        <BL></BL>
        평소에 사이드 프로젝트를 꾸준히 했던 게 도움이 되었는지 인턴 과정을
        무사히 통과하여 결국 합격했어요. 그리고 이제 회사에 다닌 지 2년이
        넘었는데 여러모로 괜찮은 회사라는 생각이 들어요. 단순히 금전적인 보상을
        떠나 얻은 게 참 많았거든요.
        <BL></BL>{" "}
        <BT>
          특히 회사를 다니며 소중한 인연을 많이 만났어요. 치열한 경쟁을 뚫고
          들어온 뛰어난 동기들 그리고 선배들을 통해 많이 배울 수 있었죠. 또
          마음이 맞는 친구와 함께 재밌는 걸 만들어보기도 했구요. 예를 들어
          한번은 회사 동기인 디자이너가 책 표지 디자인을 해준 일이 있어요.
          덕분에 정말 멋진 표지 디자인이 나왔죠. 근데 그 때 문득 내가 이 회사에
          다니지 않았다면 이런 친구를 만날 수 있었을까 생각이 들었어요. 그 뒤로
          좋은 사람과 기회를 비교적 쉽게 만날 수 있다는 게 이 회사의 큰 장점이라
          생각하게 됐죠.
        </BT>{" "}
        <BL></BL>
        근데 이런 부분에서 발생하는 단점도 하나 있어요. 뛰어난 사람들로 구성된
        환경에 있다보니 가끔은 내 자신이 작게 느껴질 때도 있어요. 특히 처음에
        부서에 들어가면 더 그래요. 내가 선배들만큼의 수준에 도달할 수 있을까
        걱정을 하게 되죠.
        <BL></BL>{" "}
        <BT>
          다행히도 저는 이런 무력감을 보듬어줄 동기가 많았어요. 이런 환경에
          있어서 그렇지 우리는 충분히 멋진 사람이라 말해줘서 힐링이 많이 됐죠.
          근데 단순히 위로의 말이 아니라 실제로도 그런 것 같아요. 지금은 어떤
          테두리에 갇혀 자기 자신을 과소평가 할 필요는 없다 생각해요.
        </BT>{" "}
        <BL></BL>
        겨우 2년 정도의 시간을 보내고 평가하는 게 이를 수도 있지만 그래도 첫
        직장으로 좋은 곳이에요. 앞에서 말한 장점도 있고 다른 곳에서 오신
        경력자분들 얘기를 들으면 아직까지 꼰대 같은 회사도 참 많더라구요. 적어도
        여긴 그런 게 없으니 괜찮아요.
        <BL></BL>
        앞으로 이곳에서 얼마나 더 시간을 보낼지는 모르지만 그래도 몸을 담고 있을
        때 최대한 흡수를 많이 해볼 생각이에요. 큰 회사이기도 해서 이곳에서만
        경험해볼 수 있는 게 분명 있거든요. 일단 지금은 코찔찔이 기획자티를 벗는
        게 제 1의 목표입니다.
      </>
    ),
  },
  {
    img: img7,
    ratio: "16:9",
    title: "나의 희망, 모지또",
    subTitle: "데일리 이모지 다이어리, 모지또 출시",
    desc: (
      <>
        회사에 들어온 직후로는 사이드 프로젝트가 조금 뜸해졌어요. 새로운 환경에
        적응해야 된다는 생각에 일을 벌리는 것을 참았죠. 그런데 그것도 오래 가지
        않았어요.
        <BL></BL>
        제가 좋아하는 기획 일을 할 수 있는 회사에 들어오긴 했지만 사이드
        프로젝트에서 얻는 보람과 재미를 느끼긴 어려웠어요. 그것도 그럴 게 회사엔
        회사 나름대로의 질서가 있어서 사이드 프로젝트만큼 제가 주도적으로 할 수
        없었거든요.
        <BL></BL>
        물론 그걸 나쁘다고 할 수는 없을 거예요. 그런 질서가 있기에 수많은
        사용자들에게 안정적인 서비스를 제공할 수 있는 거니까요. 그런 질서에서도
        분명히 배울 점이 있기도 하구요.
        <BL></BL>{" "}
        <BT>
          그래서 바쁘겠지만 회사 일과 사이드 프로젝트를 병행하며 두 마리 토끼를
          다 잡아보자 생각하게 됐어요. 회사에선 신입 기획자로서 배우고 사이드
          프로젝트에선 주도적인 역할을 하는 식으로요.
        </BT>{" "}
        <BL></BL>
        누군가는 그렇게까지 해야 되냐고 물을 수도 있겠지만 저는 오히려 그렇게
        해야 에너지를 얻는 것 같아요. 누군가 테니스, 골프를 치며 통해 에너지를
        얻는 것처럼 하나의 여가죠.
        <BL></BL>{" "}
        <BT>
          어쨌든 그렇게 마음을 정하고 시작한 사이드 프로젝트 중 하나가
          모지또에요. 혼자가 아닌 팀으로 만들게 된 다이어리 앱인데 기대했던
          것보다 결과가 좋았어요. 하나의 앱을 만들며 배운 게 참 많았는데 결과도
          좋다니 더할 나위 없었죠.
        </BT>{" "}
        <BL></BL>더 기분이 좋은 건 이게 온전히 저와 팀의 역량으로 만들어낸
        일이기 때문이었어요. 회사에선 내가 아닌 그저 회사의 역량 덕분에 잘되는
        일이 많았거든요. 그 반대도 마찬가지였구요.
        <BL></BL>{" "}
        <BT>
          결과가 생각 이상으로 좋아서 더 진지하게 모지또에 임하게 됐어요. 원래는
          가벼운 마음으로 다이어리 앱을 만들었는데 알고보니 사람의 감정을 다루는
          무거운 분야였어요. 지금은 사람들이 자신의 감정을 돌아보고 더 나은
          방향으로 나아갈 수 있게 돕자라는 마음을 갖고 서비스를 만드는 중이에요.
        </BT>{" "}
        <BL></BL>
        그리고 제가 사이드 프로젝트를 하면서 이렇게 좋은 반응을 받은 적이
        없었어서 하나의 희망을 품게 됐어요. 어쩌면 이게 메인이 될 수도 있겠다는
        희망이요. 애정하는 사람들과 제가 제일 좋아하는 일을 하며 돈도 번다면
        정말 행복할 것 같아요.
        <BL></BL>
        그래서 요새 팀원들과 함께 열심히 해외 버전도 준비하고 있어요. 출시는
        2022년 초인데 이번엔 더 진지한 마음으로 준비하는 만큼 더 다양한
        가능성으로 연결되길 바라고 또 바라요. 아 모지또는 이{" "}
        <OL
          url={"https://teamblender.github.io/mojito_policy_terms/#/"}
          bwColor={bwColor}
        >
          링크
        </OL>
        에서 다운로드 받을 수 있어요.
      </>
    ),
  },
  {
    video: "https://youtu.be/5IQ6OV61zTU",
    title: "새로운 세계, 독립 출판",
    subTitle: "단편소설 무화과를 출판하다",
    desc: (
      <>
        회사에서든 사이드 프로젝트에서든 무형의 제품을 만들다보니 문득 물성있는
        게 그리워졌어요. 손으로 만질 수 있는 결과물을 만들고 싶었죠. 특히 제가
        글쓰기를 좋아하다보니 책에 관심이 갔어요.
        <BL></BL>
        그러던 중 평소 관심있게 보던 독립 서점 계정에서 독립 출판 4주 클래스를
        운영한다는 포스트를 봤어요. 4주 동안 독립 출판을 함께하는 수업이었어요.
        흥미가 생겨 바로 등록했죠.
        <BL></BL>{" "}
        <BT>
          수업 첫날부터 등록하길 잘했다는 생각을 했어요. 수강생들이 자기소개를
          하며 왜 독립 출판을 하게 되었는지 말하는 시간이 있었는데 모든 분들의
          배경과 사연이 다양하고 감성적이었어요. 맨날 IT 업계 사람들만 만나다
          다양한 분야의 사람들을 만나니 신선하기도 했구요.
        </BT>{" "}
        <BL></BL>
        저는 예전에 써놓은 무화과라는 단편소설을 책으로 만들기로 했어요.
        무화과를 보며 예전엔 어떻게 이런 긴 글을 쓸 수 있었지하며 신기했거든요.
        기왕 쓴 김에 출판까지 시켜주면 좋겠다 생각이 들은 거죠. 4주 동안의
        수업을 하는 동안 다시 퇴고를 하는데 오랜만에 보니 고칠 게 눈에 많이
        보이더라구요. 그래도 글을 한번 더 다듬으니 뿌듯했어요.
        <BL></BL>{" "}
        <BT>
          퇴고를 마무리하고 가르침에 따라 인쇄를 맡기러 을지로에 갔는데 새로운
          세상이었어요. 엄청난 크기의 인쇄기에서 나는 굉음과 페인트를 몸에
          묻히고 돌아다니시는 작업자분들. 하드웨어의 제작 환경은 이렇구나라는 걸
          단숨에 알 수 있었어요. 어떻게 보면 저와 반대편에 있는 세상일 텐데 제가
          그곳에 온 거죠.
        </BT>{" "}
        <BL></BL>
        가서 어떤 종이로 인쇄를 할 건지, 몇 부를 뽑을 건지 인쇄소 사장님과
        얘기를 했어요. 앱 서비스는 한번 만들면 무한히 복제가 가능하고 오류가
        있으면 수정하기가 쉬운 반면 책은 그렇지 않았어요. 그래서 결정할 때 더
        신중해졌죠. 저는 500부를 뽑기로 해서 적지 않은 돈이 나가는 거였거든요.
        <BL></BL>{" "}
        <BT>
          어찌저찌 결정을 하고 마침내 인쇄를 하는 날 인쇄소를 찾아갔어요. 제대로
          인쇄가 되는지 확인하는 감리 작업을 위해서였는데 설렜어요. 세상에 나온
          제 책을 처음으로 보는 거니까요. 마침 인쇄소에 갔을 때 테스트로 제 책을
          뽑아보고 있었는데 인쇄기의 규칙적인 소리에 맞춰 심장이 쿵쿵댔어요.
          따끈따끈한 제 책이 나오는 흥분되는 순간이었어요.
        </BT>{" "}
        <BL></BL>
        인쇄가 끝나고 500부가 저희 집으로 배송이 되었을 땐 기쁘면서도 걱정도
        됐어요. 500부라는 게 생각보다 부피를 많이 차지하더라구요. 300부나
        500부나 뽑는 비용이 비슷해서 500부를 뽑았는데 후회가 좀 됐어요. 근데
        이것도 다 배움이라 생각하니 그런 후회의 감정도 헛웃음 한번에 사라졌죠.
        천천히 천천히 팔면서 혹은 나눠주면서 소진해나갈 생각이에요.
        <BL></BL>{" "}
        <BT>
          책이 나오고 두 곳의 독립 서점에 입고를 했는데요. 신기하게도 조금씩
          팔리긴 하더라구요. 그리고 책을 통해 알게된 새로운 인연도 있구요. 마치
          책이 저 대신 이 세상을 여행하며 사람들을 만나고 다니는 느낌이에요. 제
          분신인 거죠.
        </BT>{" "}
        <BL></BL>
        이런 매력 때문에 저는 앞으로도 독립 출판을 계속할 것 같아요. 새로운
        세상에서 접하는 새로운 경험과 인연이 좋거든요. 물론 그거 돈이 안되지
        않냐라고 묻는 사람도 있어요. 맞아요. 돈은 안돼요. 더군다나 IT 앱
        서비스에 비하면 더 그렇죠. 하지만 오히려 그렇기에 더욱더 순수한 창작으로
        느껴져요. 돈이 없어도 다들 자기만의 의미를 위해서 하니까 인간적이고
        따뜻해요.
        <BL></BL>{" "}
        <BT>
          그리고 이번에 책이라는 하드웨어를 만들며 분명히 배운 것도 많아요. 그걸
          제가 IT 분야에서 배운 것들과 합치면 재밌는 결과가 나올 수도 있겠다는
          생각이 들어요. 퓨전 음식처럼 융합에서 멋진 결과물이 나올 때가
          많으니까요. 그래서 앞으로는 더 다양한 세계를 오가며 프로젝트를 할
          생각이에요.
        </BT>
      </>
    ),
  },
  {
    video: "https://youtu.be/eFvMCTFV64g",
    title: "스테이에 살어리랏다",
    subTitle: "언젠가 나만의 스테이를 짓고 싶어",
    desc: (
      <>
        가끔 나에게 주는 상 느낌으로 좋은 곳에 가서 시간을 보내고 와요. 특히
        한옥 스테이를 좋아해서 스테이폴리오를 둘러보다 괜찮은 곳 일정이 나면
        충동적으로 예약해버리죠.
        <BL></BL>
        처음엔 그저 호기심이었어요. 얼마나 좋길래 이런 가격을 주고 일박을 하는
        거지 생각을 하고 서촌의 한 스테이에 가봤죠. 근데 스테이에 가기 위해
        서촌에 들어선 순간 직감했어요. 아 이거 내 스타일이다. 동네부터 너무
        이쁘고 분위기도 소박해서 좋았어요. 골목길도 이곳저곳 다 들어가보고 싶게
        매력을 풍겼구요. 제가 살던 서울이 아닌 느낌이 들더라구요.
        <BL></BL>{" "}
        <BT>
          그리고 마침내 스테이에 들어갔을 때 이세계에 들어온 것 같다는 생각이
          들었어요. 멋진 정취의 한옥 안에 들어섰는데 힐링이 되는 향과 재즈가
          저를 반겨줬어요. 그 짧은 순간 도시 생활하며 느꼈던 스트레스들이 사르르
          녹으며 아 이래서 이런 곳에 오는 거구나 싶었죠.
        </BT>{" "}
        <BL></BL>
        들어간 후에도 한옥 스테이에서 여유를 부리며 시간을 보냈어요. 혼자 가서
        심심하지 않을까 생각했는데 서촌이라는 동네엔 구경할 게 참 많더라구요.
        골목길 이곳저곳을 탐험하며 서촌의 매력에 완전 빠져들었어요. 예쁜 가게도
        괜히 들어가보고 맛있어보이는 라멘 가게에도 그냥 들어가서 먹고. 혼자만의
        시간을 아주 잘 누리다 왔죠.
        <BL></BL>첫 경험이 만족스럽다보니 그 뒤로도 종종 스테이폴리오에 들어가게
        됐어요. 괜찮은 숙소 어디 없나 둘러보다가 괜찮은 곳 있으면 예약하고.
        그렇게 지금까지 스테이 네 곳을 다녀왔는데 다 만족스러웠어요.
        <BL></BL>
        근데 가는 스테이마다 각각의 매력이 있었는데 그런 것들을 즐기다보니 공간
        기획도 재밌겠다라는 생각이 들더라구요. 나라면 어떤 스테이를 만들지 종종
        상상하는데 재밌어요.
        <BL></BL>{" "}
        <BT>
          그래서 그 뒤로 버킷리스트가 하나 생겼는데 바로 저만의 스테이를 만드는
          거예요. 집 두 동을 지어서 하나에선 제가 살고 나머지 하나는 스테이로
          쓰고 싶어요. 프리랜서처럼 집에서 일하다 손님을 맞이하고 방 안내드리고
          같이 세상사는 얘기하고. 여행객들의 이야기를 듣다보면 여행하는 느낌이
          들지 않을까 생각해요. 물론 너무 낭만에 빠진 이야기일 수도 있겠지만
          언젠가 한번은 도전해보고 싶은 꿈이에요.
        </BT>{" "}
        <BL></BL>
        꿈을 이루기 위해선 준비할 게 참 많을 거예요. 그래도 관심을 두고 하나씩
        준비해볼 생각이에요. 인생은 기니까 차근차근 천천히 준비하다보면 언젠간
        결실을 맺겠죠.
      </>
    ),
  },
  {
    img: img10,
    ratio: "1:1",
    title: "자취 라이프 시작",
    subTitle: "자취하면서 알게 되는 나",
    desc: (
      <>
        예전부터 저만의 공간이 갖고 싶었어요. 창작자에겐 자신만의 공간이
        중요하다는 글을 본 뒤엔 더더욱요. 그래서 결국엔 자취를 하게 됐죠. 본가
        바로 근처에서요.
        <BL></BL>
        본가 바로 앞에서 자취하는 거면 무슨 의미가 있는 건지 물어보는 사람들이
        있어요. 그런데 저는 오히려 본가와 가까운 게 좋은 것 같아요. 사실 저는
        혼자만의 공간을 갖고 싶은 것 외엔 가족들이랑 사는 게 좋았거든요. 집밥도
        너무 좋아했구요. 그래서 나만의 공간을 가지면서 가족들과도 자주 시간을
        보낼 수 있는 지금의 자취방이 만족스러워요.
        <BL></BL>
        근데 자취를 시작하니까 숨쉬는 게 다 돈이다라는 말이 확 와닿더라구요.
        저라는 한 사람이 머물 공간을 유지하기 위해 이곳저곳 나가는 돈이 참
        많아요. 관리비, 인터넷비, 정수기비 등. 숨만 쉬어도 돈이 나가요. 예전에는
        별 생각없이 지출을 해도 통장이 텅장이 되는 일은 없었는데 이제는 확
        느껴지죠.
        <BL></BL>{" "}
        <BT>
          하지만 이런 것들을 모두 따져도 자취하는 게 참 좋아요. 자취하면서
          누리는 것, 느끼는 것들이 많거든요. 특히 혼자 살 때 내가 어떤 식으로
          생활하는지 이번에 처음 알게 됐어요. 밥은 어떻게 챙겨먹는지, 방은
          얼마나 자주 청소하는지. 혼자 지내보니 제가 생각보다 부지런히
          살더라구요. 부모님도 처음엔 걱정을 많이 하시다 이제는 좀 믿으시는 것
          같아요.
        </BT>{" "}
        <BL></BL>또 자취를 하면서 원래는 보지 못했던 세계도 눈에 들어와요.
        오늘의집, 마켓컬리 등 평소에 써보지도 않은 앱에 들어가게 되고
        인스타그램에서도 인테리어 관련 콘텐츠를 많이 보는 식이죠. 말하다보니 다
        돈쓰는 얘기인데 그것도 다 새로운 경험이라 합리화하고 있어요. 새로운
        세상에 눈을 뜨면 더 많은 기회가 눈에 들어오기도 하니까요.
        <BL></BL>
        아직은 자취 초반이라 그럴 수도 있지만 로망을 최대한 실현하면서 살고
        있어요. 아침에 재즈를 틀고 차 내리기, 밤에 불 다 끄고 야경 구경하기,
        세탁기 돌리면서 책 읽기. 이런 행동들을 통해 소소한 행복을 얻어요.
        <BL></BL>{" "}
        <BT>
          슬슬 자리를 잡은 후론 창작을 더 열심히 하기 위해 노력 중이에요. 자취를
          한 목적 중에 더 활발한 창작 활동을 위한 것도 있으니까. 실제로 혼자
          있다보니 집중도가 높고 더 많은 작업을 하게 돼요. 자취의 효과가 있는 것
          같아요.
        </BT>{" "}
        <BL></BL>
        앞으로 자취를 하면서 만들어나갈 결과물들이 궁금해져요. 새로운 환경의
        내가 만들었으니 결과물들이 조금씩 달라질 거라 생각하는데 그 달라지는
        과정을 잘 기록해놓으려구요. 지금 준비하고 있는 몽타주라는 매거진이 그
        기록의 시작점이 아닐까 생각도 해요. 잘 됐으면 좋겠어요!
      </>
    ),
  },
];

export { JunwooleeHeader, JunwooleeContents };
