import React from "react";
import styled from "styled-components";

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const LBox = styled.div`
  display: flex;
  align-items: center;
`;

const ListIcon = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"};
  margin-right: 5px;
`;

const ListTitle = styled.div`
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  color: ${(props) =>
    props.bwColor === "white" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"};
`;

const RBox = styled.div``;

const ListHeader = ({ listTitle = "Montages", bwColor = "black" }) => {
  return (
    <Box className="dD">
      <LBox>
        <ListIcon bwColor={bwColor}></ListIcon>
        <ListTitle bwColor={bwColor}>{listTitle}</ListTitle>
      </LBox>
      <RBox></RBox>
    </Box>
  );
};

export default ListHeader;
